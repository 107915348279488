import { RefObject, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FeatureId } from "@/api";
import { ChartLibraryActions } from "@/contexts/ChartLibrarySlice";
import { LibraryChart, selectIndependentVariable } from "@/contexts/ChartLibraryStore";
import ChartLibraryEditor from "@/features/chartElementLibrary/ChartLibraryEditor";
import useEscKeyCallback from "@/hooks/useEscKeyCallback";
import useResetHotkey from "@/hooks/useResetHotkey";
import { useFeatureFlags } from "@/utils/features";

import { LibraryActiveChartContainer, LibraryActiveChartOverlay } from "./LibraryActiveChart.styled";

interface LibraryActiveChartProps {
  activeChart: LibraryChart;
}

const LibraryActiveChart = ({ activeChart }: LibraryActiveChartProps) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const dispatch = useDispatch();
  const wrapperRef = useRef<HTMLDivElement>();

  const { independentVariable: independentVar } = useSelector(selectIndependentVariable);

  const handleCloseActiveChart = () => {
    dispatch(
      ChartLibraryActions.setActiveChart({
        chartId: "",
        telematicsless: !isFeatureEnabled(FeatureId.Telemetry),
      })
    );
  };

  const handleResetChartZoom = () => {
    dispatch(ChartLibraryActions.updateLibrarySpan({}));
  };

  useEscKeyCallback(handleCloseActiveChart);
  useResetHotkey(handleResetChartZoom);

  return (
    <LibraryActiveChartContainer ref={wrapperRef as RefObject<HTMLDivElement>}>
      <LibraryActiveChartOverlay className="active-chart-overlay" onClick={handleCloseActiveChart}>
        <ChartLibraryEditor chart={activeChart!} independentVar={independentVar} />
      </LibraryActiveChartOverlay>
    </LibraryActiveChartContainer>
  );
};

export default LibraryActiveChart;
