import { Card } from "antd";
import _, { isUndefined } from "lodash";
import { useContext, useMemo } from "react";
import { Provider as ChartLibraryProvider } from "react-redux";

import { FeatureId, useTimeSeriesFieldsQuery } from "@/api";
import Loading from "@/components/loading";
import { QUERY_SETTINGS, SCV_CHART } from "@/constants";
import { getDefaultChartLibraryInitalState } from "@/contexts/ChartLibrarySlice";
import configureChartLibraryStore, { ChartLibraryState } from "@/contexts/ChartLibraryStore";
import SCVContext from "@/contexts/SCVContext";
import ChartLibrary from "@/features/chartElementLibrary/ChartLibrary";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { stateParser } from "@/hooks/useStateToExternalSource";
import { getCurrentCustomerId } from "@/utils/customers";
import { useFeatureFlags } from "@/utils/features";
import { getFromLocalStorage } from "@/utils/localStorageUtils";
import { getQueryParam } from "@/utils/urlUtils";

import { validateState } from "./chartLibraryWrapperUtils";
import VehicleDetailsAnomalySection from "./VehicleDetailsAnomalySection";

export const externalLibraryStateShapeIsValid = (externalState: unknown, validState: object): boolean => {
  if (isUndefined(externalState) || !externalState || typeof externalState !== "object") return false;
  const incomingKeys = Object.keys(externalState);
  const expectedKeys = Object.keys(validState);
  return expectedKeys.length === _.intersection(incomingKeys, expectedKeys).length;
};

const ChartLibraryWrapper = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const { customerIdentifier } = useCustomerIdentifier();
  const { pvin } = useContext(SCVContext);

  const { data, isLoading } = useTimeSeriesFieldsQuery(
    { customerId: getCurrentCustomerId() ?? "" },
    { staleTime: QUERY_SETTINGS.LONG_STALE_TIME, enabled: isFeatureEnabled(FeatureId.Telemetry) }
  );

  const libraryStore = useMemo(() => {
    if (isLoading && isFeatureEnabled(FeatureId.Telemetry)) return;
    // Get state from URL first, then localstorage
    const serializedState = getQueryParam(SCV_CHART) || getFromLocalStorage(SCV_CHART);
    // Pass state to ChartLibraryStore, if state ends up being invalid then the store will use a hardcoded default
    const parsedState = serializedState ? stateParser(serializedState) : undefined;
    const initialState = externalLibraryStateShapeIsValid(parsedState, getDefaultChartLibraryInitalState())
      ? (parsedState as ChartLibraryState)
      : undefined;
    const maybeValidatedState = validateState(
      initialState,
      data,
      !isFeatureEnabled(FeatureId.Telemetry),
      customerIdentifier
    );
    // Pass state to ChartLibraryStore, if state ends up being undefined then the store will use a hardcoded default
    const validatedState = maybeValidatedState ? maybeValidatedState : getDefaultChartLibraryInitalState();
    return configureChartLibraryStore(validatedState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pvin, data]);

  const anomalySectionClassName = isFeatureEnabled(FeatureId.Telemetry)
    ? "rendering-order-third"
    : "rendering-order-fith";

  if ((isLoading && isFeatureEnabled(FeatureId.Telemetry)) || !libraryStore) {
    return (
      <Card className="card-pullsystems-shadow rendering-order-third">
        <Loading height={256} />
      </Card>
    );
  }

  return (
    <ChartLibraryProvider store={libraryStore}>
      <VehicleDetailsAnomalySection pvin={pvin} className={anomalySectionClassName} />
      <ChartLibrary />
    </ChartLibraryProvider>
  );
};

export default ChartLibraryWrapper;
