import { UseQueryOptions } from "@tanstack/react-query";

import { FeatureId } from "@/api";
import { useVehicleSpeedStatsQuery, VehicleSpeedStatsQuery, VehicleSpeedStatsQueryVariables } from "@/api/customerApi";
import { useFeatureFlags } from "@/utils/features";

export const useTelematicsProtectedVehicleSpeedStatsQuery = (
  variables: VehicleSpeedStatsQueryVariables,
  options?: UseQueryOptions<VehicleSpeedStatsQuery>
) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const results = useVehicleSpeedStatsQuery(variables, {
    ...options,
    enabled: isFeatureEnabled(FeatureId.Telemetry) ? options?.enabled : false,
  });

  if (isFeatureEnabled(FeatureId.Telemetry)) {
    return results;
  }

  return {
    data: fakeSpeedStats,
    isLoading: false,
    isError: false,
    error: undefined,
    isSuccess: true,
    telematicsless: true,
  };
};

const fakeSpeedStats: VehicleSpeedStatsQuery = {
  filteredQuery: {
    vehicleSpeedStats: {
      vehicle: {
        p5: 18.341,
        p25: 38.57,
        median: 59.64,
        p75: 81.47,
        p95: 104.871,
      },
      fleet: {
        p5: 19.5125,
        p25: 41.75,
        median: 64.455,
        p75: 88.8225,
        p95: 122.305,
      },
    },
  },
};
