import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from "@tanstack/react-query";
import { useApiFetcher } from "@/hooks/useApiFetcher";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  BigInt: number;
  DateTime: string;
  EmailAddress: string;
  Ip: string;
  JSON: string;
  JSONObject: object;
  PhoneNumber: string;
  /** Psuedo-anonymized Vehicle Identification Number. A globally unique identifier for a vehicle valid only within Amaya. */
  Pvin: string;
  /** Generic signed string that can be used anywhere we might need to pass information with claims. */
  SignedId: string;
  Time: string;
  /** A globally-unique identifier for a set of values, where each value corresponds to a PVIN and timestamp. */
  TimeSeriesFieldId: string;
  TimeStamp: string;
  URL: string;
  /** Vehicle Identification Number. A globally unique identifier for a vehicle that is used outside of Amaya. */
  Vin: string;
};

export enum AggregateType {
  /** Count of observed signal values reported within the interval. */
  Count = "COUNT",
  /** The observed signal value with the latest timestamp within the interval. */
  Last = "LAST",
  /** Arithmetic mean of all observed signal values within the interval. */
  Mean = "MEAN",
  /** 50th percentile of all observed signal values within the interval. May be approximated. */
  Median = "MEDIAN",
  /** Standard deviation of all observed signal values within the interval. */
  Std = "STD",
  /** Sum of all observed signal values within the interval. */
  Sum = "SUM",
}

export type AnomalyDetector = {
  __typename?: "AnomalyDetector";
  /** Anomaly detector configured caution threshold */
  cautionThreshold: Scalars["Float"];
  /** The date this was created */
  created: Scalars["DateTime"];
  /** The user who created this */
  creator?: Maybe<ReadOnlyUser>;
  /** Anomaly detector description */
  description?: Maybe<Scalars["String"]>;
  expression?: Maybe<SavedExpression>;
  /** Globally unique identifier for the anomaly detector */
  id: Scalars["ID"];
  /** The ids of other time series fields directly depended upon by this detector */
  inputIds: Array<Scalars["TimeSeriesFieldId"]>;
  /** Whether the model was made by the customer (this is for a mocked out feature) */
  isCustomerMLModel?: Maybe<Scalars["Boolean"]>;
  /** The date this was last modified */
  lastModified?: Maybe<Scalars["DateTime"]>;
  /** Anomaly detector name */
  name: Scalars["String"];
  /** Anomaly detector i18n name key, if applicable, otherwise the name. */
  nameKey: Scalars["String"];
  status?: Maybe<CalculatedStatus>;
  /** An id that can be used to query the values of this anomaly detector as a time series. */
  timeSeriesFieldId: Scalars["TimeSeriesFieldId"];
  /** The type of this detector */
  type?: Maybe<AnomalyDetectorType>;
  /** The Anomaly detector version */
  version: Scalars["Int"];
  /** Anomaly detector configured warning threshold */
  warningThreshold: Scalars["Float"];
};

export enum AnomalyDetectorType {
  Ml = "ML",
  Pattern = "Pattern",
}

export enum CalculatedStatus {
  Available = "AVAILABLE",
  Calculating = "CALCULATING",
  Draft = "DRAFT",
  Error = "ERROR",
}

/** Cluster saved. */
export type Cluster = {
  __typename?: "Cluster";
  /** Total of pvins + date range combination inside the cluster */
  combinationCount: Scalars["Int"];
  created: Scalars["DateTime"];
  creator?: Maybe<ReadOnlyUser>;
  /** JSON object containing additional metadata for a cluster */
  customerAttributes?: Maybe<Scalars["JSONObject"]>;
  customerId: Scalars["ID"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  /** PVINs that are not in the vehicle table */
  missingPvins: Array<Scalars["String"]>;
  name: Scalars["String"];
  /** Different PVINs inside the cluster */
  pvins: Array<Scalars["String"]>;
  /** Total of different vehicles inside the cluster */
  vehicleCount: Scalars["Int"];
  version: Scalars["Int"];
};

/** Input to create a Cluster */
export type ClusterCreateInput = {
  content: Array<ClusterRedshiftCreateInput>;
  /** Additional metadata stored as a JSON object */
  customerAttributes?: InputMaybe<Scalars["JSONObject"]>;
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  /**
   * If true, the cluster will be created without a creator; otherwise the current user will be the creator.
   * Requires global Admin permission.
   */
  readOnly?: InputMaybe<Scalars["Boolean"]>;
};

export type ClusterMutation = {
  __typename?: "ClusterMutation";
  /** Makes a copy of an existing cluster */
  copyCluster?: Maybe<Scalars["ID"]>;
  /** Creates a cluster based on input and returns result and possible warnings. */
  createCluster?: Maybe<ClusterUpserted>;
  /** Deletes a cluster by id */
  deleteCluster?: Maybe<Scalars["String"]>;
  /** Edits the name or description of an existing cluster. */
  editCluster?: Maybe<Cluster>;
  /** Updates cluster pvins data (pvins and range dates). */
  updateClusterPvins?: Maybe<ClusterUpserted>;
};

export type ClusterMutationCopyClusterArgs = {
  clusterId: Scalars["ID"];
  description: Scalars["String"];
  name: Scalars["String"];
};

export type ClusterMutationCreateClusterArgs = {
  input: ClusterCreateInput;
};

export type ClusterMutationDeleteClusterArgs = {
  clusterId: Scalars["ID"];
};

export type ClusterMutationEditClusterArgs = {
  clusterId: Scalars["ID"];
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  version: Scalars["Int"];
};

export type ClusterMutationUpdateClusterPvinsArgs = {
  action: ClusterUpdateAction;
  clusterId: Scalars["ID"];
  filter?: InputMaybe<FilterInput>;
  ignoreDateRanges?: InputMaybe<Scalars["Boolean"]>;
  input: Array<ClusterRedshiftCreateInput>;
  version: Scalars["Int"];
};

/** Prisma Cluster, no vehicle related attributes. */
export type ClusterNonVehicleDetails = {
  __typename?: "ClusterNonVehicleDetails";
  created: Scalars["DateTime"];
  creator?: Maybe<ReadOnlyUser>;
  /** JSON object containing additional metadata for a cluster */
  customerAttributes?: Maybe<Scalars["JSONObject"]>;
  customerId: Scalars["ID"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  version: Scalars["Int"];
};

/** Cluster with Pagination result */
export type ClusterPaginatedResult = {
  __typename?: "ClusterPaginatedResult";
  data: Array<Cluster>;
  pagination: PaginationResult;
};

export type ClusterQuery = {
  __typename?: "ClusterQuery";
  /** Gets all non vehicle related details of a cluster */
  clusterNonVehicleDetails?: Maybe<ClusterNonVehicleDetails>;
  /** Retrieves cluster from an especified id or all Clusters for customer if no id specified */
  clusters: ClusterPaginatedResult;
};

export type ClusterQueryClusterNonVehicleDetailsArgs = {
  id: Scalars["ID"];
};

export type ClusterQueryClustersArgs = {
  filter?: InputMaybe<FilterInput>;
  ids?: InputMaybe<Array<Scalars["ID"]>>;
  pagination?: InputMaybe<Pagination>;
  sorting?: InputMaybe<SortFilter>;
};

export type ClusterRedshift = {
  __typename?: "ClusterRedshift";
  endDate?: Maybe<Scalars["DateTime"]>;
  pvin: Scalars["String"];
  startDate?: Maybe<Scalars["DateTime"]>;
};

export type ClusterRedshiftCreateInput = {
  endDate?: InputMaybe<Scalars["DateTime"]>;
  pvin: Scalars["ID"];
  startDate?: InputMaybe<Scalars["DateTime"]>;
};

export enum ClusterSortField {
  Created = "created",
  CreatorName = "creatorName",
  Description = "description",
  Name = "name",
  VehicleCount = "vehicleCount",
}

export enum ClusterUpdateAction {
  AddPvins = "ADD_PVINS",
  RemovePvins = "REMOVE_PVINS",
}

/** Cluster Created or Updated returned */
export type ClusterUpserted = {
  __typename?: "ClusterUpserted";
  cluster: Cluster;
  missingPvins: Array<Scalars["String"]>;
  repeatedCombinations: Array<ClusterRedshift>;
};

/** Readonly version of CostDensityFunctionInput */
export type CostDensityFunction = {
  __typename?: "CostDensityFunction";
  densityUnit: DensityFunctionIndependentVariable;
  distribution: DensityFunction;
  totalCost: Scalars["Float"];
};

/** Describes a cost as a total amount in dollars distributed over some independent variable. */
export type CostDensityFunctionInput = {
  /** The unit of the distribution independent variable. */
  densityUnit: DensityFunctionIndependentVariable;
  /** The distribution of totalCost over some independent variable. */
  distribution: DensityFunctionInput;
  /** The total cost amount. */
  totalCost: Scalars["Float"];
};

export type Customer = {
  __typename?: "Customer";
  /** A human-readable name for the customer */
  appVersion?: Maybe<Scalars["String"]>;
  /** URL where the common resources are named by different customers. */
  commonResourcesUrl: Scalars["String"];
  /**
   * If present, any customer API requests should be directed
   * to the specified API URL. If not set, the default global API URL should be used.
   */
  customApiUrl?: Maybe<Scalars["String"]>;
  /** The default fields for display in the event analysis chart. */
  defaultEventAnalysisFields?: Maybe<Array<DefaultTimeSeries>>;
  /** The default faults that will be rendered in the SCV chart for telematicsless customers */
  defaultFaults?: Maybe<Array<DefaultFaults>>;
  /** The default fields for display in the time series chart. */
  defaultTimeSeriesFields?: Maybe<Array<DefaultTimeSeries>>;
  /** Feature configuration for the customer */
  features?: Maybe<Array<Feature>>;
  /** URL for i18n folder */
  i18nFolder: Scalars["String"];
  id: Scalars["String"];
  /** The URI of a logo image for the customer, if configured */
  logoUri?: Maybe<Scalars["String"]>;
  /** A human-readable name for the customer */
  name: Scalars["String"];
};

export type CustomerPermissions = {
  __typename?: "CustomerPermissions";
  customerId: Scalars["ID"];
  permissions: Array<Permission>;
};

export type CustomerUserSettings = {
  __typename?: "CustomerUserSettings";
  /** The customer ID to which these settings apply for the current user */
  customerId: Scalars["ID"];
  /**
   * A sequence of vehicles recently accessed from the Vehicle Details UI feature. The server may truncate older visits
   * beyond some limit.
   */
  recentDetailVehicles?: Maybe<VisitedVehiclePaginatedResult>;
  recentEvents?: Maybe<Array<VisitedEvent>>;
};

export type CustomerUserSettingsRecentDetailVehiclesArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type CustomerUserSettingsInput = {
  customerId: Scalars["ID"];
  recentDetailVehicles?: InputMaybe<Array<VisitedVehicleInput>>;
};

export type DateRange = {
  from?: InputMaybe<Scalars["DateTime"]>;
  to?: InputMaybe<Scalars["DateTime"]>;
};

export type DefaultFaults = {
  __typename?: "DefaultFaults";
  code: Scalars["String"];
  source: Scalars["String"];
  troubleCode: Scalars["String"];
};

export type DefaultTimeSeries = {
  __typename?: "DefaultTimeSeries";
  aggregateType: AggregateType;
  id: Scalars["TimeSeriesFieldId"];
  unit: Scalars["String"];
};

export type DeleteDependencyResponse = {
  __typename?: "DeleteDependencyResponse";
  dependencies: Array<Maybe<Dependency>>;
};

/** A readonly version of DensityFunctionInput */
export type DensityFunction = {
  __typename?: "DensityFunction";
  points: Array<Point>;
  y0: Scalars["Float"];
};

export enum DensityFunctionIndependentVariable {
  OdometerKm = "ODOMETER_KM",
  TimeDays = "TIME_DAYS",
}

/**
 * Describes a function that defines the distribution of some quantity over an independent variable x, for x > 0
 * as a spline interpolation of a series of points. As the function is meant to describe the distribution of a
 * quantity, it is thus unitless and can be considered a density function.
 * This can be used to define e.g. how the cost of a repair concept is distributed over time, or how the discount
 * effect of one repair concept on another is distributed over the mileage of a vehicle.
 * Note: it is assumed that the value of the function for x -> infinity is 0.
 */
export type DensityFunctionInput = {
  /** The spline points of the function. */
  points: Array<PointInput>;
  /** The value of the function at x = 0. */
  y0: Scalars["Float"];
};

export type Dependency = {
  __typename?: "Dependency";
  id: Scalars["ID"];
  name: Scalars["String"];
  owner?: Maybe<Scalars["String"]>;
  type: DependencyType;
};

export enum DependencyType {
  Anomaly = "ANOMALY",
  Event = "EVENT",
  Expression = "EXPRESSION",
}

/** A readonly version of DiscountDensityFunctionInput */
export type DiscountDensityFunction = {
  __typename?: "DiscountDensityFunction";
  distribution?: Maybe<DensityFunction>;
  /** TODO: Unused */
  totalDiscount: Scalars["Float"];
};

/**
 * Describes a discount between two repair events, which is the degree to which the occurrence of an event
 * decreases the probability of a condition occurring over the remaining lifetime of the vehicle.
 */
export type DiscountDensityFunctionInput = {
  /** Describes the distribution of totalDiscount over some independent variable. */
  distribution: DensityFunctionInput;
  /** TODO: Unused */
  totalDiscount: Scalars["Float"];
};

export enum ErrorKey {
  Database = "DATABASE",
  DataWarehouse = "DATA_WAREHOUSE",
  GenericError = "GENERIC_ERROR",
  InvalidInterval = "INVALID_INTERVAL",
  UniquenessConstraint = "UNIQUENESS_CONSTRAINT",
}

export type EventAnalysisQuickActionsConfig = {
  __typename?: "EventAnalysisQuickActionsConfig";
  /** If set to true, the action button for adding a pattern will show a fake image */
  fakePatternEditor?: Maybe<Scalars["Boolean"]>;
};

export enum EventType {
  Cluster = "cluster",
  Expression = "expression",
  Fault = "fault",
  Repair = "repair",
}

export type ExpressionAnomaly = {
  __typename?: "ExpressionAnomaly";
  created: Scalars["DateTime"];
  creator?: Maybe<ReadOnlyUser>;
  customerId: Scalars["ID"];
  definitionFieldId: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  expression: SavedExpression;
  id: Scalars["ID"];
  inputIds: Array<Scalars["TimeSeriesFieldId"]>;
  /** For internal use only. */
  internal: ExpressionAnomalyInternal;
  lastModified: Scalars["DateTime"];
  name: Scalars["String"];
  status: CalculatedStatus;
  threshold_caution: Scalars["Float"];
  threshold_lower: Scalars["Float"];
  threshold_upper: Scalars["Float"];
  threshold_warning: Scalars["Float"];
  type: AnomalyDetectorType;
  version: Scalars["Int"];
};

export type ExpressionAnomalyInput = {
  description?: InputMaybe<Scalars["String"]>;
  expressionId?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  threshold_caution?: InputMaybe<Scalars["Float"]>;
  threshold_lower?: InputMaybe<Scalars["Float"]>;
  threshold_upper?: InputMaybe<Scalars["Float"]>;
  threshold_warning?: InputMaybe<Scalars["Float"]>;
};

export type ExpressionAnomalyInternal = {
  __typename?: "ExpressionAnomalyInternal";
  sql: Scalars["String"];
  sqlParams: Array<Maybe<Scalars["Any"]>>;
};

export type ExpressionAnomalyInternalMutation = {
  __typename?: "ExpressionAnomalyInternalMutation";
  updateStatus: Array<Scalars["Boolean"]>;
};

export type ExpressionAnomalyInternalMutationUpdateStatusArgs = {
  customerId: Scalars["ID"];
  updates: Array<StatusUpdate>;
};

export type ExpressionAnomalyMutation = {
  __typename?: "ExpressionAnomalyMutation";
  /** Creates an anomaly from an expression */
  createExpressionAnomaly: ExpressionAnomaly;
  /** Deletes expression anomaly */
  deleteExpressionAnomaly: DeleteDependencyResponse;
  /** Updates expression anomaly */
  updateExpressionAnomaly: ExpressionAnomaly;
};

export type ExpressionAnomalyMutationCreateExpressionAnomalyArgs = {
  expressionAnomaly: ExpressionAnomalyInput;
};

export type ExpressionAnomalyMutationDeleteExpressionAnomalyArgs = {
  id: Scalars["ID"];
  version: Scalars["Int"];
};

export type ExpressionAnomalyMutationUpdateExpressionAnomalyArgs = {
  expressionAnomaly: ExpressionAnomalyInput;
  id: Scalars["ID"];
  version: Scalars["Int"];
};

export type ExpressionAnomalyQuery = {
  __typename?: "ExpressionAnomalyQuery";
  /** Retrieves all expression anomalies */
  expressionAnomalies: Array<ExpressionAnomaly>;
  /** retreives expression anomaly by id */
  expressionAnomaly?: Maybe<ExpressionAnomaly>;
  /**
   * Retrieves details for expression anomaly detectors matching the specified TimeSeriesFieldIds if one
   * exists, otherwise null.
   */
  expressionAnomalyDetectorsForTimeSeriesFieldId: Array<Maybe<AnomalyDetector>>;
};

export type ExpressionAnomalyQueryExpressionAnomaliesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  status?: InputMaybe<CalculatedStatus>;
};

export type ExpressionAnomalyQueryExpressionAnomalyArgs = {
  id: Scalars["ID"];
};

export type ExpressionAnomalyQueryExpressionAnomalyDetectorsForTimeSeriesFieldIdArgs = {
  ids: Array<Scalars["TimeSeriesFieldId"]>;
};

export type ExpressionEvent = {
  __typename?: "ExpressionEvent";
  created: Scalars["DateTime"];
  creator?: Maybe<ReadOnlyUser>;
  customerId: Scalars["ID"];
  /**
   * A customer unique id that is created by a hash of the operator, threshold, and expression definitionFieldId.
   * This allows ELT to pick up new or editied expression events, run their SQL query and place the results in the calculated table under this
   * defintionFieldId. The defintionFeildId is used to query for events in event analysis.
   */
  definitionFieldId: Scalars["ID"];
  expression: SavedExpression;
  id: Scalars["ID"];
  /** For internal use only. */
  internal: ExpressionEventInternal;
  lastModified: Scalars["DateTime"];
  name: Scalars["String"];
  operator: ExpressionEventOperator;
  /**
   * The status of the calcualtion in ELT is updated when ELT picks up the expression event for calcualtion and when the
   * calcualtions are completed.
   */
  status: CalculatedStatus;
  threshold: Scalars["Float"];
  version: Scalars["Int"];
};

export type ExpressionEventInput = {
  expressionId?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  operator?: InputMaybe<ExpressionEventOperator>;
  status?: InputMaybe<CalculatedStatus>;
  threshold?: InputMaybe<Scalars["Float"]>;
};

/**
 * For internal use only. When ELT queries amaya for expression events to be picked up by ELT it
 * will get the SQL and parameters then assemble them and run the query placin the results in the
 * calcualted table.
 */
export type ExpressionEventInternal = {
  __typename?: "ExpressionEventInternal";
  /** The raw sql for calcualting an expression events occurrence rate, to be picked up by ELT and ran. */
  occurrencesSQL: Scalars["String"];
  /** The parameters for the occurrences sql in the order that they appear, to be inputed into the above SQL when it is being compiled. */
  occurrencesSQLParams: Array<Maybe<Scalars["Any"]>>;
  /** The raw sql with variables for its parameters, to be picked up by ELT and ran. */
  sql: Scalars["String"];
  /** The parameters for the sql in the order that they appear, to be inputed into the above SQL when it is being compiled. */
  sqlParams: Array<Maybe<Scalars["Any"]>>;
};

/**
 * For internal use only. This allows ELT to update the status of an expression event in amaya after it has run the
 * calcualtions on it.
 */
export type ExpressionEventInternalMutation = {
  __typename?: "ExpressionEventInternalMutation";
  updateStatus: Array<Scalars["Boolean"]>;
};

/**
 * For internal use only. This allows ELT to update the status of an expression event in amaya after it has run the
 * calcualtions on it.
 */
export type ExpressionEventInternalMutationUpdateStatusArgs = {
  customerId: Scalars["ID"];
  updates: Array<StatusUpdate>;
};

export type ExpressionEventMutation = {
  __typename?: "ExpressionEventMutation";
  /** Creates an event from an expression */
  createExpressionEvent: ExpressionEvent;
  /** Deletes Event Expression */
  deleteExpressionEvent?: Maybe<Scalars["ID"]>;
  /** Updates Event Expression */
  updateExpressionEvent: ExpressionEvent;
};

export type ExpressionEventMutationCreateExpressionEventArgs = {
  expressionEvent: ExpressionEventInput;
};

export type ExpressionEventMutationDeleteExpressionEventArgs = {
  id: Scalars["ID"];
  version: Scalars["Int"];
};

export type ExpressionEventMutationUpdateExpressionEventArgs = {
  expressionEvent: ExpressionEventInput;
  id: Scalars["ID"];
  version: Scalars["Int"];
};

export enum ExpressionEventOperator {
  Gt = "GT",
  Gte = "GTE",
  Lt = "LT",
  Lte = "LTE",
}

export type ExpressionEventQuery = {
  __typename?: "ExpressionEventQuery";
  expressionEvent: ExpressionEvent;
  /** Retrieves all Expression Events */
  expressionEvents: Array<ExpressionEvent>;
};

export type ExpressionEventQueryExpressionEventArgs = {
  id: Scalars["ID"];
};

export type ExpressionEventQueryExpressionEventsArgs = {
  ids?: InputMaybe<Array<Scalars["ID"]>>;
};

export enum ExpressionIntervalType {
  Days = "DAYS",
  Km = "KM",
}

export enum ExpressionStatus {
  Available = "AVAILABLE",
  Calculating = "CALCULATING",
  Draft = "DRAFT",
  Error = "ERROR",
}

export type ExpressionTimeSeriesData = {
  __typename?: "ExpressionTimeSeriesData";
  count: Array<Array<Scalars["Float"]>>;
  high: Array<Array<Scalars["Float"]>>;
  low: Array<Array<Scalars["Float"]>>;
  vehicleCount: Array<Array<Scalars["Int"]>>;
  x: Array<Scalars["Float"]>;
  y: Array<Array<Maybe<Scalars["Float"]>>>;
};

export type Feature = {
  __typename?: "Feature";
  config?: Maybe<FeatureConfig>;
  id: FeatureId;
};

export type FeatureConfig = EventAnalysisQuickActionsConfig;

export enum FeatureId {
  /**
   * Feature for allowing users to create, view, and edit pattern anomalies. This feature will controll all access to the routes
   * for the anomaly pages
   */
  Anomalies = "Anomalies",
  /** Feature in anomaly view for performing cohort comparison. Currently faked. */
  AnomalyCohortComparison = "AnomalyCohortComparison",
  /**
   * Feature flag that will allow repair events that have already been assigned to not be filtered for and to
   * be displayed in the repair event landing page.
   */
  AssignedRepairEvents = "AssignedRepairEvents",
  /** Feature showing a pox plot of vehicle charging duration vs fleet charging duration */
  ChargingDuration = "ChargingDuration",
  /** Feature for the cluster home page and view */
  Clusters = "Clusters",
  /**
   * Feature for showing machine learning anomaly detector models as supplied by the customer.
   * Currently faked.
   */
  CustomerProvidedAnomalyModels = "CustomerProvidedAnomalyModels",
  /**
   * Quick action buttons in the event analysis for adding pattern, etc.
   * Currently faked.
   */
  EventAnalysisQuickActions = "EventAnalysisQuickActions",
  /** Repair concepts and fleet forecast. */
  FinancialAnalysis = "FinancialAnalysis",
  /**
   * Feature for allowing users to create, view, and edit patterns. This feature will controll all access to the routes
   * for the pattern pages
   */
  Patterns = "Patterns",
  /**
   * Feature flag for refined pattern editor while it is in development. This feature flag will be temporary
   * as the refined pattern editor will be avlaiable to all customers when done.
   */
  RefinedPatternEditor = "RefinedPatternEditor",
  /**
   * Feature for allowing users to see repair events, select the primary attributes, and match the logs
   * with clusters.
   */
  RepairEvents = "RepairEvents",
  /**
   * Feature flag that will disable all telematics components for customers that do not have telematics data or
   * have not given us access to the telematics data.
   */
  Telemetry = "Telemetry",
  /**
   * Feature for allowing users to interact with VINs (instead of PVINs). For now all users have permission
   * to view VINs; in the future this will further be restricted by permissions.
   */
  Vins = "Vins",
}

export type Filter = {
  group?: InputMaybe<Array<Scalars["String"]>>;
  groupCriteria?: InputMaybe<GroupFilterCriteria>;
  includeNulls?: InputMaybe<Scalars["Boolean"]>;
  key: Scalars["String"];
  range?: InputMaybe<Array<Scalars["Float"]>>;
  type: FilterType;
};

export type FilterInput = {
  /** All Vehicle Filter Inputs */
  vehicle?: InputMaybe<VehicleFilterInput>;
};

export enum FilterType {
  Date = "Date",
  Group = "Group",
  Range = "Range",
}

/** A readonly version of ForecastInput */
export type Forecast = {
  __typename?: "Forecast";
  /** The date this was created */
  created: Scalars["DateTime"];
  /** The user who created this */
  creator: ReadOnlyUser;
  discountUnit: DensityFunctionIndependentVariable;
  discounts: Array<Array<DiscountDensityFunction>>;
  id: Scalars["ID"];
  /** The date this was last modified */
  lastModified: Scalars["DateTime"];
  name: Scalars["String"];
  productionForecast: ProductionForecast;
  repairConcepts: Array<ForecastRepair>;
  version: Scalars["Int"];
};

export type ForecastInput = {
  /** The independent variable of the density functions in discounts. */
  discountUnit: DensityFunctionIndependentVariable;
  /**
   * Each element [i,j] describes the degree to which the probability of the jth element in repairConcepts being triggered
   * is  discounted following a repair of the ith element. Both dimensions of this matrix must equal the length of
   * repairConcepts.
   */
  discounts: Array<Array<InputMaybe<DiscountDensityFunctionInput>>>;
  /** The user-defined name of the forecast to create */
  name: Scalars["String"];
  /** The ID of the production forecast to be used. */
  productionForecastId: Scalars["ID"];
  /**
   * The collection of repair concepts referenced by this forecast. A valid forecast requires at least one repair
   * concept.
   */
  repairConcepts: Array<ForecastRepairInput>;
};

export type ForecastMutation = {
  __typename?: "ForecastMutation";
  /** Creates the specified forecast */
  createForecast: Forecast;
  /** Deletes the specified forecast */
  deleteForecast?: Maybe<Scalars["ID"]>;
  /** Updates the specified forecast */
  updateForecast: Forecast;
};

export type ForecastMutationCreateForecastArgs = {
  forecast: ForecastInput;
};

export type ForecastMutationDeleteForecastArgs = {
  id: Scalars["ID"];
  version: Scalars["Int"];
};

export type ForecastMutationUpdateForecastArgs = {
  forecast: ForecastInput;
  id: Scalars["ID"];
  version: Scalars["Int"];
};

export type ForecastQuery = {
  __typename?: "ForecastQuery";
  /** Retrieves all forecasts with the specified IDs, or all forecasts for this customer if no ids are specified. */
  forecasts: Array<Forecast>;
};

export type ForecastQueryForecastsArgs = {
  ids?: InputMaybe<Array<Scalars["ID"]>>;
};

export type ForecastRepair = {
  __typename?: "ForecastRepair";
  repairConcept: RepairConcept;
  threshold: Scalars["Float"];
};

/** A "repair line item" in a forecast */
export type ForecastRepairInput = {
  /** The ID of the repair concept to which this forecast repair applies. These must be unique within a forecast. */
  repairConceptId: Scalars["ID"];
  /**
   * Quantifies a predicted probability for the repairConcept to be triggered, above which we assume the repair
   * will occur.
   */
  threshold: Scalars["Float"];
};

export enum GroupFilterCriteria {
  Excl = "Excl",
  Incl = "Incl",
}

export type Mutation = {
  __typename?: "Mutation";
  /** Appends an event id and type to the list of recently visited events stored for the current user. */
  appendVisitedEvent: UserSettings;
  /** Appends a vehicle id to the list of recently visited vehicles stored for the current user. */
  appendVisitedVehicle: UserSettings;
  clusters?: Maybe<ClusterMutation>;
  /** Delete an event to the list of recently visited events stored for the current user. */
  deleteVisitedEvent: UserSettings;
  expressionAnomalies?: Maybe<ExpressionAnomalyMutation>;
  /** For internal use only. API users should use expressionAnomalies.updateExpressionAnomaly */
  expressionAnomalyInternal?: Maybe<ExpressionAnomalyInternalMutation>;
  expressionEvents?: Maybe<ExpressionEventMutation>;
  /** For Internal use only. */
  expressionEventsInternal?: Maybe<ExpressionEventInternalMutation>;
  forecasts?: Maybe<ForecastMutation>;
  repairConcepts?: Maybe<RepairConceptMutation>;
  savedExpressions?: Maybe<SavedExpressionMutation>;
  /** For internal use only. API users should use savedExpressions.updateSavedExpression. */
  savedExpressionsInternal?: Maybe<SavedExpressionInternalMutation>;
  /** Updates the server with the settings for the current user. */
  updateUserSettings: UserSettings;
};

export type MutationAppendVisitedEventArgs = {
  customerId: Scalars["ID"];
  ecu?: InputMaybe<Scalars["String"]>;
  eventId: Scalars["String"];
  name: Scalars["String"];
  type: EventType;
};

export type MutationAppendVisitedVehicleArgs = {
  customerId: Scalars["ID"];
  vehicleId: Scalars["ID"];
};

export type MutationClustersArgs = {
  customerId: Scalars["ID"];
};

export type MutationDeleteVisitedEventArgs = {
  customerId: Scalars["ID"];
  eventIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type MutationExpressionAnomaliesArgs = {
  customerId: Scalars["ID"];
};

export type MutationExpressionEventsArgs = {
  customerId: Scalars["ID"];
};

export type MutationForecastsArgs = {
  customerId: Scalars["ID"];
};

export type MutationRepairConceptsArgs = {
  customerId: Scalars["ID"];
};

export type MutationSavedExpressionsArgs = {
  customerId: Scalars["ID"];
};

export type MutationUpdateUserSettingsArgs = {
  settings?: InputMaybe<UserSettingsInput>;
};

export type Pagination = {
  currentPage: Scalars["Int"];
  pageSize: Scalars["Int"];
};

export type PaginationResult = {
  __typename?: "PaginationResult";
  currentPage: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalCount: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export enum Permission {
  Admin = "Admin",
  EditClusters = "EditClusters",
  EditCustomEvents = "EditCustomEvents",
  EditExpression = "EditExpression",
  EditExpressionAnomaly = "EditExpressionAnomaly",
  EditExpressionEvent = "EditExpressionEvent",
  EditForecast = "EditForecast",
  EditRepairConcept = "EditRepairConcept",
  ExecuteHealthCheck = "ExecuteHealthCheck",
  ReadClusters = "ReadClusters",
  ReadCustomEvents = "ReadCustomEvents",
  ReadDataSources = "ReadDataSources",
  ReadExpression = "ReadExpression",
  ReadExpressionAnomaly = "ReadExpressionAnomaly",
  ReadExpressionEvent = "ReadExpressionEvent",
  ReadForecast = "ReadForecast",
  ReadRepairConcept = "ReadRepairConcept",
  ReadVehicles = "ReadVehicles",
  Service = "Service",
}

/** A readonly version of PointInput */
export type Point = {
  __typename?: "Point";
  x: Scalars["Float"];
  y: Scalars["Float"];
};

export type PointInput = {
  x: Scalars["Float"];
  y: Scalars["Float"];
};

export type ProductionForecast = {
  __typename?: "ProductionForecast";
  created: Scalars["DateTime"];
  creator?: Maybe<ReadOnlyUser>;
  customerId: Scalars["ID"];
  definition: ProductionForecastDefinition;
  id: Scalars["ID"];
  lastModified: Scalars["DateTime"];
  name: Scalars["String"];
  version: Scalars["Int"];
};

export type ProductionForecastDefinition = {
  __typename?: "ProductionForecastDefinition";
  forecast: Array<ProductionForecastUnitDefinition>;
  monthCount?: Maybe<Scalars["Int"]>;
  startingMonth?: Maybe<Scalars["DateTime"]>;
};

/** Defines a production forecast that is supplied by the customer */
export type ProductionForecastDefinitionInput = {
  /**
   * The forecast. Each entry describes an (optionally) filtered cohort of units and contains
   * the expected count of units per month for monthCount months starting with startingMonth.
   */
  forecast: Array<ProductionForecastUnitDefinitionInput>;
  /** The number of months in the forecast */
  monthCount?: InputMaybe<Scalars["Int"]>;
  /** The date of the first month of the forecast */
  startingMonth?: InputMaybe<Scalars["DateTime"]>;
};

export type ProductionForecastQuery = {
  __typename?: "ProductionForecastQuery";
  productionForecasts: Array<ProductionForecast>;
};

export type ProductionForecastUnitDefinition = {
  __typename?: "ProductionForecastUnitDefinition";
  country?: Maybe<Scalars["ID"]>;
  modelType?: Maybe<Scalars["ID"]>;
  modelYear?: Maybe<Scalars["Int"]>;
  unitCounts: Array<Maybe<Scalars["Int"]>>;
};

export type ProductionForecastUnitDefinitionInput = {
  country?: InputMaybe<Scalars["ID"]>;
  modelType?: InputMaybe<Scalars["ID"]>;
  modelYear?: InputMaybe<Scalars["Int"]>;
  /**
   * The number of units by month that are forecast to be produced, filtered by the
   * fields above
   */
  unitCounts: Array<InputMaybe<Scalars["Int"]>>;
};

export type Query = {
  __typename?: "Query";
  clusters?: Maybe<ClusterQuery>;
  /** Lists all customers the current user has at least one permission to. */
  customers: Array<Customer>;
  expressionAnomalies?: Maybe<ExpressionAnomalyQuery>;
  expressionEvents?: Maybe<ExpressionEventQuery>;
  forecasts?: Maybe<ForecastQuery>;
  /** Returns the current user */
  me: UserWithPermissions;
  productionForecasts?: Maybe<ProductionForecastQuery>;
  repairConcepts?: Maybe<RepairConceptQuery>;
  savedExpressions?: Maybe<SavedExpressionQuery>;
  /**
   * Retrieves all potential values from clusters and vehicles for a given input.
   * Optional limit to limit the number of results returned (default is 20).
   */
  searchAutoComplete: SearchAutoCompleteResults;
  serverInfo: ServerInfo;
  /**
   * Retrieves the list of all time series fields that can be used (graphed, referenced in patterns, referenced in
   * anomaly detectors, etc.).
   */
  timeSeriesFields: Array<TimeSeriesField>;
  userEvents: UserCreatedEvents;
  /** Retrieves settings for the current user. */
  userSettings: UserSettings;
  users: Array<User>;
};

export type QueryClustersArgs = {
  customerId: Scalars["ID"];
};

export type QueryExpressionAnomaliesArgs = {
  customerId: Scalars["ID"];
};

export type QueryExpressionEventsArgs = {
  customerId: Scalars["ID"];
};

export type QueryForecastsArgs = {
  customerId: Scalars["ID"];
};

export type QueryProductionForecastsArgs = {
  customerId: Scalars["ID"];
};

export type QueryRepairConceptsArgs = {
  customerId: Scalars["ID"];
};

export type QuerySavedExpressionsArgs = {
  customerId: Scalars["ID"];
};

export type QuerySearchAutoCompleteArgs = {
  customerId: Scalars["ID"];
  input: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryTimeSeriesFieldsArgs = {
  customerId: Scalars["ID"];
};

export type QueryUserEventsArgs = {
  customerId: Scalars["ID"];
  pagination?: InputMaybe<Pagination>;
  sorting?: InputMaybe<UserEventsSortInput>;
};

export type QueryUsersArgs = {
  customerId: Scalars["String"];
};

export type ReadOnlyUser = {
  __typename?: "ReadOnlyUser";
  isMe: Scalars["Boolean"];
  name: Scalars["String"];
};

/** Readonly version of RepairConceptInput */
export type RepairConcept = {
  __typename?: "RepairConcept";
  cost: CostDensityFunction;
  /** The date this was created */
  created: Scalars["DateTime"];
  /** The user who created this */
  creator: ReadOnlyUser;
  /** Customer-unique ID for this repair concept */
  id: Scalars["ID"];
  /** The date this was last modified */
  lastModified: Scalars["DateTime"];
  name: Scalars["String"];
  repairEventType?: Maybe<RepairEventType>;
  triggerExpressionEvent?: Maybe<ExpressionEvent>;
  version: Scalars["Int"];
};

export type RepairConceptInput = {
  /** The cost of this repair concept, including how it is distributed over the remaining lifetime of the vehicle. */
  cost: CostDensityFunctionInput;
  /** The user-defined name of the repair concept */
  name: Scalars["String"];
  /**
   * The type of observed repair event that defines this concept.
   * Either this or triggerExpressionId must be non-null.
   */
  repairEventType?: InputMaybe<RepairEventType>;
  /**
   * The id of an Expression of Boolean type that, when true, makes a vehicle eligible for this repair concept.
   * Either this or repairEventType must be non-null.
   */
  triggerExpressionEventId?: InputMaybe<Scalars["ID"]>;
};

export type RepairConceptMutation = {
  __typename?: "RepairConceptMutation";
  /** Creates the specified repair concept */
  createRepairConcept: RepairConcept;
  /** Deletes the specified repair concept */
  deleteRepairConcept?: Maybe<Scalars["ID"]>;
  /** Updates the specified repair concept */
  updateRepairConcept: RepairConcept;
};

export type RepairConceptMutationCreateRepairConceptArgs = {
  repairConcept: RepairConceptInput;
};

export type RepairConceptMutationDeleteRepairConceptArgs = {
  id: Scalars["ID"];
  version: Scalars["Int"];
};

export type RepairConceptMutationUpdateRepairConceptArgs = {
  id: Scalars["ID"];
  repairConcept: RepairConceptInput;
  version: Scalars["Int"];
};

export type RepairConceptQuery = {
  __typename?: "RepairConceptQuery";
  /**
   * Retrieves all repair concepts with the specified IDs, or all repair concepts for this customer if no ids are
   * specified.
   */
  repairConcepts: Array<RepairConcept>;
};

export type RepairConceptQueryRepairConceptsArgs = {
  ids?: InputMaybe<Array<Scalars["ID"]>>;
};

export enum RepairEventType {
  BatteryFullReplacement = "BATTERY_FULL_REPLACEMENT",
  BatteryModuleReplacement = "BATTERY_MODULE_REPLACEMENT",
}

export type Role = {
  __typename?: "Role";
  enabled: Scalars["Boolean"];
  id: Scalars["String"];
  name: Scalars["String"];
};

/** An expression saved for reuse */
export type SavedExpression = {
  __typename?: "SavedExpression";
  created: Scalars["DateTime"];
  creator?: Maybe<ReadOnlyUser>;
  customerId: Scalars["ID"];
  definition: Scalars["JSONObject"];
  /**
   * A customer-unique ID for the expression definition. This value will be updated with unique values
   * for `definition`. Use this value to access the calculated results of the expression in
   * customer-specific times series APIs such as `vehicleTimeSeries` and `vehicleCohortTimeSeries`.
   */
  definitionFieldId: Scalars["ID"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  inputIds: Array<Scalars["ID"]>;
  /** For internal use only. */
  internal: SavedExpressionInternal;
  lastModified: Scalars["DateTime"];
  name: Scalars["String"];
  readOnly?: Maybe<Scalars["Boolean"]>;
  status: CalculatedStatus;
  version: Scalars["Int"];
};

export type SavedExpressionInput = {
  definition?: InputMaybe<Scalars["JSONObject"]>;
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type SavedExpressionInternal = {
  __typename?: "SavedExpressionInternal";
  sql: Scalars["String"];
  sqlParams: Array<Maybe<Scalars["Any"]>>;
};

export type SavedExpressionInternalMutation = {
  __typename?: "SavedExpressionInternalMutation";
  updateStatus: Array<Scalars["Boolean"]>;
};

export type SavedExpressionInternalMutationUpdateStatusArgs = {
  customerId: Scalars["ID"];
  updates: Array<StatusUpdate>;
};

export type SavedExpressionMutation = {
  __typename?: "SavedExpressionMutation";
  /** Create pattern */
  createSavedExpression: SavedExpression;
  /** Delete pattern */
  deleteSavedExpression: DeleteDependencyResponse;
  /** Update pattern */
  updateSavedExpression: SavedExpression;
};

export type SavedExpressionMutationCreateSavedExpressionArgs = {
  expression: SavedExpressionInput;
};

export type SavedExpressionMutationDeleteSavedExpressionArgs = {
  id: Scalars["ID"];
  version: Scalars["Int"];
};

export type SavedExpressionMutationUpdateSavedExpressionArgs = {
  expression: SavedExpressionInput;
  id: Scalars["ID"];
  version: Scalars["Int"];
};

/** SavedExpressions with Pagination result */
export type SavedExpressionPaginatedResult = {
  __typename?: "SavedExpressionPaginatedResult";
  items: Array<SavedExpression>;
  pagination: PaginationResult;
  sort?: Maybe<Array<SortFilterResult>>;
};

export type SavedExpressionQuery = {
  __typename?: "SavedExpressionQuery";
  savedExpression?: Maybe<SavedExpression>;
  savedExpressions: SavedExpressionPaginatedResult;
};

export type SavedExpressionQuerySavedExpressionArgs = {
  expressionId: Scalars["ID"];
};

export type SavedExpressionQuerySavedExpressionsArgs = {
  filters?: InputMaybe<Array<InputMaybe<Filter>>>;
  ids?: InputMaybe<Array<Scalars["ID"]>>;
  pagination?: InputMaybe<Pagination>;
  sorting?: InputMaybe<SortFilter>;
  status?: InputMaybe<CalculatedStatus>;
};

export enum SavedExpressionsSortField {
  Created = "created",
  CreatorName = "creatorName",
  Description = "description",
  Name = "name",
  Status = "status",
}

export type SearchAutoCompleteResults = {
  __typename?: "SearchAutoCompleteResults";
  clusters: Array<SearchedCluster>;
  vehicleIds: Array<VehicleIdentifier>;
};

export type SearchedCluster = {
  __typename?: "SearchedCluster";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  name: Scalars["String"];
};

export type ServerInfo = {
  __typename?: "ServerInfo";
  version: Scalars["String"];
};

export type SortFilter = {
  field: Scalars["String"];
  order: SortOrder;
};

export type SortFilterResult = {
  __typename?: "SortFilterResult";
  field: Scalars["String"];
  order: SortOrder;
};

export enum SortOrder {
  Ascend = "ascend",
  Descend = "descend",
}

export type SqlProviderInternal = {
  parameters: Array<Scalars["String"]>;
  sql: Scalars["String"];
};

export type StatusUpdate = {
  definitionFieldId: Scalars["ID"];
  status: CalculatedStatus;
};

export enum SupportedEventType {
  Expression = "expression",
  Fault = "fault",
  Repair = "repair",
}

export enum TimeSeriesComparisonGroup {
  BottomQuartile = "BOTTOM_QUARTILE",
  Mean = "MEAN",
  Median = "MEDIAN",
  Stddev = "STDDEV",
  TopQuartile = "TOP_QUARTILE",
}

export type TimeSeriesField = {
  __typename?: "TimeSeriesField";
  /**
   * The types of aggregation operations supported by the system when computing data for any interval size
   * (including 1 minute).
   */
  aggregationTypes?: Maybe<Array<AggregateType>>;
  displayName?: Maybe<Scalars["String"]>;
  /**
   * If applicable, the id of the underlying entity which generates the time series. This could be e.g. an expression id
   * or an anomaly detector id.
   */
  entityId?: Maybe<Scalars["String"]>;
  family: TimeSeriesFieldFamily;
  id: Scalars["TimeSeriesFieldId"];
  inputIds: Array<Scalars["TimeSeriesFieldId"]>;
  name?: Maybe<Scalars["String"]>;
  /**
   * The types of aggregation operations supported by the system when computing data for 1-minute intervals.
   * Suitable for e.g. use in expression definitions.
   */
  oneMinuteAggregationTypes?: Maybe<Array<AggregateType>>;
  type?: Maybe<TimeSeriesFieldDataType>;
  unit?: Maybe<Scalars["String"]>;
};

export enum TimeSeriesFieldDataType {
  Boolean = "BOOLEAN",
  Float = "FLOAT",
  Integer = "INTEGER",
  String = "STRING",
}

export enum TimeSeriesFieldFamily {
  AnomalyScore = "ANOMALY_SCORE",
  Expression = "EXPRESSION",
  Feature = "FEATURE",
  Signal = "SIGNAL",
}

export enum TimeSeriesIndependentVar {
  Mileage = "MILEAGE",
  Time = "TIME",
}

export type UpdateUserInput = {
  id: Scalars["ID"];
  roles: Array<Scalars["String"]>;
};

export type User = {
  __typename?: "User";
  /** The authentication provider details for this user. */
  auth?: Maybe<Array<UserAuth>>;
  email: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  roles: Array<Maybe<Role>>;
};

export type UserAuth = {
  __typename?: "UserAuth";
  /**
   * An ID of this user in the authentication provider of the specified source. Note that a single user can have multiple
   * IDs for the same source; e.g. in Auth0 a user can authenticate with a password or Google and these are two different
   * IDs.
   */
  id: Scalars["ID"];
  /** The authentication provider source. We only support Auth0 right now. */
  source: UserAuthSource;
};

export enum UserAuthSource {
  Auth0 = "Auth0",
}

export type UserCreatedEvent = {
  __typename?: "UserCreatedEvent";
  created: Scalars["DateTime"];
  creatorId: Scalars["String"];
  customerId: Scalars["String"];
  expression: SavedExpression;
  id: Scalars["ID"];
  lastModified: Scalars["DateTime"];
  name: Scalars["String"];
  status: CalculatedStatus;
  version: Scalars["Int"];
};

export type UserCreatedEvents = {
  __typename?: "UserCreatedEvents";
  expressionEvents: Array<UserCreatedEvent>;
  pagination: PaginationResult;
};

export enum UserEventsSortField {
  LastModified = "lastModified",
  Name = "name",
  Status = "status",
}

export type UserEventsSortInput = {
  field?: InputMaybe<UserEventsSortField>;
  order?: InputMaybe<SortOrder>;
};

export type UserSettings = {
  __typename?: "UserSettings";
  /** Customer-specific settings for this user. */
  customerSettings?: Maybe<Array<CustomerUserSettings>>;
  /**
   * A non-decreasing integer used to ensure write consistency. This will be incremented by the backend on every successful
   * update.
   */
  version?: Maybe<Scalars["Int"]>;
};

export type UserSettingsInput = {
  customerSettings?: InputMaybe<Array<CustomerUserSettingsInput>>;
  version?: InputMaybe<Scalars["Int"]>;
};

export type UserWithPermissions = {
  __typename?: "UserWithPermissions";
  authId: Scalars["ID"];
  customerPermissions?: Maybe<Array<Maybe<CustomerPermissions>>>;
  email: Scalars["String"];
  globalPermissions: Array<Permission>;
  id: Scalars["ID"];
  roles: Array<Scalars["ID"]>;
};

/** Vehicle input that is being used as variable for the queries */
export type VehicleFilterInput = {
  /** array of car delivery countries */
  deliveryCountry?: InputMaybe<Array<Scalars["String"]>>;
  /** array of car models */
  model?: InputMaybe<Array<Scalars["String"]>>;
  /** array of car model styles */
  modelStyle?: InputMaybe<Array<Scalars["String"]>>;
  /** array of car models years */
  modelYear?: InputMaybe<Array<Scalars["Int"]>>;
};

export type VehicleIdentifier = {
  __typename?: "VehicleIdentifier";
  /** Unique identifier for the vehicle (sometimes called PVIN, or pseudo-anonymized VIN) */
  id: Scalars["Pvin"];
  /** VIN. Only populated if this feature is enabled for the customer */
  vin?: Maybe<Scalars["Vin"]>;
};

/** The output equivalent of VehiclePointInput */
export type VehiclePoint = {
  __typename?: "VehiclePoint";
  odometer?: Maybe<Scalars["Float"]>;
  pvin: Scalars["ID"];
  timestamp?: Maybe<Scalars["DateTime"]>;
};

/**
 * Describes a specific moment for a PVIN that can be defined by either timestamp or mileage (or both).
 * These are the typical line items associated with a user-defined event.
 */
export type VehiclePointInput = {
  /** The odometer value to which this tuple applies. This or timestamp must be non-null. */
  odometer?: InputMaybe<Scalars["Float"]>;
  /** The id of the vehicle */
  pvin: Scalars["ID"];
  /** The timestamp to which this tuple applies. This or odometer must be non-null. */
  timestamp?: InputMaybe<Scalars["DateTime"]>;
};

export type VisitedEvent = {
  __typename?: "VisitedEvent";
  /** The ECU of the Fault event. Only applies to fault events, for all other events this be undefiend. */
  ecu?: Maybe<Scalars["String"]>;
  /** all events have Ids excpet repair events which just have a string */
  eventId: Scalars["String"];
  /** the name of the event */
  name: Scalars["String"];
  /** the date and time the user visited the vehicle */
  timestamp: Scalars["DateTime"];
  /** the type of event */
  type: EventType;
};

/** A record of a visit by the user to the details of a specific vehicle Id */
export type VisitedVehicle = {
  __typename?: "VisitedVehicle";
  /** The date & time the user visited the vehicle. */
  timestamp: Scalars["DateTime"];
  /** The vehicle Id that was visited by the user */
  vehicleId: Scalars["ID"];
};

export type VisitedVehicleInput = {
  timestamp: Scalars["DateTime"];
  vehicleId: Scalars["ID"];
};

/** VisitedVehicles with Pagination result */
export type VisitedVehiclePaginatedResult = {
  __typename?: "VisitedVehiclePaginatedResult";
  items: Array<VisitedVehicle>;
  pagination: PaginationResult;
};

export type CustomersQueryVariables = Exact<{ [key: string]: never }>;

export type CustomersQuery = {
  __typename?: "Query";
  customers: Array<{
    __typename?: "Customer";
    id: string;
    name: string;
    appVersion?: string | null;
    logoUri?: string | null;
    customApiUrl?: string | null;
    i18nFolder: string;
    commonResourcesUrl: string;
    defaultTimeSeriesFields?: Array<{
      __typename?: "DefaultTimeSeries";
      id: string;
      aggregateType: AggregateType;
      unit: string;
    }> | null;
    defaultEventAnalysisFields?: Array<{
      __typename?: "DefaultTimeSeries";
      id: string;
      aggregateType: AggregateType;
      unit: string;
    }> | null;
    defaultFaults?: Array<{ __typename?: "DefaultFaults"; code: string; troubleCode: string; source: string }> | null;
    features?: Array<{
      __typename?: "Feature";
      id: FeatureId;
      config?: { __typename: "EventAnalysisQuickActionsConfig"; fakePatternEditor?: boolean | null } | null;
    }> | null;
  }>;
};

export type ExpressionEventForEventHeaderQueryVariables = Exact<{
  customerId: Scalars["ID"];
  expressionEventId: Scalars["ID"];
}>;

export type ExpressionEventForEventHeaderQuery = {
  __typename?: "Query";
  expressionEvents?: {
    __typename?: "ExpressionEventQuery";
    expressionEvent: {
      __typename?: "ExpressionEvent";
      name: string;
      operator: ExpressionEventOperator;
      threshold: number;
      status: CalculatedStatus;
      expression: { __typename?: "SavedExpression"; status: CalculatedStatus; name: string };
    };
  } | null;
};

export type SearchAutoCompleteQueryVariables = Exact<{
  customerId: Scalars["ID"];
  input: Scalars["String"];
}>;

export type SearchAutoCompleteQuery = {
  __typename?: "Query";
  searchAutoComplete: {
    __typename?: "SearchAutoCompleteResults";
    clusters: Array<{ __typename?: "SearchedCluster"; id: string; name: string; description?: string | null }>;
    vehicleIds: Array<{ __typename?: "VehicleIdentifier"; id: string; vin?: string | null }>;
  };
};

export type CreateExpressionAnomalyMutationVariables = Exact<{
  customerId: Scalars["ID"];
  expressionAnomaly: ExpressionAnomalyInput;
}>;

export type CreateExpressionAnomalyMutation = {
  __typename?: "Mutation";
  expressionAnomalies?: {
    __typename?: "ExpressionAnomalyMutation";
    createExpressionAnomaly: { __typename?: "ExpressionAnomaly"; id: string; name: string };
  } | null;
};

export type DeleteExpressionAnomalyMutationVariables = Exact<{
  customerId: Scalars["ID"];
  id: Scalars["ID"];
  version: Scalars["Int"];
}>;

export type DeleteExpressionAnomalyMutation = {
  __typename?: "Mutation";
  expressionAnomalies?: {
    __typename?: "ExpressionAnomalyMutation";
    deleteExpressionAnomaly: {
      __typename?: "DeleteDependencyResponse";
      dependencies: Array<{ __typename?: "Dependency"; type: DependencyType; id: string; name: string } | null>;
    };
  } | null;
};

export type EditExpressionAnomalyMutationVariables = Exact<{
  customerId: Scalars["ID"];
  expressionAnomaly: ExpressionAnomalyInput;
  id: Scalars["ID"];
  version: Scalars["Int"];
}>;

export type EditExpressionAnomalyMutation = {
  __typename?: "Mutation";
  expressionAnomalies?: {
    __typename?: "ExpressionAnomalyMutation";
    updateExpressionAnomaly: { __typename?: "ExpressionAnomaly"; id: string; name: string };
  } | null;
};

export type ExpressionAnomalyByIdQueryVariables = Exact<{
  customerId: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type ExpressionAnomalyByIdQuery = {
  __typename?: "Query";
  expressionAnomalies?: {
    __typename?: "ExpressionAnomalyQuery";
    expressionAnomaly?: {
      __typename?: "ExpressionAnomaly";
      id: string;
      customerId: string;
      name: string;
      description?: string | null;
      threshold_lower: number;
      threshold_caution: number;
      threshold_warning: number;
      threshold_upper: number;
      lastModified: string;
      version: number;
      inputIds: Array<string>;
      expression: { __typename?: "SavedExpression"; definition: object; id: string; status: CalculatedStatus };
      creator?: { __typename?: "ReadOnlyUser"; isMe: boolean } | null;
    } | null;
  } | null;
};

export type GetExpressionAnomaliesQueryVariables = Exact<{
  customerId: Scalars["ID"];
  status?: InputMaybe<CalculatedStatus>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
}>;

export type GetExpressionAnomaliesQuery = {
  __typename?: "Query";
  expressionAnomalies?: {
    __typename?: "ExpressionAnomalyQuery";
    expressionAnomalies: Array<{
      __typename?: "ExpressionAnomaly";
      definitionFieldId: string;
      created: string;
      customerId: string;
      description?: string | null;
      id: string;
      lastModified: string;
      name: string;
      status: CalculatedStatus;
      threshold_caution: number;
      threshold_lower: number;
      threshold_upper: number;
      threshold_warning: number;
      version: number;
      type: AnomalyDetectorType;
      inputIds: Array<string>;
      creator?: { __typename?: "ReadOnlyUser"; isMe: boolean; name: string } | null;
      expression: {
        __typename?: "SavedExpression";
        created: string;
        customerId: string;
        definition: object;
        definitionFieldId: string;
        id: string;
        lastModified: string;
        name: string;
        readOnly?: boolean | null;
        status: CalculatedStatus;
        version: number;
        inputIds: Array<string>;
      };
    }>;
  } | null;
};

export type ExpressionAnomaliesForLibrarySignalSelectorQueryVariables = Exact<{
  customerId: Scalars["ID"];
  status?: InputMaybe<CalculatedStatus>;
}>;

export type ExpressionAnomaliesForLibrarySignalSelectorQuery = {
  __typename?: "Query";
  expressionAnomalies?: {
    __typename?: "ExpressionAnomalyQuery";
    expressionAnomalies: Array<{
      __typename?: "ExpressionAnomaly";
      id: string;
      name: string;
      status: CalculatedStatus;
      threshold_caution: number;
      threshold_warning: number;
      threshold_lower: number;
      threshold_upper: number;
    }>;
  } | null;
};

export type ListClustersQueryVariables = Exact<{
  filter: FilterInput;
  customerId: Scalars["ID"];
  pagination?: InputMaybe<Pagination>;
  sorting?: InputMaybe<SortFilter>;
}>;

export type ListClustersQuery = {
  __typename?: "Query";
  clusters?: {
    __typename?: "ClusterQuery";
    clusters: {
      __typename?: "ClusterPaginatedResult";
      data: Array<{
        __typename?: "Cluster";
        id: string;
        customerId: string;
        name: string;
        description?: string | null;
        created: string;
        version: number;
        vehicleCount: number;
        combinationCount: number;
        creator?: { __typename?: "ReadOnlyUser"; name: string; isMe: boolean } | null;
      }>;
      pagination: {
        __typename?: "PaginationResult";
        currentPage: number;
        pageSize: number;
        totalCount: number;
        totalPages: number;
      };
    };
  } | null;
};

export type ClusterNameQueryVariables = Exact<{
  customerId: Scalars["ID"];
  id: Scalars["ID"];
  filter?: InputMaybe<FilterInput>;
}>;

export type ClusterNameQuery = {
  __typename?: "Query";
  clusters?: {
    __typename?: "ClusterQuery";
    clusters: { __typename?: "ClusterPaginatedResult"; data: Array<{ __typename?: "Cluster"; name: string }> };
  } | null;
};

export type ClusterNonVehicleDetailsQueryVariables = Exact<{
  customerId: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type ClusterNonVehicleDetailsQuery = {
  __typename?: "Query";
  clusters?: {
    __typename?: "ClusterQuery";
    clusterNonVehicleDetails?: {
      __typename?: "ClusterNonVehicleDetails";
      id: string;
      customerId: string;
      name: string;
      description?: string | null;
      created: string;
      version: number;
      creator?: { __typename?: "ReadOnlyUser"; name: string; isMe: boolean } | null;
    } | null;
  } | null;
};

export type ClusterDetailsQueryVariables = Exact<{
  customerId: Scalars["ID"];
  id: Scalars["ID"];
  filter?: InputMaybe<FilterInput>;
}>;

export type ClusterDetailsQuery = {
  __typename?: "Query";
  clusters?: {
    __typename?: "ClusterQuery";
    clusters: {
      __typename?: "ClusterPaginatedResult";
      data: Array<{
        __typename?: "Cluster";
        id: string;
        customerId: string;
        name: string;
        description?: string | null;
        created: string;
        pvins: Array<string>;
        missingPvins: Array<string>;
        version: number;
        vehicleCount: number;
        combinationCount: number;
        creator?: { __typename?: "ReadOnlyUser"; name: string; isMe: boolean } | null;
      }>;
    };
  } | null;
};

export type CreateClusterMutationVariables = Exact<{
  customerId: Scalars["ID"];
  input: ClusterCreateInput;
}>;

export type CreateClusterMutation = {
  __typename?: "Mutation";
  clusters?: {
    __typename?: "ClusterMutation";
    createCluster?: {
      __typename?: "ClusterUpserted";
      missingPvins: Array<string>;
      cluster: { __typename?: "Cluster"; id: string; name: string };
      repeatedCombinations: Array<{
        __typename?: "ClusterRedshift";
        pvin: string;
        startDate?: string | null;
        endDate?: string | null;
      }>;
    } | null;
  } | null;
};

export type DeleteClusterMutationVariables = Exact<{
  customerId: Scalars["ID"];
  clusterId: Scalars["ID"];
}>;

export type DeleteClusterMutation = {
  __typename?: "Mutation";
  clusters?: { __typename?: "ClusterMutation"; deleteCluster?: string | null } | null;
};

export type UpdateClusterMutationVariables = Exact<{
  customerId: Scalars["ID"];
  clusterId: Scalars["ID"];
  version: Scalars["Int"];
  input: Array<ClusterRedshiftCreateInput> | ClusterRedshiftCreateInput;
  action: ClusterUpdateAction;
  ignoreDateRanges?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<FilterInput>;
}>;

export type UpdateClusterMutation = {
  __typename?: "Mutation";
  clusters?: {
    __typename?: "ClusterMutation";
    updateClusterPvins?: {
      __typename?: "ClusterUpserted";
      missingPvins: Array<string>;
      cluster: {
        __typename?: "Cluster";
        id: string;
        customerId: string;
        name: string;
        description?: string | null;
        created: string;
        pvins: Array<string>;
        version: number;
        vehicleCount: number;
        creator?: { __typename?: "ReadOnlyUser"; name: string; isMe: boolean } | null;
      };
      repeatedCombinations: Array<{
        __typename?: "ClusterRedshift";
        pvin: string;
        startDate?: string | null;
        endDate?: string | null;
      }>;
    } | null;
  } | null;
};

export type EditClusterMutationVariables = Exact<{
  customerId: Scalars["ID"];
  clusterId: Scalars["ID"];
  version: Scalars["Int"];
  name: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
}>;

export type EditClusterMutation = {
  __typename?: "Mutation";
  clusters?: {
    __typename?: "ClusterMutation";
    editCluster?: {
      __typename?: "Cluster";
      id: string;
      customerId: string;
      name: string;
      description?: string | null;
    } | null;
  } | null;
};

export type CopyClusterMutationVariables = Exact<{
  clusterId: Scalars["ID"];
  name: Scalars["String"];
  description: Scalars["String"];
  customerId: Scalars["ID"];
}>;

export type CopyClusterMutation = {
  __typename?: "Mutation";
  clusters?: { __typename?: "ClusterMutation"; copyCluster?: string | null } | null;
};

export type CreateExpressionEventMutationVariables = Exact<{
  expressionEvent: ExpressionEventInput;
  customerId: Scalars["ID"];
}>;

export type CreateExpressionEventMutation = {
  __typename?: "Mutation";
  expressionEvents?: {
    __typename?: "ExpressionEventMutation";
    createExpressionEvent: {
      __typename?: "ExpressionEvent";
      created: string;
      customerId: string;
      id: string;
      name: string;
      creator?: { __typename?: "ReadOnlyUser"; name: string } | null;
      expression: { __typename?: "SavedExpression"; id: string; name: string };
    };
  } | null;
};

export type GetExpressionEventForFormQueryVariables = Exact<{
  customerId: Scalars["ID"];
  expressionEventId: Scalars["ID"];
}>;

export type GetExpressionEventForFormQuery = {
  __typename?: "Query";
  expressionEvents?: {
    __typename?: "ExpressionEventQuery";
    expressionEvent: {
      __typename?: "ExpressionEvent";
      created: string;
      customerId: string;
      id: string;
      lastModified: string;
      name: string;
      operator: ExpressionEventOperator;
      threshold: number;
      version: number;
      creator?: { __typename?: "ReadOnlyUser"; name: string; isMe: boolean } | null;
      expression: {
        __typename?: "SavedExpression";
        id: string;
        status: CalculatedStatus;
        creator?: { __typename?: "ReadOnlyUser"; name: string; isMe: boolean } | null;
      };
    };
  } | null;
};

export type GetExpressionEventsQueryVariables = Exact<{
  customerId: Scalars["ID"];
}>;

export type GetExpressionEventsQuery = {
  __typename?: "Query";
  expressionEvents?: {
    __typename?: "ExpressionEventQuery";
    expressionEvents: Array<{
      __typename?: "ExpressionEvent";
      created: string;
      customerId: string;
      id: string;
      lastModified: string;
      name: string;
      version: number;
      creator?: { __typename?: "ReadOnlyUser"; name: string } | null;
      expression: { __typename?: "SavedExpression"; name: string; id: string; status: CalculatedStatus };
    }>;
  } | null;
};

export type GetSavedExpressionsForEventFormQueryVariables = Exact<{
  customerId: Scalars["ID"];
  status?: InputMaybe<CalculatedStatus>;
}>;

export type GetSavedExpressionsForEventFormQuery = {
  __typename?: "Query";
  savedExpressions?: {
    __typename?: "SavedExpressionQuery";
    savedExpressions: {
      __typename?: "SavedExpressionPaginatedResult";
      items: Array<{
        __typename?: "SavedExpression";
        id: string;
        name: string;
        status: CalculatedStatus;
        creator?: { __typename?: "ReadOnlyUser"; name: string; isMe: boolean } | null;
      }>;
    };
  } | null;
};

export type UpdateExpressionEventMutationVariables = Exact<{
  customerId: Scalars["ID"];
  expressionEvent: ExpressionEventInput;
  updateExpressionEventId: Scalars["ID"];
  version: Scalars["Int"];
}>;

export type UpdateExpressionEventMutation = {
  __typename?: "Mutation";
  expressionEvents?: {
    __typename?: "ExpressionEventMutation";
    updateExpressionEvent: { __typename?: "ExpressionEvent"; version: number };
  } | null;
};

export type ExpressionEventsForRelatedEventTableQueryVariables = Exact<{
  customerId: Scalars["ID"];
  ids?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type ExpressionEventsForRelatedEventTableQuery = {
  __typename?: "Query";
  expressionEvents?: {
    __typename?: "ExpressionEventQuery";
    expressionEvents: Array<{ __typename?: "ExpressionEvent"; id: string; name: string }>;
  } | null;
};

export type AppendVisitedEventMutationVariables = Exact<{
  customerId: Scalars["ID"];
  eventId: Scalars["String"];
  type: EventType;
  name: Scalars["String"];
  ecu?: InputMaybe<Scalars["String"]>;
}>;

export type AppendVisitedEventMutation = {
  __typename?: "Mutation";
  appendVisitedEvent: {
    __typename?: "UserSettings";
    version?: number | null;
    customerSettings?: Array<{
      __typename?: "CustomerUserSettings";
      customerId: string;
      recentEvents?: Array<{
        __typename?: "VisitedEvent";
        timestamp: string;
        eventId: string;
        type: EventType;
        name: string;
        ecu?: string | null;
      }> | null;
    }> | null;
  };
};

export type DeleteExpressionEventMutationVariables = Exact<{
  customerId: Scalars["ID"];
  id: Scalars["ID"];
  version: Scalars["Int"];
}>;

export type DeleteExpressionEventMutation = {
  __typename?: "Mutation";
  expressionEvents?: { __typename?: "ExpressionEventMutation"; deleteExpressionEvent?: string | null } | null;
};

export type RecentEventsQueryVariables = Exact<{ [key: string]: never }>;

export type RecentEventsQuery = {
  __typename?: "Query";
  userSettings: {
    __typename?: "UserSettings";
    customerSettings?: Array<{
      __typename?: "CustomerUserSettings";
      customerId: string;
      recentEvents?: Array<{
        __typename?: "VisitedEvent";
        timestamp: string;
        eventId: string;
        type: EventType;
        name: string;
        ecu?: string | null;
      }> | null;
    }> | null;
  };
};

export type UserEventsQueryVariables = Exact<{
  customerId: Scalars["ID"];
  pagination?: InputMaybe<Pagination>;
  sorting?: InputMaybe<UserEventsSortInput>;
}>;

export type UserEventsQuery = {
  __typename?: "Query";
  userEvents: {
    __typename?: "UserCreatedEvents";
    expressionEvents: Array<{
      __typename?: "UserCreatedEvent";
      id: string;
      customerId: string;
      name: string;
      created: string;
      lastModified: string;
      creatorId: string;
      version: number;
      status: CalculatedStatus;
      expression: { __typename?: "SavedExpression"; name: string; id: string };
    }>;
    pagination: { __typename?: "PaginationResult"; currentPage: number; pageSize: number; totalCount: number };
  };
};

export type CreateForecastMutationVariables = Exact<{
  customerId: Scalars["ID"];
  forecast: ForecastInput;
}>;

export type CreateForecastMutation = {
  __typename?: "Mutation";
  forecasts?: {
    __typename?: "ForecastMutation";
    createForecast: {
      __typename?: "Forecast";
      created: string;
      discountUnit: DensityFunctionIndependentVariable;
      id: string;
      lastModified: string;
      name: string;
      version: number;
      creator: { __typename?: "ReadOnlyUser"; name: string };
      discounts: Array<
        Array<{
          __typename?: "DiscountDensityFunction";
          totalDiscount: number;
          distribution?: {
            __typename?: "DensityFunction";
            y0: number;
            points: Array<{ __typename?: "Point"; x: number; y: number }>;
          } | null;
        }>
      >;
      productionForecast: {
        __typename?: "ProductionForecast";
        created: string;
        customerId: string;
        id: string;
        lastModified: string;
        name: string;
        version: number;
        creator?: { __typename?: "ReadOnlyUser"; name: string } | null;
        definition: {
          __typename?: "ProductionForecastDefinition";
          monthCount?: number | null;
          startingMonth?: string | null;
          forecast: Array<{
            __typename?: "ProductionForecastUnitDefinition";
            country?: string | null;
            modelType?: string | null;
            modelYear?: number | null;
            unitCounts: Array<number | null>;
          }>;
        };
      };
      repairConcepts: Array<{
        __typename?: "ForecastRepair";
        threshold: number;
        repairConcept: {
          __typename?: "RepairConcept";
          created: string;
          id: string;
          lastModified: string;
          name: string;
          repairEventType?: RepairEventType | null;
          version: number;
          cost: {
            __typename?: "CostDensityFunction";
            densityUnit: DensityFunctionIndependentVariable;
            totalCost: number;
            distribution: {
              __typename?: "DensityFunction";
              y0: number;
              points: Array<{ __typename?: "Point"; x: number; y: number }>;
            };
          };
          creator: { __typename?: "ReadOnlyUser"; name: string };
          triggerExpressionEvent?: {
            __typename?: "ExpressionEvent";
            created: string;
            customerId: string;
            id: string;
            lastModified: string;
            name: string;
            version: number;
            creator?: { __typename?: "ReadOnlyUser"; name: string } | null;
            expression: { __typename?: "SavedExpression"; status: CalculatedStatus };
          } | null;
        };
      }>;
    };
  } | null;
};

export type ForecastsQueryVariables = Exact<{
  customerId: Scalars["ID"];
  ids?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type ForecastsQuery = {
  __typename?: "Query";
  forecasts?: {
    __typename?: "ForecastQuery";
    forecasts: Array<{
      __typename?: "Forecast";
      id: string;
      name: string;
      discountUnit: DensityFunctionIndependentVariable;
      created: string;
      lastModified: string;
      version: number;
      productionForecast: {
        __typename?: "ProductionForecast";
        id: string;
        name: string;
        definition: {
          __typename?: "ProductionForecastDefinition";
          startingMonth?: string | null;
          monthCount?: number | null;
          forecast: Array<{
            __typename?: "ProductionForecastUnitDefinition";
            country?: string | null;
            modelType?: string | null;
            modelYear?: number | null;
            unitCounts: Array<number | null>;
          }>;
        };
      };
      repairConcepts: Array<{
        __typename?: "ForecastRepair";
        threshold: number;
        repairConcept: {
          __typename?: "RepairConcept";
          id: string;
          name: string;
          repairEventType?: RepairEventType | null;
          triggerExpressionEvent?: {
            __typename?: "ExpressionEvent";
            id: string;
            name: string;
            expression: { __typename?: "SavedExpression"; status: CalculatedStatus };
          } | null;
          cost: {
            __typename?: "CostDensityFunction";
            totalCost: number;
            densityUnit: DensityFunctionIndependentVariable;
            distribution: {
              __typename?: "DensityFunction";
              y0: number;
              points: Array<{ __typename?: "Point"; x: number; y: number }>;
            };
          };
        };
      }>;
      discounts: Array<
        Array<{
          __typename?: "DiscountDensityFunction";
          totalDiscount: number;
          distribution?: {
            __typename?: "DensityFunction";
            y0: number;
            points: Array<{ __typename?: "Point"; x: number; y: number }>;
          } | null;
        }>
      >;
      creator: { __typename?: "ReadOnlyUser"; name: string };
    }>;
  } | null;
};

export type ProductionForecastsQueryVariables = Exact<{
  customerId: Scalars["ID"];
}>;

export type ProductionForecastsQuery = {
  __typename?: "Query";
  productionForecasts?: {
    __typename?: "ProductionForecastQuery";
    productionForecasts: Array<{
      __typename?: "ProductionForecast";
      id: string;
      customerId: string;
      name: string;
      created: string;
      lastModified: string;
      version: number;
      creator?: { __typename?: "ReadOnlyUser"; name: string } | null;
    }>;
  } | null;
};

export type UpdateForecastMutationVariables = Exact<{
  customerId: Scalars["ID"];
  id: Scalars["ID"];
  forecast: ForecastInput;
  version: Scalars["Int"];
}>;

export type UpdateForecastMutation = {
  __typename?: "Mutation";
  forecasts?: {
    __typename?: "ForecastMutation";
    updateForecast: {
      __typename?: "Forecast";
      created: string;
      discountUnit: DensityFunctionIndependentVariable;
      id: string;
      lastModified: string;
      name: string;
      version: number;
      creator: { __typename?: "ReadOnlyUser"; name: string };
      discounts: Array<
        Array<{
          __typename?: "DiscountDensityFunction";
          totalDiscount: number;
          distribution?: {
            __typename?: "DensityFunction";
            y0: number;
            points: Array<{ __typename?: "Point"; x: number; y: number }>;
          } | null;
        }>
      >;
      productionForecast: {
        __typename?: "ProductionForecast";
        created: string;
        customerId: string;
        id: string;
        lastModified: string;
        name: string;
        version: number;
        creator?: { __typename?: "ReadOnlyUser"; name: string } | null;
        definition: {
          __typename?: "ProductionForecastDefinition";
          monthCount?: number | null;
          startingMonth?: string | null;
          forecast: Array<{
            __typename?: "ProductionForecastUnitDefinition";
            country?: string | null;
            modelType?: string | null;
            modelYear?: number | null;
            unitCounts: Array<number | null>;
          }>;
        };
      };
      repairConcepts: Array<{
        __typename?: "ForecastRepair";
        threshold: number;
        repairConcept: {
          __typename?: "RepairConcept";
          created: string;
          id: string;
          lastModified: string;
          name: string;
          repairEventType?: RepairEventType | null;
          version: number;
          cost: {
            __typename?: "CostDensityFunction";
            densityUnit: DensityFunctionIndependentVariable;
            totalCost: number;
            distribution: {
              __typename?: "DensityFunction";
              y0: number;
              points: Array<{ __typename?: "Point"; x: number; y: number }>;
            };
          };
          creator: { __typename?: "ReadOnlyUser"; name: string };
        };
      }>;
    };
  } | null;
};

export type HomeQueryVariables = Exact<{
  recentDetailVehiclesPagination?: InputMaybe<Pagination>;
}>;

export type HomeQuery = {
  __typename?: "Query";
  userSettings: {
    __typename?: "UserSettings";
    customerSettings?: Array<{
      __typename?: "CustomerUserSettings";
      customerId: string;
      recentDetailVehicles?: {
        __typename?: "VisitedVehiclePaginatedResult";
        items: Array<{ __typename?: "VisitedVehicle"; timestamp: string; vehicleId: string }>;
      } | null;
    }> | null;
  };
};

export type CreateSavedExpressionMutationVariables = Exact<{
  customerId: Scalars["ID"];
  expression: SavedExpressionInput;
}>;

export type CreateSavedExpressionMutation = {
  __typename?: "Mutation";
  savedExpressions?: {
    __typename?: "SavedExpressionMutation";
    createSavedExpression: { __typename?: "SavedExpression"; id: string; name: string };
  } | null;
};

export type DeleteSavedExpressionMutationVariables = Exact<{
  customerId: Scalars["ID"];
  id: Scalars["ID"];
  version: Scalars["Int"];
}>;

export type DeleteSavedExpressionMutation = {
  __typename?: "Mutation";
  savedExpressions?: {
    __typename?: "SavedExpressionMutation";
    deleteSavedExpression: {
      __typename?: "DeleteDependencyResponse";
      dependencies: Array<{
        __typename?: "Dependency";
        type: DependencyType;
        id: string;
        name: string;
        owner?: string | null;
      } | null>;
    };
  } | null;
};

export type GetSavedExpressionByIdQueryVariables = Exact<{
  customerId: Scalars["ID"];
  expressionId: Scalars["ID"];
}>;

export type GetSavedExpressionByIdQuery = {
  __typename?: "Query";
  savedExpressions?: {
    __typename?: "SavedExpressionQuery";
    savedExpression?: {
      __typename?: "SavedExpression";
      created: string;
      customerId: string;
      id: string;
      definitionFieldId: string;
      lastModified: string;
      name: string;
      description?: string | null;
      status: CalculatedStatus;
      version: number;
      definition: object;
      readOnly?: boolean | null;
      creator?: { __typename?: "ReadOnlyUser"; isMe: boolean; name: string } | null;
    } | null;
  } | null;
};

export type GetSavedExpressionsQueryVariables = Exact<{
  customerId: Scalars["ID"];
  status?: InputMaybe<CalculatedStatus>;
  pagination?: InputMaybe<Pagination>;
  sorting?: InputMaybe<SortFilter>;
  filters?: InputMaybe<Array<InputMaybe<Filter>> | InputMaybe<Filter>>;
}>;

export type GetSavedExpressionsQuery = {
  __typename?: "Query";
  savedExpressions?: {
    __typename?: "SavedExpressionQuery";
    savedExpressions: {
      __typename?: "SavedExpressionPaginatedResult";
      items: Array<{
        __typename?: "SavedExpression";
        created: string;
        customerId: string;
        id: string;
        lastModified: string;
        name: string;
        description?: string | null;
        status: CalculatedStatus;
        version: number;
        readOnly?: boolean | null;
        definitionFieldId: string;
        definition: object;
        inputIds: Array<string>;
        creator?: { __typename?: "ReadOnlyUser"; name: string; isMe: boolean } | null;
      }>;
      pagination: { __typename?: "PaginationResult"; totalCount: number; pageSize: number; currentPage: number };
    };
  } | null;
};

export type UpdateSavedExpressionMutationVariables = Exact<{
  customerId: Scalars["ID"];
  id: Scalars["ID"];
  expression: SavedExpressionInput;
  version: Scalars["Int"];
}>;

export type UpdateSavedExpressionMutation = {
  __typename?: "Mutation";
  savedExpressions?: {
    __typename?: "SavedExpressionMutation";
    updateSavedExpression: { __typename?: "SavedExpression"; id: string; name: string };
  } | null;
};

export type SavedExpressionsQueryVariables = Exact<{
  customerId: Scalars["ID"];
  status?: InputMaybe<CalculatedStatus>;
  ids?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  pagination?: InputMaybe<Pagination>;
  sorting?: InputMaybe<SortFilter>;
}>;

export type SavedExpressionsQuery = {
  __typename?: "Query";
  savedExpressions?: {
    __typename?: "SavedExpressionQuery";
    savedExpressions: {
      __typename?: "SavedExpressionPaginatedResult";
      items: Array<{
        __typename?: "SavedExpression";
        id: string;
        customerId: string;
        name: string;
        status: CalculatedStatus;
        created: string;
        lastModified: string;
        version: number;
        definition: object;
        inputIds: Array<string>;
        creator?: { __typename?: "ReadOnlyUser"; name: string } | null;
      }>;
      pagination: { __typename?: "PaginationResult"; totalCount: number; pageSize: number; currentPage: number };
    };
  } | null;
};

export type CreateRepairConceptMutationVariables = Exact<{
  customerId: Scalars["ID"];
  repairConcept: RepairConceptInput;
}>;

export type CreateRepairConceptMutation = {
  __typename?: "Mutation";
  repairConcepts?: {
    __typename?: "RepairConceptMutation";
    createRepairConcept: { __typename?: "RepairConcept"; id: string };
  } | null;
};

export type RepairConceptsQueryVariables = Exact<{
  customerId: Scalars["ID"];
  ids?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type RepairConceptsQuery = {
  __typename?: "Query";
  repairConcepts?: {
    __typename?: "RepairConceptQuery";
    repairConcepts: Array<{
      __typename?: "RepairConcept";
      id: string;
      name: string;
      repairEventType?: RepairEventType | null;
      created: string;
      lastModified: string;
      version: number;
      triggerExpressionEvent?: {
        __typename?: "ExpressionEvent";
        id: string;
        name: string;
        expression: { __typename?: "SavedExpression"; status: CalculatedStatus };
      } | null;
      cost: {
        __typename?: "CostDensityFunction";
        totalCost: number;
        densityUnit: DensityFunctionIndependentVariable;
        distribution: {
          __typename?: "DensityFunction";
          y0: number;
          points: Array<{ __typename?: "Point"; x: number; y: number }>;
        };
      };
      creator: { __typename?: "ReadOnlyUser"; name: string };
    }>;
  } | null;
};

export type UpdateRepairConceptMutationVariables = Exact<{
  customerId: Scalars["ID"];
  id: Scalars["ID"];
  repairConcept: RepairConceptInput;
  version: Scalars["Int"];
}>;

export type UpdateRepairConceptMutation = {
  __typename?: "Mutation";
  repairConcepts?: {
    __typename?: "RepairConceptMutation";
    updateRepairConcept: { __typename?: "RepairConcept"; id: string };
  } | null;
};

export type AppendVisitedVehicleMutationVariables = Exact<{
  customerId: Scalars["ID"];
  vehicleId: Scalars["ID"];
}>;

export type AppendVisitedVehicleMutation = {
  __typename?: "Mutation";
  appendVisitedVehicle: {
    __typename?: "UserSettings";
    version?: number | null;
    customerSettings?: Array<{
      __typename?: "CustomerUserSettings";
      customerId: string;
      recentDetailVehicles?: {
        __typename?: "VisitedVehiclePaginatedResult";
        items: Array<{ __typename?: "VisitedVehicle"; timestamp: string; vehicleId: string }>;
        pagination: { __typename?: "PaginationResult"; currentPage: number; pageSize: number };
      } | null;
    }> | null;
  };
};

export type AllClustersForSyncQueryVariables = Exact<{
  customerId: Scalars["ID"];
}>;

export type AllClustersForSyncQuery = {
  __typename?: "Query";
  clusters?: {
    __typename?: "ClusterQuery";
    clusters: {
      __typename?: "ClusterPaginatedResult";
      data: Array<{
        __typename?: "Cluster";
        id: string;
        name: string;
        version: number;
        customerAttributes?: object | null;
        creator?: { __typename?: "ReadOnlyUser"; name: string } | null;
      }>;
    };
  } | null;
};

export type CreateClusterForSyncMutationVariables = Exact<{
  customerId: Scalars["ID"];
  input: ClusterCreateInput;
}>;

export type CreateClusterForSyncMutation = {
  __typename?: "Mutation";
  clusters?: {
    __typename?: "ClusterMutation";
    createCluster?: {
      __typename?: "ClusterUpserted";
      cluster: {
        __typename?: "Cluster";
        id: string;
        name: string;
        version: number;
        customerAttributes?: object | null;
        creator?: { __typename?: "ReadOnlyUser"; name: string } | null;
      };
    } | null;
  } | null;
};

export type UpdateClusterForSyncMutationVariables = Exact<{
  customerId: Scalars["ID"];
  clusterId: Scalars["ID"];
  version: Scalars["Int"];
  input: Array<ClusterRedshiftCreateInput> | ClusterRedshiftCreateInput;
  action: ClusterUpdateAction;
}>;

export type UpdateClusterForSyncMutation = {
  __typename?: "Mutation";
  clusters?: {
    __typename?: "ClusterMutation";
    updateClusterPvins?: {
      __typename?: "ClusterUpserted";
      cluster: { __typename?: "Cluster"; id: string; name: string; version: number };
    } | null;
  } | null;
};

export type ExpressionAnomalyDetectorsForTimeSeriesFieldIdQueryVariables = Exact<{
  customerId: Scalars["ID"];
  ids: Array<Scalars["TimeSeriesFieldId"]> | Scalars["TimeSeriesFieldId"];
}>;

export type ExpressionAnomalyDetectorsForTimeSeriesFieldIdQuery = {
  __typename?: "Query";
  expressionAnomalies?: {
    __typename?: "ExpressionAnomalyQuery";
    expressionAnomalyDetectorsForTimeSeriesFieldId: Array<{
      __typename?: "AnomalyDetector";
      id: string;
      name: string;
      nameKey: string;
      description?: string | null;
      cautionThreshold: number;
      warningThreshold: number;
      created: string;
      lastModified?: string | null;
      version: number;
      isCustomerMLModel?: boolean | null;
      type?: AnomalyDetectorType | null;
      timeSeriesFieldId: string;
      inputIds: Array<string>;
      creator?: { __typename?: "ReadOnlyUser"; name: string; isMe: boolean } | null;
    } | null>;
  } | null;
};

export type AllClustersWithNamesQueryVariables = Exact<{
  customerId: Scalars["ID"];
}>;

export type AllClustersWithNamesQuery = {
  __typename?: "Query";
  clusters?: {
    __typename?: "ClusterQuery";
    clusters: {
      __typename?: "ClusterPaginatedResult";
      data: Array<{ __typename?: "Cluster"; id: string; name: string }>;
    };
  } | null;
};

export type TimeSeriesFieldsQueryVariables = Exact<{
  customerId: Scalars["ID"];
}>;

export type TimeSeriesFieldsQuery = {
  __typename?: "Query";
  timeSeriesFields: Array<{
    __typename?: "TimeSeriesField";
    family: TimeSeriesFieldFamily;
    id: string;
    entityId?: string | null;
    name?: string | null;
    displayName?: string | null;
    type?: TimeSeriesFieldDataType | null;
    unit?: string | null;
    inputIds: Array<string>;
    oneMinuteAggregationTypes?: Array<AggregateType> | null;
    aggregationTypes?: Array<AggregateType> | null;
  }>;
};

export type ExpressionEventsQueryVariables = Exact<{
  customerId: Scalars["ID"];
  ids?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type ExpressionEventsQuery = {
  __typename?: "Query";
  expressionEvents?: {
    __typename?: "ExpressionEventQuery";
    expressionEvents: Array<{
      __typename?: "ExpressionEvent";
      id: string;
      name: string;
      operator: ExpressionEventOperator;
      threshold: number;
      definitionFieldId: string;
      status: CalculatedStatus;
      expression: { __typename?: "SavedExpression"; name: string; status: CalculatedStatus; definitionFieldId: string };
    }>;
  } | null;
};

export type GetExpressionEventQueryVariables = Exact<{
  customerId: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetExpressionEventQuery = {
  __typename?: "Query";
  expressionEvents?: {
    __typename?: "ExpressionEventQuery";
    expressionEvent: {
      __typename?: "ExpressionEvent";
      id: string;
      name: string;
      operator: ExpressionEventOperator;
      threshold: number;
      expression: { __typename?: "SavedExpression"; name: string; status: CalculatedStatus; definitionFieldId: string };
    };
  } | null;
};

export type TimeSeriesForPatternValidationAndLookupQueryVariables = Exact<{
  customerId: Scalars["ID"];
}>;

export type TimeSeriesForPatternValidationAndLookupQuery = {
  __typename?: "Query";
  expressionAnomalies?: {
    __typename?: "ExpressionAnomalyQuery";
    expressionAnomalies: Array<{
      __typename?: "ExpressionAnomaly";
      customerId: string;
      threshold_upper: number;
      threshold_lower: number;
      definitionFieldId: string;
      id: string;
      inputIds: Array<string>;
      expression: { __typename?: "SavedExpression"; definition: object };
    }>;
  } | null;
  savedExpressions?: {
    __typename?: "SavedExpressionQuery";
    savedExpressions: {
      __typename?: "SavedExpressionPaginatedResult";
      items: Array<{
        __typename?: "SavedExpression";
        customerId: string;
        id: string;
        definitionFieldId: string;
        definition: object;
      }>;
    };
  } | null;
};

export const CustomersDocument = `
    query Customers {
  customers {
    id
    name
    appVersion
    logoUri
    customApiUrl
    defaultTimeSeriesFields {
      id
      aggregateType
      unit
    }
    defaultEventAnalysisFields {
      id
      aggregateType
      unit
    }
    defaultFaults {
      code
      troubleCode
      source
    }
    i18nFolder
    commonResourcesUrl
    features {
      id
      config {
        __typename
        ... on EventAnalysisQuickActionsConfig {
          fakePatternEditor
        }
      }
    }
  }
}
    `;
export const useCustomersQuery = <TData = CustomersQuery, TError = unknown>(
  variables?: CustomersQueryVariables,
  options?: UseQueryOptions<CustomersQuery, TError, TData>
) =>
  useQuery<CustomersQuery, TError, TData>(
    variables === undefined ? ["Customers"] : ["Customers", variables],
    useApiFetcher<CustomersQuery, CustomersQueryVariables>(CustomersDocument).bind(null, variables),
    options
  );

useCustomersQuery.getKey = (variables?: CustomersQueryVariables) =>
  variables === undefined ? ["Customers"] : ["Customers", variables];
export const ExpressionEventForEventHeaderDocument = `
    query expressionEventForEventHeader($customerId: ID!, $expressionEventId: ID!) {
  expressionEvents(customerId: $customerId) {
    expressionEvent(id: $expressionEventId) {
      name
      expression {
        status
        name
      }
      operator
      threshold
      status
    }
  }
}
    `;
export const useExpressionEventForEventHeaderQuery = <TData = ExpressionEventForEventHeaderQuery, TError = unknown>(
  variables: ExpressionEventForEventHeaderQueryVariables,
  options?: UseQueryOptions<ExpressionEventForEventHeaderQuery, TError, TData>
) =>
  useQuery<ExpressionEventForEventHeaderQuery, TError, TData>(
    ["expressionEventForEventHeader", variables],
    useApiFetcher<ExpressionEventForEventHeaderQuery, ExpressionEventForEventHeaderQueryVariables>(
      ExpressionEventForEventHeaderDocument
    ).bind(null, variables),
    options
  );

useExpressionEventForEventHeaderQuery.getKey = (variables: ExpressionEventForEventHeaderQueryVariables) => [
  "expressionEventForEventHeader",
  variables,
];
export const SearchAutoCompleteDocument = `
    query SearchAutoComplete($customerId: ID!, $input: String!) {
  searchAutoComplete(customerId: $customerId, input: $input) {
    clusters {
      id
      name
      description
    }
    vehicleIds {
      id
      vin
    }
  }
}
    `;
export const useSearchAutoCompleteQuery = <TData = SearchAutoCompleteQuery, TError = unknown>(
  variables: SearchAutoCompleteQueryVariables,
  options?: UseQueryOptions<SearchAutoCompleteQuery, TError, TData>
) =>
  useQuery<SearchAutoCompleteQuery, TError, TData>(
    ["SearchAutoComplete", variables],
    useApiFetcher<SearchAutoCompleteQuery, SearchAutoCompleteQueryVariables>(SearchAutoCompleteDocument).bind(
      null,
      variables
    ),
    options
  );

useSearchAutoCompleteQuery.getKey = (variables: SearchAutoCompleteQueryVariables) => ["SearchAutoComplete", variables];
export const CreateExpressionAnomalyDocument = `
    mutation createExpressionAnomaly($customerId: ID!, $expressionAnomaly: ExpressionAnomalyInput!) {
  expressionAnomalies(customerId: $customerId) {
    createExpressionAnomaly(expressionAnomaly: $expressionAnomaly) {
      id
      name
    }
  }
}
    `;
export const useCreateExpressionAnomalyMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateExpressionAnomalyMutation,
    TError,
    CreateExpressionAnomalyMutationVariables,
    TContext
  >
) =>
  useMutation<CreateExpressionAnomalyMutation, TError, CreateExpressionAnomalyMutationVariables, TContext>(
    ["createExpressionAnomaly"],
    useApiFetcher<CreateExpressionAnomalyMutation, CreateExpressionAnomalyMutationVariables>(
      CreateExpressionAnomalyDocument
    ),
    options
  );
export const DeleteExpressionAnomalyDocument = `
    mutation deleteExpressionAnomaly($customerId: ID!, $id: ID!, $version: Int!) {
  expressionAnomalies(customerId: $customerId) {
    deleteExpressionAnomaly(id: $id, version: $version) {
      dependencies {
        type
        id
        name
      }
    }
  }
}
    `;
export const useDeleteExpressionAnomalyMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteExpressionAnomalyMutation,
    TError,
    DeleteExpressionAnomalyMutationVariables,
    TContext
  >
) =>
  useMutation<DeleteExpressionAnomalyMutation, TError, DeleteExpressionAnomalyMutationVariables, TContext>(
    ["deleteExpressionAnomaly"],
    useApiFetcher<DeleteExpressionAnomalyMutation, DeleteExpressionAnomalyMutationVariables>(
      DeleteExpressionAnomalyDocument
    ),
    options
  );
export const EditExpressionAnomalyDocument = `
    mutation editExpressionAnomaly($customerId: ID!, $expressionAnomaly: ExpressionAnomalyInput!, $id: ID!, $version: Int!) {
  expressionAnomalies(customerId: $customerId) {
    updateExpressionAnomaly(
      expressionAnomaly: $expressionAnomaly
      id: $id
      version: $version
    ) {
      id
      name
    }
  }
}
    `;
export const useEditExpressionAnomalyMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<EditExpressionAnomalyMutation, TError, EditExpressionAnomalyMutationVariables, TContext>
) =>
  useMutation<EditExpressionAnomalyMutation, TError, EditExpressionAnomalyMutationVariables, TContext>(
    ["editExpressionAnomaly"],
    useApiFetcher<EditExpressionAnomalyMutation, EditExpressionAnomalyMutationVariables>(EditExpressionAnomalyDocument),
    options
  );
export const ExpressionAnomalyByIdDocument = `
    query expressionAnomalyById($customerId: ID!, $id: ID!) {
  expressionAnomalies(customerId: $customerId) {
    expressionAnomaly(id: $id) {
      id
      customerId
      name
      description
      expression {
        definition
        id
        status
      }
      creator {
        isMe
      }
      threshold_lower
      threshold_caution
      threshold_warning
      threshold_upper
      lastModified
      version
      inputIds
    }
  }
}
    `;
export const useExpressionAnomalyByIdQuery = <TData = ExpressionAnomalyByIdQuery, TError = unknown>(
  variables: ExpressionAnomalyByIdQueryVariables,
  options?: UseQueryOptions<ExpressionAnomalyByIdQuery, TError, TData>
) =>
  useQuery<ExpressionAnomalyByIdQuery, TError, TData>(
    ["expressionAnomalyById", variables],
    useApiFetcher<ExpressionAnomalyByIdQuery, ExpressionAnomalyByIdQueryVariables>(ExpressionAnomalyByIdDocument).bind(
      null,
      variables
    ),
    options
  );

useExpressionAnomalyByIdQuery.getKey = (variables: ExpressionAnomalyByIdQueryVariables) => [
  "expressionAnomalyById",
  variables,
];
export const GetExpressionAnomaliesDocument = `
    query getExpressionAnomalies($customerId: ID!, $status: CalculatedStatus, $ids: [String]) {
  expressionAnomalies(customerId: $customerId) {
    expressionAnomalies(status: $status, ids: $ids) {
      definitionFieldId
      created
      creator {
        isMe
        name
      }
      customerId
      description
      expression {
        created
        customerId
        definition
        definitionFieldId
        id
        lastModified
        name
        readOnly
        status
        version
        inputIds
      }
      id
      lastModified
      name
      status
      threshold_caution
      threshold_lower
      threshold_upper
      threshold_warning
      version
      type
      inputIds
    }
  }
}
    `;
export const useGetExpressionAnomaliesQuery = <TData = GetExpressionAnomaliesQuery, TError = unknown>(
  variables: GetExpressionAnomaliesQueryVariables,
  options?: UseQueryOptions<GetExpressionAnomaliesQuery, TError, TData>
) =>
  useQuery<GetExpressionAnomaliesQuery, TError, TData>(
    ["getExpressionAnomalies", variables],
    useApiFetcher<GetExpressionAnomaliesQuery, GetExpressionAnomaliesQueryVariables>(
      GetExpressionAnomaliesDocument
    ).bind(null, variables),
    options
  );

useGetExpressionAnomaliesQuery.getKey = (variables: GetExpressionAnomaliesQueryVariables) => [
  "getExpressionAnomalies",
  variables,
];
export const ExpressionAnomaliesForLibrarySignalSelectorDocument = `
    query expressionAnomaliesForLibrarySignalSelector($customerId: ID!, $status: CalculatedStatus) {
  expressionAnomalies(customerId: $customerId) {
    expressionAnomalies(status: $status) {
      id
      name
      status
      threshold_caution
      threshold_warning
      threshold_lower
      threshold_upper
    }
  }
}
    `;
export const useExpressionAnomaliesForLibrarySignalSelectorQuery = <
  TData = ExpressionAnomaliesForLibrarySignalSelectorQuery,
  TError = unknown
>(
  variables: ExpressionAnomaliesForLibrarySignalSelectorQueryVariables,
  options?: UseQueryOptions<ExpressionAnomaliesForLibrarySignalSelectorQuery, TError, TData>
) =>
  useQuery<ExpressionAnomaliesForLibrarySignalSelectorQuery, TError, TData>(
    ["expressionAnomaliesForLibrarySignalSelector", variables],
    useApiFetcher<
      ExpressionAnomaliesForLibrarySignalSelectorQuery,
      ExpressionAnomaliesForLibrarySignalSelectorQueryVariables
    >(ExpressionAnomaliesForLibrarySignalSelectorDocument).bind(null, variables),
    options
  );

useExpressionAnomaliesForLibrarySignalSelectorQuery.getKey = (
  variables: ExpressionAnomaliesForLibrarySignalSelectorQueryVariables
) => ["expressionAnomaliesForLibrarySignalSelector", variables];
export const ListClustersDocument = `
    query listClusters($filter: FilterInput!, $customerId: ID!, $pagination: Pagination, $sorting: SortFilter) {
  clusters(customerId: $customerId) {
    clusters(pagination: $pagination, sorting: $sorting, filter: $filter) {
      data {
        id
        customerId
        name
        description
        created
        creator {
          name
          isMe
        }
        version
        vehicleCount
        combinationCount
      }
      pagination {
        currentPage
        pageSize
        totalCount
        totalPages
      }
    }
  }
}
    `;
export const useListClustersQuery = <TData = ListClustersQuery, TError = unknown>(
  variables: ListClustersQueryVariables,
  options?: UseQueryOptions<ListClustersQuery, TError, TData>
) =>
  useQuery<ListClustersQuery, TError, TData>(
    ["listClusters", variables],
    useApiFetcher<ListClustersQuery, ListClustersQueryVariables>(ListClustersDocument).bind(null, variables),
    options
  );

useListClustersQuery.getKey = (variables: ListClustersQueryVariables) => ["listClusters", variables];
export const ClusterNameDocument = `
    query clusterName($customerId: ID!, $id: ID!, $filter: FilterInput) {
  clusters(customerId: $customerId) {
    clusters(ids: [$id], filter: $filter) {
      data {
        name
      }
    }
  }
}
    `;
export const useClusterNameQuery = <TData = ClusterNameQuery, TError = unknown>(
  variables: ClusterNameQueryVariables,
  options?: UseQueryOptions<ClusterNameQuery, TError, TData>
) =>
  useQuery<ClusterNameQuery, TError, TData>(
    ["clusterName", variables],
    useApiFetcher<ClusterNameQuery, ClusterNameQueryVariables>(ClusterNameDocument).bind(null, variables),
    options
  );

useClusterNameQuery.getKey = (variables: ClusterNameQueryVariables) => ["clusterName", variables];
export const ClusterNonVehicleDetailsDocument = `
    query clusterNonVehicleDetails($customerId: ID!, $id: ID!) {
  clusters(customerId: $customerId) {
    clusterNonVehicleDetails(id: $id) {
      id
      customerId
      name
      description
      created
      creator {
        name
        isMe
      }
      version
    }
  }
}
    `;
export const useClusterNonVehicleDetailsQuery = <TData = ClusterNonVehicleDetailsQuery, TError = unknown>(
  variables: ClusterNonVehicleDetailsQueryVariables,
  options?: UseQueryOptions<ClusterNonVehicleDetailsQuery, TError, TData>
) =>
  useQuery<ClusterNonVehicleDetailsQuery, TError, TData>(
    ["clusterNonVehicleDetails", variables],
    useApiFetcher<ClusterNonVehicleDetailsQuery, ClusterNonVehicleDetailsQueryVariables>(
      ClusterNonVehicleDetailsDocument
    ).bind(null, variables),
    options
  );

useClusterNonVehicleDetailsQuery.getKey = (variables: ClusterNonVehicleDetailsQueryVariables) => [
  "clusterNonVehicleDetails",
  variables,
];
export const ClusterDetailsDocument = `
    query clusterDetails($customerId: ID!, $id: ID!, $filter: FilterInput) {
  clusters(customerId: $customerId) {
    clusters(ids: [$id], filter: $filter) {
      data {
        id
        customerId
        name
        description
        created
        creator {
          name
          isMe
        }
        pvins
        missingPvins
        version
        vehicleCount
        combinationCount
      }
    }
  }
}
    `;
export const useClusterDetailsQuery = <TData = ClusterDetailsQuery, TError = unknown>(
  variables: ClusterDetailsQueryVariables,
  options?: UseQueryOptions<ClusterDetailsQuery, TError, TData>
) =>
  useQuery<ClusterDetailsQuery, TError, TData>(
    ["clusterDetails", variables],
    useApiFetcher<ClusterDetailsQuery, ClusterDetailsQueryVariables>(ClusterDetailsDocument).bind(null, variables),
    options
  );

useClusterDetailsQuery.getKey = (variables: ClusterDetailsQueryVariables) => ["clusterDetails", variables];
export const CreateClusterDocument = `
    mutation createCluster($customerId: ID!, $input: ClusterCreateInput!) {
  clusters(customerId: $customerId) {
    createCluster(input: $input) {
      cluster {
        id
        name
      }
      missingPvins
      repeatedCombinations {
        pvin
        startDate
        endDate
      }
    }
  }
}
    `;
export const useCreateClusterMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<CreateClusterMutation, TError, CreateClusterMutationVariables, TContext>
) =>
  useMutation<CreateClusterMutation, TError, CreateClusterMutationVariables, TContext>(
    ["createCluster"],
    useApiFetcher<CreateClusterMutation, CreateClusterMutationVariables>(CreateClusterDocument),
    options
  );
export const DeleteClusterDocument = `
    mutation deleteCluster($customerId: ID!, $clusterId: ID!) {
  clusters(customerId: $customerId) {
    deleteCluster(clusterId: $clusterId)
  }
}
    `;
export const useDeleteClusterMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<DeleteClusterMutation, TError, DeleteClusterMutationVariables, TContext>
) =>
  useMutation<DeleteClusterMutation, TError, DeleteClusterMutationVariables, TContext>(
    ["deleteCluster"],
    useApiFetcher<DeleteClusterMutation, DeleteClusterMutationVariables>(DeleteClusterDocument),
    options
  );
export const UpdateClusterDocument = `
    mutation updateCluster($customerId: ID!, $clusterId: ID!, $version: Int!, $input: [ClusterRedshiftCreateInput!]!, $action: ClusterUpdateAction!, $ignoreDateRanges: Boolean, $filter: FilterInput) {
  clusters(customerId: $customerId) {
    updateClusterPvins(
      clusterId: $clusterId
      version: $version
      input: $input
      action: $action
      ignoreDateRanges: $ignoreDateRanges
      filter: $filter
    ) {
      cluster {
        id
        customerId
        name
        description
        created
        creator {
          name
          isMe
        }
        pvins
        version
        vehicleCount
      }
      missingPvins
      repeatedCombinations {
        pvin
        startDate
        endDate
      }
    }
  }
}
    `;
export const useUpdateClusterMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdateClusterMutation, TError, UpdateClusterMutationVariables, TContext>
) =>
  useMutation<UpdateClusterMutation, TError, UpdateClusterMutationVariables, TContext>(
    ["updateCluster"],
    useApiFetcher<UpdateClusterMutation, UpdateClusterMutationVariables>(UpdateClusterDocument),
    options
  );
export const EditClusterDocument = `
    mutation editCluster($customerId: ID!, $clusterId: ID!, $version: Int!, $name: String!, $description: String) {
  clusters(customerId: $customerId) {
    editCluster(
      clusterId: $clusterId
      version: $version
      name: $name
      description: $description
    ) {
      id
      customerId
      name
      description
    }
  }
}
    `;
export const useEditClusterMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<EditClusterMutation, TError, EditClusterMutationVariables, TContext>
) =>
  useMutation<EditClusterMutation, TError, EditClusterMutationVariables, TContext>(
    ["editCluster"],
    useApiFetcher<EditClusterMutation, EditClusterMutationVariables>(EditClusterDocument),
    options
  );
export const CopyClusterDocument = `
    mutation copyCluster($clusterId: ID!, $name: String!, $description: String!, $customerId: ID!) {
  clusters(customerId: $customerId) {
    copyCluster(clusterId: $clusterId, name: $name, description: $description)
  }
}
    `;
export const useCopyClusterMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<CopyClusterMutation, TError, CopyClusterMutationVariables, TContext>
) =>
  useMutation<CopyClusterMutation, TError, CopyClusterMutationVariables, TContext>(
    ["copyCluster"],
    useApiFetcher<CopyClusterMutation, CopyClusterMutationVariables>(CopyClusterDocument),
    options
  );
export const CreateExpressionEventDocument = `
    mutation createExpressionEvent($expressionEvent: ExpressionEventInput!, $customerId: ID!) {
  expressionEvents(customerId: $customerId) {
    createExpressionEvent(expressionEvent: $expressionEvent) {
      created
      creator {
        name
      }
      customerId
      expression {
        id
        name
      }
      id
      name
    }
  }
}
    `;
export const useCreateExpressionEventMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<CreateExpressionEventMutation, TError, CreateExpressionEventMutationVariables, TContext>
) =>
  useMutation<CreateExpressionEventMutation, TError, CreateExpressionEventMutationVariables, TContext>(
    ["createExpressionEvent"],
    useApiFetcher<CreateExpressionEventMutation, CreateExpressionEventMutationVariables>(CreateExpressionEventDocument),
    options
  );
export const GetExpressionEventForFormDocument = `
    query getExpressionEventForForm($customerId: ID!, $expressionEventId: ID!) {
  expressionEvents(customerId: $customerId) {
    expressionEvent(id: $expressionEventId) {
      created
      creator {
        name
        isMe
      }
      customerId
      expression {
        id
        status
        creator {
          name
          isMe
        }
      }
      id
      lastModified
      name
      operator
      threshold
      version
    }
  }
}
    `;
export const useGetExpressionEventForFormQuery = <TData = GetExpressionEventForFormQuery, TError = unknown>(
  variables: GetExpressionEventForFormQueryVariables,
  options?: UseQueryOptions<GetExpressionEventForFormQuery, TError, TData>
) =>
  useQuery<GetExpressionEventForFormQuery, TError, TData>(
    ["getExpressionEventForForm", variables],
    useApiFetcher<GetExpressionEventForFormQuery, GetExpressionEventForFormQueryVariables>(
      GetExpressionEventForFormDocument
    ).bind(null, variables),
    options
  );

useGetExpressionEventForFormQuery.getKey = (variables: GetExpressionEventForFormQueryVariables) => [
  "getExpressionEventForForm",
  variables,
];
export const GetExpressionEventsDocument = `
    query getExpressionEvents($customerId: ID!) {
  expressionEvents(customerId: $customerId) {
    expressionEvents {
      created
      creator {
        name
      }
      customerId
      expression {
        name
        id
        status
      }
      id
      lastModified
      name
      version
    }
  }
}
    `;
export const useGetExpressionEventsQuery = <TData = GetExpressionEventsQuery, TError = unknown>(
  variables: GetExpressionEventsQueryVariables,
  options?: UseQueryOptions<GetExpressionEventsQuery, TError, TData>
) =>
  useQuery<GetExpressionEventsQuery, TError, TData>(
    ["getExpressionEvents", variables],
    useApiFetcher<GetExpressionEventsQuery, GetExpressionEventsQueryVariables>(GetExpressionEventsDocument).bind(
      null,
      variables
    ),
    options
  );

useGetExpressionEventsQuery.getKey = (variables: GetExpressionEventsQueryVariables) => [
  "getExpressionEvents",
  variables,
];
export const GetSavedExpressionsForEventFormDocument = `
    query getSavedExpressionsForEventForm($customerId: ID!, $status: CalculatedStatus) {
  savedExpressions(customerId: $customerId) {
    savedExpressions(status: $status) {
      items {
        id
        name
        status
        creator {
          name
          isMe
        }
      }
    }
  }
}
    `;
export const useGetSavedExpressionsForEventFormQuery = <TData = GetSavedExpressionsForEventFormQuery, TError = unknown>(
  variables: GetSavedExpressionsForEventFormQueryVariables,
  options?: UseQueryOptions<GetSavedExpressionsForEventFormQuery, TError, TData>
) =>
  useQuery<GetSavedExpressionsForEventFormQuery, TError, TData>(
    ["getSavedExpressionsForEventForm", variables],
    useApiFetcher<GetSavedExpressionsForEventFormQuery, GetSavedExpressionsForEventFormQueryVariables>(
      GetSavedExpressionsForEventFormDocument
    ).bind(null, variables),
    options
  );

useGetSavedExpressionsForEventFormQuery.getKey = (variables: GetSavedExpressionsForEventFormQueryVariables) => [
  "getSavedExpressionsForEventForm",
  variables,
];
export const UpdateExpressionEventDocument = `
    mutation updateExpressionEvent($customerId: ID!, $expressionEvent: ExpressionEventInput!, $updateExpressionEventId: ID!, $version: Int!) {
  expressionEvents(customerId: $customerId) {
    updateExpressionEvent(
      expressionEvent: $expressionEvent
      id: $updateExpressionEventId
      version: $version
    ) {
      version
    }
  }
}
    `;
export const useUpdateExpressionEventMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdateExpressionEventMutation, TError, UpdateExpressionEventMutationVariables, TContext>
) =>
  useMutation<UpdateExpressionEventMutation, TError, UpdateExpressionEventMutationVariables, TContext>(
    ["updateExpressionEvent"],
    useApiFetcher<UpdateExpressionEventMutation, UpdateExpressionEventMutationVariables>(UpdateExpressionEventDocument),
    options
  );
export const ExpressionEventsForRelatedEventTableDocument = `
    query expressionEventsForRelatedEventTable($customerId: ID!, $ids: [ID!]) {
  expressionEvents(customerId: $customerId) {
    expressionEvents(ids: $ids) {
      id
      name
    }
  }
}
    `;
export const useExpressionEventsForRelatedEventTableQuery = <
  TData = ExpressionEventsForRelatedEventTableQuery,
  TError = unknown
>(
  variables: ExpressionEventsForRelatedEventTableQueryVariables,
  options?: UseQueryOptions<ExpressionEventsForRelatedEventTableQuery, TError, TData>
) =>
  useQuery<ExpressionEventsForRelatedEventTableQuery, TError, TData>(
    ["expressionEventsForRelatedEventTable", variables],
    useApiFetcher<ExpressionEventsForRelatedEventTableQuery, ExpressionEventsForRelatedEventTableQueryVariables>(
      ExpressionEventsForRelatedEventTableDocument
    ).bind(null, variables),
    options
  );

useExpressionEventsForRelatedEventTableQuery.getKey = (
  variables: ExpressionEventsForRelatedEventTableQueryVariables
) => ["expressionEventsForRelatedEventTable", variables];
export const AppendVisitedEventDocument = `
    mutation appendVisitedEvent($customerId: ID!, $eventId: String!, $type: EventType!, $name: String!, $ecu: String) {
  appendVisitedEvent(
    customerId: $customerId
    eventId: $eventId
    type: $type
    name: $name
    ecu: $ecu
  ) {
    customerSettings {
      customerId
      recentEvents {
        timestamp
        eventId
        type
        name
        ecu
      }
    }
    version
  }
}
    `;
export const useAppendVisitedEventMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<AppendVisitedEventMutation, TError, AppendVisitedEventMutationVariables, TContext>
) =>
  useMutation<AppendVisitedEventMutation, TError, AppendVisitedEventMutationVariables, TContext>(
    ["appendVisitedEvent"],
    useApiFetcher<AppendVisitedEventMutation, AppendVisitedEventMutationVariables>(AppendVisitedEventDocument),
    options
  );
export const DeleteExpressionEventDocument = `
    mutation deleteExpressionEvent($customerId: ID!, $id: ID!, $version: Int!) {
  expressionEvents(customerId: $customerId) {
    deleteExpressionEvent(id: $id, version: $version)
  }
}
    `;
export const useDeleteExpressionEventMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<DeleteExpressionEventMutation, TError, DeleteExpressionEventMutationVariables, TContext>
) =>
  useMutation<DeleteExpressionEventMutation, TError, DeleteExpressionEventMutationVariables, TContext>(
    ["deleteExpressionEvent"],
    useApiFetcher<DeleteExpressionEventMutation, DeleteExpressionEventMutationVariables>(DeleteExpressionEventDocument),
    options
  );
export const RecentEventsDocument = `
    query recentEvents {
  userSettings {
    customerSettings {
      customerId
      recentEvents {
        timestamp
        eventId
        type
        name
        ecu
      }
    }
  }
}
    `;
export const useRecentEventsQuery = <TData = RecentEventsQuery, TError = unknown>(
  variables?: RecentEventsQueryVariables,
  options?: UseQueryOptions<RecentEventsQuery, TError, TData>
) =>
  useQuery<RecentEventsQuery, TError, TData>(
    variables === undefined ? ["recentEvents"] : ["recentEvents", variables],
    useApiFetcher<RecentEventsQuery, RecentEventsQueryVariables>(RecentEventsDocument).bind(null, variables),
    options
  );

useRecentEventsQuery.getKey = (variables?: RecentEventsQueryVariables) =>
  variables === undefined ? ["recentEvents"] : ["recentEvents", variables];
export const UserEventsDocument = `
    query userEvents($customerId: ID!, $pagination: Pagination, $sorting: UserEventsSortInput) {
  userEvents(customerId: $customerId, pagination: $pagination, sorting: $sorting) {
    expressionEvents {
      id
      customerId
      name
      created
      lastModified
      creatorId
      version
      status
      expression {
        name
        id
      }
    }
    pagination {
      currentPage
      pageSize
      totalCount
    }
  }
}
    `;
export const useUserEventsQuery = <TData = UserEventsQuery, TError = unknown>(
  variables: UserEventsQueryVariables,
  options?: UseQueryOptions<UserEventsQuery, TError, TData>
) =>
  useQuery<UserEventsQuery, TError, TData>(
    ["userEvents", variables],
    useApiFetcher<UserEventsQuery, UserEventsQueryVariables>(UserEventsDocument).bind(null, variables),
    options
  );

useUserEventsQuery.getKey = (variables: UserEventsQueryVariables) => ["userEvents", variables];
export const CreateForecastDocument = `
    mutation createForecast($customerId: ID!, $forecast: ForecastInput!) {
  forecasts(customerId: $customerId) {
    createForecast(forecast: $forecast) {
      created
      creator {
        name
      }
      discountUnit
      discounts {
        distribution {
          points {
            x
            y
          }
          y0
        }
        totalDiscount
      }
      id
      lastModified
      name
      productionForecast {
        created
        creator {
          name
        }
        customerId
        definition {
          forecast {
            country
            modelType
            modelYear
            unitCounts
          }
          monthCount
          startingMonth
        }
        id
        lastModified
        name
        version
      }
      repairConcepts {
        repairConcept {
          cost {
            densityUnit
            distribution {
              points {
                x
                y
              }
              y0
            }
            totalCost
          }
          created
          creator {
            name
          }
          id
          lastModified
          name
          repairEventType
          triggerExpressionEvent {
            created
            creator {
              name
            }
            customerId
            id
            lastModified
            name
            expression {
              status
            }
            version
          }
          version
        }
        threshold
      }
      version
    }
  }
}
    `;
export const useCreateForecastMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<CreateForecastMutation, TError, CreateForecastMutationVariables, TContext>
) =>
  useMutation<CreateForecastMutation, TError, CreateForecastMutationVariables, TContext>(
    ["createForecast"],
    useApiFetcher<CreateForecastMutation, CreateForecastMutationVariables>(CreateForecastDocument),
    options
  );
export const ForecastsDocument = `
    query forecasts($customerId: ID!, $ids: [ID!]) {
  forecasts(customerId: $customerId) {
    forecasts(ids: $ids) {
      id
      name
      productionForecast {
        id
        name
        definition {
          startingMonth
          monthCount
          forecast {
            country
            modelType
            modelYear
            unitCounts
          }
        }
      }
      repairConcepts {
        repairConcept {
          id
          name
          triggerExpressionEvent {
            id
            name
            expression {
              status
            }
          }
          repairEventType
          cost {
            totalCost
            distribution {
              y0
              points {
                x
                y
              }
            }
            densityUnit
          }
        }
        threshold
      }
      discounts {
        totalDiscount
        distribution {
          y0
          points {
            x
            y
          }
        }
      }
      discountUnit
      created
      lastModified
      creator {
        name
      }
      version
    }
  }
}
    `;
export const useForecastsQuery = <TData = ForecastsQuery, TError = unknown>(
  variables: ForecastsQueryVariables,
  options?: UseQueryOptions<ForecastsQuery, TError, TData>
) =>
  useQuery<ForecastsQuery, TError, TData>(
    ["forecasts", variables],
    useApiFetcher<ForecastsQuery, ForecastsQueryVariables>(ForecastsDocument).bind(null, variables),
    options
  );

useForecastsQuery.getKey = (variables: ForecastsQueryVariables) => ["forecasts", variables];
export const ProductionForecastsDocument = `
    query productionForecasts($customerId: ID!) {
  productionForecasts(customerId: $customerId) {
    productionForecasts {
      id
      customerId
      name
      created
      lastModified
      creator {
        name
      }
      version
    }
  }
}
    `;
export const useProductionForecastsQuery = <TData = ProductionForecastsQuery, TError = unknown>(
  variables: ProductionForecastsQueryVariables,
  options?: UseQueryOptions<ProductionForecastsQuery, TError, TData>
) =>
  useQuery<ProductionForecastsQuery, TError, TData>(
    ["productionForecasts", variables],
    useApiFetcher<ProductionForecastsQuery, ProductionForecastsQueryVariables>(ProductionForecastsDocument).bind(
      null,
      variables
    ),
    options
  );

useProductionForecastsQuery.getKey = (variables: ProductionForecastsQueryVariables) => [
  "productionForecasts",
  variables,
];
export const UpdateForecastDocument = `
    mutation updateForecast($customerId: ID!, $id: ID!, $forecast: ForecastInput!, $version: Int!) {
  forecasts(customerId: $customerId) {
    updateForecast(id: $id, forecast: $forecast, version: $version) {
      created
      creator {
        name
      }
      discountUnit
      discounts {
        distribution {
          points {
            x
            y
          }
          y0
        }
        totalDiscount
      }
      id
      lastModified
      name
      productionForecast {
        created
        creator {
          name
        }
        customerId
        definition {
          forecast {
            country
            modelType
            modelYear
            unitCounts
          }
          monthCount
          startingMonth
        }
        id
        lastModified
        name
        version
      }
      repairConcepts {
        repairConcept {
          cost {
            densityUnit
            distribution {
              points {
                x
                y
              }
              y0
            }
            totalCost
          }
          created
          creator {
            name
          }
          id
          lastModified
          name
          repairEventType
          version
        }
        threshold
      }
      version
    }
  }
}
    `;
export const useUpdateForecastMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdateForecastMutation, TError, UpdateForecastMutationVariables, TContext>
) =>
  useMutation<UpdateForecastMutation, TError, UpdateForecastMutationVariables, TContext>(
    ["updateForecast"],
    useApiFetcher<UpdateForecastMutation, UpdateForecastMutationVariables>(UpdateForecastDocument),
    options
  );
export const HomeDocument = `
    query home($recentDetailVehiclesPagination: Pagination) {
  userSettings {
    customerSettings {
      customerId
      recentDetailVehicles(pagination: $recentDetailVehiclesPagination) {
        items {
          timestamp
          vehicleId
        }
      }
    }
  }
}
    `;
export const useHomeQuery = <TData = HomeQuery, TError = unknown>(
  variables?: HomeQueryVariables,
  options?: UseQueryOptions<HomeQuery, TError, TData>
) =>
  useQuery<HomeQuery, TError, TData>(
    variables === undefined ? ["home"] : ["home", variables],
    useApiFetcher<HomeQuery, HomeQueryVariables>(HomeDocument).bind(null, variables),
    options
  );

useHomeQuery.getKey = (variables?: HomeQueryVariables) => (variables === undefined ? ["home"] : ["home", variables]);
export const CreateSavedExpressionDocument = `
    mutation createSavedExpression($customerId: ID!, $expression: SavedExpressionInput!) {
  savedExpressions(customerId: $customerId) {
    createSavedExpression(expression: $expression) {
      id
      name
    }
  }
}
    `;
export const useCreateSavedExpressionMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<CreateSavedExpressionMutation, TError, CreateSavedExpressionMutationVariables, TContext>
) =>
  useMutation<CreateSavedExpressionMutation, TError, CreateSavedExpressionMutationVariables, TContext>(
    ["createSavedExpression"],
    useApiFetcher<CreateSavedExpressionMutation, CreateSavedExpressionMutationVariables>(CreateSavedExpressionDocument),
    options
  );
export const DeleteSavedExpressionDocument = `
    mutation DeleteSavedExpression($customerId: ID!, $id: ID!, $version: Int!) {
  savedExpressions(customerId: $customerId) {
    deleteSavedExpression(id: $id, version: $version) {
      dependencies {
        type
        id
        name
        owner
      }
    }
  }
}
    `;
export const useDeleteSavedExpressionMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<DeleteSavedExpressionMutation, TError, DeleteSavedExpressionMutationVariables, TContext>
) =>
  useMutation<DeleteSavedExpressionMutation, TError, DeleteSavedExpressionMutationVariables, TContext>(
    ["DeleteSavedExpression"],
    useApiFetcher<DeleteSavedExpressionMutation, DeleteSavedExpressionMutationVariables>(DeleteSavedExpressionDocument),
    options
  );
export const GetSavedExpressionByIdDocument = `
    query GetSavedExpressionById($customerId: ID!, $expressionId: ID!) {
  savedExpressions(customerId: $customerId) {
    savedExpression(expressionId: $expressionId) {
      created
      creator {
        isMe
        name
      }
      customerId
      id
      definitionFieldId
      lastModified
      name
      description
      status
      version
      definition
      readOnly
    }
  }
}
    `;
export const useGetSavedExpressionByIdQuery = <TData = GetSavedExpressionByIdQuery, TError = unknown>(
  variables: GetSavedExpressionByIdQueryVariables,
  options?: UseQueryOptions<GetSavedExpressionByIdQuery, TError, TData>
) =>
  useQuery<GetSavedExpressionByIdQuery, TError, TData>(
    ["GetSavedExpressionById", variables],
    useApiFetcher<GetSavedExpressionByIdQuery, GetSavedExpressionByIdQueryVariables>(
      GetSavedExpressionByIdDocument
    ).bind(null, variables),
    options
  );

useGetSavedExpressionByIdQuery.getKey = (variables: GetSavedExpressionByIdQueryVariables) => [
  "GetSavedExpressionById",
  variables,
];
export const GetSavedExpressionsDocument = `
    query GetSavedExpressions($customerId: ID!, $status: CalculatedStatus, $pagination: Pagination, $sorting: SortFilter, $filters: [Filter]) {
  savedExpressions(customerId: $customerId) {
    savedExpressions(
      status: $status
      sorting: $sorting
      pagination: $pagination
      filters: $filters
    ) {
      items {
        created
        creator {
          name
          isMe
        }
        customerId
        id
        lastModified
        name
        description
        status
        version
        readOnly
        definitionFieldId
        definition
        inputIds
      }
      pagination {
        totalCount
        pageSize
        currentPage
      }
    }
  }
}
    `;
export const useGetSavedExpressionsQuery = <TData = GetSavedExpressionsQuery, TError = unknown>(
  variables: GetSavedExpressionsQueryVariables,
  options?: UseQueryOptions<GetSavedExpressionsQuery, TError, TData>
) =>
  useQuery<GetSavedExpressionsQuery, TError, TData>(
    ["GetSavedExpressions", variables],
    useApiFetcher<GetSavedExpressionsQuery, GetSavedExpressionsQueryVariables>(GetSavedExpressionsDocument).bind(
      null,
      variables
    ),
    options
  );

useGetSavedExpressionsQuery.getKey = (variables: GetSavedExpressionsQueryVariables) => [
  "GetSavedExpressions",
  variables,
];
export const UpdateSavedExpressionDocument = `
    mutation UpdateSavedExpression($customerId: ID!, $id: ID!, $expression: SavedExpressionInput!, $version: Int!) {
  savedExpressions(customerId: $customerId) {
    updateSavedExpression(id: $id, expression: $expression, version: $version) {
      id
      name
    }
  }
}
    `;
export const useUpdateSavedExpressionMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdateSavedExpressionMutation, TError, UpdateSavedExpressionMutationVariables, TContext>
) =>
  useMutation<UpdateSavedExpressionMutation, TError, UpdateSavedExpressionMutationVariables, TContext>(
    ["UpdateSavedExpression"],
    useApiFetcher<UpdateSavedExpressionMutation, UpdateSavedExpressionMutationVariables>(UpdateSavedExpressionDocument),
    options
  );
export const SavedExpressionsDocument = `
    query savedExpressions($customerId: ID!, $status: CalculatedStatus, $ids: [ID!], $pagination: Pagination, $sorting: SortFilter) {
  savedExpressions(customerId: $customerId) {
    savedExpressions(
      status: $status
      ids: $ids
      sorting: $sorting
      pagination: $pagination
    ) {
      items {
        id
        customerId
        name
        status
        created
        lastModified
        creator {
          name
        }
        version
        definition
        inputIds
      }
      pagination {
        totalCount
        pageSize
        currentPage
      }
    }
  }
}
    `;
export const useSavedExpressionsQuery = <TData = SavedExpressionsQuery, TError = unknown>(
  variables: SavedExpressionsQueryVariables,
  options?: UseQueryOptions<SavedExpressionsQuery, TError, TData>
) =>
  useQuery<SavedExpressionsQuery, TError, TData>(
    ["savedExpressions", variables],
    useApiFetcher<SavedExpressionsQuery, SavedExpressionsQueryVariables>(SavedExpressionsDocument).bind(
      null,
      variables
    ),
    options
  );

useSavedExpressionsQuery.getKey = (variables: SavedExpressionsQueryVariables) => ["savedExpressions", variables];
export const CreateRepairConceptDocument = `
    mutation createRepairConcept($customerId: ID!, $repairConcept: RepairConceptInput!) {
  repairConcepts(customerId: $customerId) {
    createRepairConcept(repairConcept: $repairConcept) {
      id
    }
  }
}
    `;
export const useCreateRepairConceptMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<CreateRepairConceptMutation, TError, CreateRepairConceptMutationVariables, TContext>
) =>
  useMutation<CreateRepairConceptMutation, TError, CreateRepairConceptMutationVariables, TContext>(
    ["createRepairConcept"],
    useApiFetcher<CreateRepairConceptMutation, CreateRepairConceptMutationVariables>(CreateRepairConceptDocument),
    options
  );
export const RepairConceptsDocument = `
    query repairConcepts($customerId: ID!, $ids: [ID!]) {
  repairConcepts(customerId: $customerId) {
    repairConcepts(ids: $ids) {
      id
      name
      triggerExpressionEvent {
        id
        name
        expression {
          status
        }
      }
      repairEventType
      cost {
        totalCost
        distribution {
          y0
          points {
            x
            y
          }
        }
        densityUnit
      }
      created
      lastModified
      creator {
        name
      }
      version
    }
  }
}
    `;
export const useRepairConceptsQuery = <TData = RepairConceptsQuery, TError = unknown>(
  variables: RepairConceptsQueryVariables,
  options?: UseQueryOptions<RepairConceptsQuery, TError, TData>
) =>
  useQuery<RepairConceptsQuery, TError, TData>(
    ["repairConcepts", variables],
    useApiFetcher<RepairConceptsQuery, RepairConceptsQueryVariables>(RepairConceptsDocument).bind(null, variables),
    options
  );

useRepairConceptsQuery.getKey = (variables: RepairConceptsQueryVariables) => ["repairConcepts", variables];
export const UpdateRepairConceptDocument = `
    mutation updateRepairConcept($customerId: ID!, $id: ID!, $repairConcept: RepairConceptInput!, $version: Int!) {
  repairConcepts(customerId: $customerId) {
    updateRepairConcept(id: $id, repairConcept: $repairConcept, version: $version) {
      id
    }
  }
}
    `;
export const useUpdateRepairConceptMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdateRepairConceptMutation, TError, UpdateRepairConceptMutationVariables, TContext>
) =>
  useMutation<UpdateRepairConceptMutation, TError, UpdateRepairConceptMutationVariables, TContext>(
    ["updateRepairConcept"],
    useApiFetcher<UpdateRepairConceptMutation, UpdateRepairConceptMutationVariables>(UpdateRepairConceptDocument),
    options
  );
export const AppendVisitedVehicleDocument = `
    mutation appendVisitedVehicle($customerId: ID!, $vehicleId: ID!) {
  appendVisitedVehicle(customerId: $customerId, vehicleId: $vehicleId) {
    customerSettings {
      customerId
      recentDetailVehicles {
        items {
          timestamp
          vehicleId
        }
        pagination {
          currentPage
          pageSize
        }
      }
    }
    version
  }
}
    `;
export const useAppendVisitedVehicleMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<AppendVisitedVehicleMutation, TError, AppendVisitedVehicleMutationVariables, TContext>
) =>
  useMutation<AppendVisitedVehicleMutation, TError, AppendVisitedVehicleMutationVariables, TContext>(
    ["appendVisitedVehicle"],
    useApiFetcher<AppendVisitedVehicleMutation, AppendVisitedVehicleMutationVariables>(AppendVisitedVehicleDocument),
    options
  );
export const AllClustersForSyncDocument = `
    query AllClustersForSync($customerId: ID!) {
  clusters(customerId: $customerId) {
    clusters {
      data {
        id
        name
        version
        creator {
          name
        }
        customerAttributes
      }
    }
  }
}
    `;
export const useAllClustersForSyncQuery = <TData = AllClustersForSyncQuery, TError = unknown>(
  variables: AllClustersForSyncQueryVariables,
  options?: UseQueryOptions<AllClustersForSyncQuery, TError, TData>
) =>
  useQuery<AllClustersForSyncQuery, TError, TData>(
    ["AllClustersForSync", variables],
    useApiFetcher<AllClustersForSyncQuery, AllClustersForSyncQueryVariables>(AllClustersForSyncDocument).bind(
      null,
      variables
    ),
    options
  );

useAllClustersForSyncQuery.getKey = (variables: AllClustersForSyncQueryVariables) => ["AllClustersForSync", variables];
export const CreateClusterForSyncDocument = `
    mutation CreateClusterForSync($customerId: ID!, $input: ClusterCreateInput!) {
  clusters(customerId: $customerId) {
    createCluster(input: $input) {
      cluster {
        id
        name
        version
        creator {
          name
        }
        customerAttributes
      }
    }
  }
}
    `;
export const useCreateClusterForSyncMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<CreateClusterForSyncMutation, TError, CreateClusterForSyncMutationVariables, TContext>
) =>
  useMutation<CreateClusterForSyncMutation, TError, CreateClusterForSyncMutationVariables, TContext>(
    ["CreateClusterForSync"],
    useApiFetcher<CreateClusterForSyncMutation, CreateClusterForSyncMutationVariables>(CreateClusterForSyncDocument),
    options
  );
export const UpdateClusterForSyncDocument = `
    mutation UpdateClusterForSync($customerId: ID!, $clusterId: ID!, $version: Int!, $input: [ClusterRedshiftCreateInput!]!, $action: ClusterUpdateAction!) {
  clusters(customerId: $customerId) {
    updateClusterPvins(
      clusterId: $clusterId
      version: $version
      input: $input
      action: $action
    ) {
      cluster {
        id
        name
        version
      }
    }
  }
}
    `;
export const useUpdateClusterForSyncMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdateClusterForSyncMutation, TError, UpdateClusterForSyncMutationVariables, TContext>
) =>
  useMutation<UpdateClusterForSyncMutation, TError, UpdateClusterForSyncMutationVariables, TContext>(
    ["UpdateClusterForSync"],
    useApiFetcher<UpdateClusterForSyncMutation, UpdateClusterForSyncMutationVariables>(UpdateClusterForSyncDocument),
    options
  );
export const ExpressionAnomalyDetectorsForTimeSeriesFieldIdDocument = `
    query ExpressionAnomalyDetectorsForTimeSeriesFieldId($customerId: ID!, $ids: [TimeSeriesFieldId!]!) {
  expressionAnomalies(customerId: $customerId) {
    expressionAnomalyDetectorsForTimeSeriesFieldId(ids: $ids) {
      id
      name
      nameKey
      description
      cautionThreshold
      warningThreshold
      created
      lastModified
      creator {
        name
        isMe
      }
      version
      isCustomerMLModel
      type
      timeSeriesFieldId
      inputIds
    }
  }
}
    `;
export const useExpressionAnomalyDetectorsForTimeSeriesFieldIdQuery = <
  TData = ExpressionAnomalyDetectorsForTimeSeriesFieldIdQuery,
  TError = unknown
>(
  variables: ExpressionAnomalyDetectorsForTimeSeriesFieldIdQueryVariables,
  options?: UseQueryOptions<ExpressionAnomalyDetectorsForTimeSeriesFieldIdQuery, TError, TData>
) =>
  useQuery<ExpressionAnomalyDetectorsForTimeSeriesFieldIdQuery, TError, TData>(
    ["ExpressionAnomalyDetectorsForTimeSeriesFieldId", variables],
    useApiFetcher<
      ExpressionAnomalyDetectorsForTimeSeriesFieldIdQuery,
      ExpressionAnomalyDetectorsForTimeSeriesFieldIdQueryVariables
    >(ExpressionAnomalyDetectorsForTimeSeriesFieldIdDocument).bind(null, variables),
    options
  );

useExpressionAnomalyDetectorsForTimeSeriesFieldIdQuery.getKey = (
  variables: ExpressionAnomalyDetectorsForTimeSeriesFieldIdQueryVariables
) => ["ExpressionAnomalyDetectorsForTimeSeriesFieldId", variables];
export const AllClustersWithNamesDocument = `
    query allClustersWithNames($customerId: ID!) {
  clusters(customerId: $customerId) {
    clusters {
      data {
        id
        name
      }
    }
  }
}
    `;
export const useAllClustersWithNamesQuery = <TData = AllClustersWithNamesQuery, TError = unknown>(
  variables: AllClustersWithNamesQueryVariables,
  options?: UseQueryOptions<AllClustersWithNamesQuery, TError, TData>
) =>
  useQuery<AllClustersWithNamesQuery, TError, TData>(
    ["allClustersWithNames", variables],
    useApiFetcher<AllClustersWithNamesQuery, AllClustersWithNamesQueryVariables>(AllClustersWithNamesDocument).bind(
      null,
      variables
    ),
    options
  );

useAllClustersWithNamesQuery.getKey = (variables: AllClustersWithNamesQueryVariables) => [
  "allClustersWithNames",
  variables,
];
export const TimeSeriesFieldsDocument = `
    query TimeSeriesFields($customerId: ID!) {
  timeSeriesFields(customerId: $customerId) {
    family
    id
    entityId
    name
    displayName
    type
    unit
    inputIds
    oneMinuteAggregationTypes
    aggregationTypes
  }
}
    `;
export const useTimeSeriesFieldsQuery = <TData = TimeSeriesFieldsQuery, TError = unknown>(
  variables: TimeSeriesFieldsQueryVariables,
  options?: UseQueryOptions<TimeSeriesFieldsQuery, TError, TData>
) =>
  useQuery<TimeSeriesFieldsQuery, TError, TData>(
    ["TimeSeriesFields", variables],
    useApiFetcher<TimeSeriesFieldsQuery, TimeSeriesFieldsQueryVariables>(TimeSeriesFieldsDocument).bind(
      null,
      variables
    ),
    options
  );

useTimeSeriesFieldsQuery.getKey = (variables: TimeSeriesFieldsQueryVariables) => ["TimeSeriesFields", variables];
export const ExpressionEventsDocument = `
    query ExpressionEvents($customerId: ID!, $ids: [ID!]) {
  expressionEvents(customerId: $customerId) {
    expressionEvents(ids: $ids) {
      id
      name
      expression {
        name
        status
        definitionFieldId
      }
      operator
      threshold
      definitionFieldId
      status
    }
  }
}
    `;
export const useExpressionEventsQuery = <TData = ExpressionEventsQuery, TError = unknown>(
  variables: ExpressionEventsQueryVariables,
  options?: UseQueryOptions<ExpressionEventsQuery, TError, TData>
) =>
  useQuery<ExpressionEventsQuery, TError, TData>(
    ["ExpressionEvents", variables],
    useApiFetcher<ExpressionEventsQuery, ExpressionEventsQueryVariables>(ExpressionEventsDocument).bind(
      null,
      variables
    ),
    options
  );

useExpressionEventsQuery.getKey = (variables: ExpressionEventsQueryVariables) => ["ExpressionEvents", variables];
export const GetExpressionEventDocument = `
    query GetExpressionEvent($customerId: ID!, $id: ID!) {
  expressionEvents(customerId: $customerId) {
    expressionEvent(id: $id) {
      id
      name
      expression {
        name
        status
        definitionFieldId
      }
      operator
      threshold
    }
  }
}
    `;
export const useGetExpressionEventQuery = <TData = GetExpressionEventQuery, TError = unknown>(
  variables: GetExpressionEventQueryVariables,
  options?: UseQueryOptions<GetExpressionEventQuery, TError, TData>
) =>
  useQuery<GetExpressionEventQuery, TError, TData>(
    ["GetExpressionEvent", variables],
    useApiFetcher<GetExpressionEventQuery, GetExpressionEventQueryVariables>(GetExpressionEventDocument).bind(
      null,
      variables
    ),
    options
  );

useGetExpressionEventQuery.getKey = (variables: GetExpressionEventQueryVariables) => ["GetExpressionEvent", variables];
export const TimeSeriesForPatternValidationAndLookupDocument = `
    query timeSeriesForPatternValidationAndLookup($customerId: ID!) {
  expressionAnomalies(customerId: $customerId) {
    expressionAnomalies {
      customerId
      threshold_upper
      threshold_lower
      definitionFieldId
      id
      inputIds
      expression {
        definition
      }
    }
  }
  savedExpressions(customerId: $customerId) {
    savedExpressions {
      items {
        customerId
        id
        definitionFieldId
        definition
      }
    }
  }
}
    `;
export const useTimeSeriesForPatternValidationAndLookupQuery = <
  TData = TimeSeriesForPatternValidationAndLookupQuery,
  TError = unknown
>(
  variables: TimeSeriesForPatternValidationAndLookupQueryVariables,
  options?: UseQueryOptions<TimeSeriesForPatternValidationAndLookupQuery, TError, TData>
) =>
  useQuery<TimeSeriesForPatternValidationAndLookupQuery, TError, TData>(
    ["timeSeriesForPatternValidationAndLookup", variables],
    useApiFetcher<TimeSeriesForPatternValidationAndLookupQuery, TimeSeriesForPatternValidationAndLookupQueryVariables>(
      TimeSeriesForPatternValidationAndLookupDocument
    ).bind(null, variables),
    options
  );

useTimeSeriesForPatternValidationAndLookupQuery.getKey = (
  variables: TimeSeriesForPatternValidationAndLookupQueryVariables
) => ["timeSeriesForPatternValidationAndLookup", variables];
