import styled from "styled-components";

export const PillHeaderStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
`;

export const PillsContainer = styled.div`
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > * {
    margin: 0 0.5rem;
  }
`;
