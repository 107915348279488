import { Expression } from "@lib/src/expression/types";
import _ from "lodash";
import { useContext } from "react";
import { PartialDeep } from "type-fest";

import { HistoryContext } from "@/contexts/HistoryProvider";

export const usePatternTreeBuilder = () => {
  const { addRevision, undo, redo, currentRevision, clearHistory } = useContext(HistoryContext);

  const editExpression = (newValue: PartialDeep<Expression>, path: string[]) => {
    if (path.length === 0) {
      addRevision(newValue);
      return;
    }
    addRevision((latestRevision: any) => {
      const newRootExpression = _.cloneDeep(latestRevision);
      _.set(newRootExpression, path, newValue);
      return newRootExpression;
    });
  };

  return {
    editExpression,
    rootExpression: (currentRevision as PartialDeep<Expression>) ?? {},
    undo,
    redo,
    clearHistory,
  };
};
