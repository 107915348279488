import {
  AggregationExpression,
  AggregationType,
  Expression,
  ExpressionType,
  TimeSeriesExpressionType,
  TimeSeriesFieldExpression,
} from "@lib/src/expression/types";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import { isEqual } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { PartialDeep } from "type-fest";

import BlockingLoading from "@/components/ui/BlockLoading";
import { PatternFocusContext } from "@/contexts/PatternFocusContext";

import { useTimeSeriesForTypeahead } from "./hooks/useTimeSeriesForTypeahead";
import { patternBuilderMap, PatternOperatorType } from "./patternBuilderMap";
import { StyledBorderlessTypeahead } from "./TypeaheadInput.styled";
import {
  filterOptions,
  formatAggregationNode,
  getFunctionOnlyOptions,
  getNodeFromFunctionIntent,
  getNodeFromOperationIntent,
  getNullNodeFromSearch,
  getNumberNodeFromSearch,
  getPatternInputOptions,
} from "./TypeaheadUtils";

const defaultPlaceholder = "Type your formula";

type TypeaheadInputProps = {
  autoFocus?: boolean;
  functionalOnly?: boolean;
  onChange: (newValue: PartialDeep<Expression>, path: string[]) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  path: string[];
  placeholder?: string;
  value?: string | number | null;
  disabled?: boolean;
  status?: "error" | "warning";
};

const TypeaheadInput = ({
  onChange,
  path,
  placeholder = defaultPlaceholder,
  value,
  functionalOnly,
  onKeyDown,
  status,
}: TypeaheadInputProps) => {
  const inputRef = useRef<any>();
  const { isLoading, availableSignals, availablePatterns, availableAnomalies } = useTimeSeriesForTypeahead();
  const { mostRecentPath, setMostRecentPath, setFocusRootNode, focusRootNode } = useContext(PatternFocusContext);
  const availableOptions = functionalOnly
    ? getFunctionOnlyOptions()
    : getPatternInputOptions(availableSignals, availablePatterns, availableAnomalies);
  const [options, setOptions] = useState(availableOptions);

  useEffect(() => {
    if (!isLoading) {
      setOptions(availableOptions);
    }
  }, [isLoading]);

  useEffect(() => {
    if (inputRef.current && isEqual(mostRecentPath, path)) {
      inputRef.current.focus();
    }
  }, [mostRecentPath, path]);

  useEffect(() => {
    if (path.length === 0 && focusRootNode) {
      inputRef.current.focus();
      setFocusRootNode(false);
    }
  }, [focusRootNode]);

  const onSearch = (searchTerm: string) => {
    // Handles function intent
    const functionIntentNode = getNodeFromFunctionIntent(availableOptions, searchTerm);
    if (functionIntentNode) {
      return onChange(functionIntentNode, path);
    }

    // Handles operation intent
    const operationIntentNode = getNodeFromOperationIntent(availableOptions, searchTerm);
    if (operationIntentNode) {
      return onChange(operationIntentNode, path);
    }

    // Filters options
    const filteredOptions = filterOptions(availableOptions, searchTerm);
    setOptions(filteredOptions);
  };

  const handleSelect = (optionSelected: DefaultOptionType | BaseOptionType) => {
    const isAggregationType = optionSelected.value in AggregationType;
    if (optionSelected.value in ExpressionType || isAggregationType) {
      const newNodeType = isAggregationType ? "Aggregation" : (optionSelected.value as PatternOperatorType);
      const newNodeValue = patternBuilderMap[newNodeType];
      const formattedNodeValue =
        newNodeType === "Aggregation"
          ? formatAggregationNode(newNodeValue as PartialDeep<AggregationExpression>, optionSelected.aggregation)
          : newNodeValue;
      onChange(formattedNodeValue, path);
    } else if (optionSelected.entity in TimeSeriesExpressionType) {
      // Entity Selection
      const newNodeValue = patternBuilderMap[
        optionSelected.entity as TimeSeriesExpressionType
      ] as TimeSeriesFieldExpression;
      onChange({ ...newNodeValue, value: optionSelected.value }, path);
    }
  };

  const handleBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => {
    const numberIntentNode = getNumberNodeFromSearch(value);
    if (numberIntentNode) {
      return onChange(numberIntentNode, path);
    }

    const nullIntentNode = getNullNodeFromSearch(value);
    if (nullIntentNode) {
      return onChange(nullIntentNode, path);
    }

    // Inputs are invalid unless they are the only one.
    // So we render them as invalid, unless
    if (isEqual(mostRecentPath, path)) {
      setMostRecentPath(undefined);
    }
  };

  if (isLoading) return <BlockingLoading className="line" />;

  return (
    <StyledBorderlessTypeahead
      popupClassName="pattern-editor-popup"
      ref={inputRef}
      options={options}
      placeholder={placeholder}
      defaultValue={value ? value.toString() : ""}
      onSearch={onSearch}
      onSelect={(_valueSelected, optionSelected) => handleSelect(optionSelected)}
      onBlur={handleBlur}
      optionRender={({ label }) => <span className="body-small typeahead-option-label">{label}</span>}
      dropdownStyle={{ width: "350px" }}
      onKeyDown={onKeyDown}
      onFocus={(e) => {
        setMostRecentPath(path);
        e.stopPropagation();
      }}
      status={status}
      autoFocus={path.length === 0}
    />
  );
};

export default TypeaheadInput;
