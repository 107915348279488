import { Pagination } from "../generated/graphql";

export const DEFAULT_PAGE_SIZE = 10;

export const DEFAULT_CURRENT_PAGE = 1;

export const DEFAULT_PAGINATION: Pagination = {
  currentPage: DEFAULT_CURRENT_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
} as const;
