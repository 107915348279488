import {
  AggregationExpression,
  BinaryExpression,
  BooleanExpression,
  CaseExpression,
  Expression,
  ExpressionType,
  IfExpression,
  LiteralNumericExpression,
  TimeSeriesFieldExpression,
} from "@lib/src/expression/types";
import { KeyboardEvent } from "react";
import { PartialDeep } from "type-fest";

import TypeaheadInput from "../TypeaheadInput";
import { getBinrayOperatorSymbol } from "../TypeaheadUtils";
import { PatternAggregationNode } from "./PatternAggregationNode";
import PatternBinaryNode from "./PatternBinaryNode";
import { PatternCaseNode } from "./PatternCaseNode";
import PatternEntityNode from "./PatternEntityNode";
import PatternFunctionNode from "./PatternFunctionNode";
import PatternIfNode from "./PatternIfNode";
import PatternLiteralNode from "./PatternLiteralNode";
import PatternNullNode from "./PatternNullNode";

type PatternNodeProps = {
  autoFocus?: boolean;
  node: PartialDeep<Expression>;
  onNodeChange: (newValue: PartialDeep<Expression>, path: string[]) => void;
  path: string[];
  deleteFunction?: (e: KeyboardEvent<HTMLDivElement>) => void;
  disabled: boolean;
  status?: "warning" | "error";
};

// TODO: better typing so there is no need to alias
const PatternNode = ({ onNodeChange, node, path, deleteFunction, autoFocus, disabled, status }: PatternNodeProps) => {
  const isBinaryOperation = !!getBinrayOperatorSymbol(node.type);
  if (node.type === ExpressionType.LiteralNumeric) {
    return (
      <PatternLiteralNode
        node={node as LiteralNumericExpression}
        path={path}
        onChange={onNodeChange}
        disabled={disabled}
      />
    );
  }
  if (node.type === ExpressionType.Null) {
    return <PatternNullNode node={node} path={path} onChange={onNodeChange} disabled={disabled} />;
  }
  if (isBinaryOperation) {
    return (
      <PatternBinaryNode node={node as BinaryExpression} path={path} onChange={onNodeChange} disabled={disabled} />
    );
  } else if (node.type === ExpressionType.If) {
    return <PatternIfNode node={node as IfExpression} path={path} onChange={onNodeChange} disabled={disabled} />;
  } else if (
    node.type === ExpressionType.Abs ||
    node.type === ExpressionType.IsNull ||
    node.type === ExpressionType.IsNotNull ||
    node.type === ExpressionType.Not
  ) {
    return (
      <PatternFunctionNode node={node as BooleanExpression} onChange={onNodeChange} path={path} disabled={disabled} />
    );
  } else if (node.type === ExpressionType.Case) {
    return <PatternCaseNode node={node as CaseExpression} path={path} onChange={onNodeChange} disabled={disabled} />;
  } else if (node.type === ExpressionType.Aggregation) {
    return (
      <PatternAggregationNode
        onChange={onNodeChange}
        node={node as AggregationExpression}
        path={path}
        disabled={disabled}
      />
    );
  } else if (
    node.type === ExpressionType.Signal ||
    node.type === ExpressionType.Pattern ||
    node.type === ExpressionType.AnomalyScore
  ) {
    return (
      <PatternEntityNode
        node={node as TimeSeriesFieldExpression}
        onChange={onNodeChange}
        path={path}
        disabled={disabled}
      />
    );
  } else {
    return (
      <TypeaheadInput
        onChange={onNodeChange}
        path={path}
        onKeyDown={deleteFunction}
        autoFocus={autoFocus}
        status={status}
      />
    );
  }
};

export default PatternNode;
