import { validateExpression } from "@lib/src/expression/expressionValidator";
import { Expression } from "@lib/src/expression/types";
import { NotificationInstance } from "antd/lib/notification/interface";
import { Dispatch, SetStateAction } from "react";

import { AfterCreate } from "@/features/anomaly/editor/AfterCreate";
import { requireCurrentCustomerId } from "@/utils/customers";

import { CreatePatternMutation } from "./CreatePatternMutation";

export const createPattern = async (
  name: string,
  description: string | null | undefined,
  definition: Expression,
  createSavedExpressionMutation: CreatePatternMutation,
  notificationApi: NotificationInstance,
  setIsSaveAsModalVisible: Dispatch<SetStateAction<boolean>>,
  afterCreate?: AfterCreate
) => {
  try {
    validateExpression(definition);
    return new Promise<{ id: string; name: string }>((resolve, reject) => {
      createSavedExpressionMutation.mutate(
        {
          customerId: requireCurrentCustomerId(),
          expression: {
            name: name,
            description,
            definition,
          },
        },
        {
          onSuccess(data) {
            notificationApi.success({
              message: `Pattern ${data.savedExpressions!.createSavedExpression.name} created.`,
            });
            setIsSaveAsModalVisible(false);
            const newSavedExpression = data.savedExpressions?.createSavedExpression;
            if (newSavedExpression) {
              afterCreate?.(newSavedExpression);
              resolve(newSavedExpression);
            } else {
              reject(new Error(`Problem saving pattern`));
            }
          },
        }
      );
    });
  } catch (e) {
    notificationApi.error({ message: "Invalid pattern" });
  }
};
