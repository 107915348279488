import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";

import userLocale from "./locale";

dayjs.extend(isBetween);
dayjs.extend(customParseFormat);

const shortIntlDateTimeFormat = new Intl.DateTimeFormat(userLocale, { dateStyle: "short" });
const shortUtcDateTimeFormat = new Intl.DateTimeFormat(userLocale, { dateStyle: "short", timeZone: "UTC" });
const mediumIntlDateTimeFormat = new Intl.DateTimeFormat(userLocale, { dateStyle: "medium" });
const mediumUtcDateTimeFormat = new Intl.DateTimeFormat(userLocale, { dateStyle: "medium", timeZone: "UTC" });

type dateType = Date | string | number;

const numRegex = new RegExp("^-?(0|[1-9][0-9]*)$");

/**
 *
 * @param date a valid date
 * @param withTime whether to include the time
 * @param useUtc whether to use UTC or local time
 * @returns localized short dateStyle string
 */
export const parseDateToStr = (date: Date, withTime?: boolean, useUtc?: boolean): string => {
  const formattedDate = useUtc ? shortUtcDateTimeFormat.format(date) : shortIntlDateTimeFormat.format(date);
  return withTime ? `${formattedDate} ${dayjs(date).format("HH:mm")}` : formattedDate;
};

/**
 *
 * @param date a valid date
 * @param withTime whether to include the time
 * @param useUtc whether to use UTC or local time
 * @returns localized medium dateStyle string
 */
export const parseDateToMdStr = (date: Date, withTime?: boolean, useUtc?: boolean): string => {
  const formattedDate = useUtc ? mediumUtcDateTimeFormat.format(date) : mediumIntlDateTimeFormat.format(date);
  return withTime ? `${formattedDate} ${dayjs(date).format("HH:mm")}` : formattedDate;
};

/**
 *
 * @param date a valid string or number date
 * @returns Date object
 */
export const toDateObj = (date?: string | number): Date => {
  if (!date) {
    return new Date();
  }
  return new Date(date);
};

/**
 *
 * @param date a valid string or number date
 * @returns Date object
 * throws if invalid date
 */
export const toDateObjOrThrow = (date?: string | number, throwMessage?: string): Date => {
  if (!dayjs(date).isValid()) {
    throw new Error(throwMessage ?? "Invalid Date");
  }
  return dayjs(date).toDate();
};

/**
 *
 * @param date a valid string or number date
 * @returns Date object or undefined if invalid date
 */
export const toDateObjOrUndefined = (date?: string | number): Date | undefined => {
  if (!dayjs(date).isValid()) {
    return;
  }
  return dayjs(date).toDate();
};

/**
 *
 * @param date a valid string or number date
 * @param format a valid string date format
 * @returns Date object or undefined if invalid date
 */
export const toDateObjFromFormattedStringOrNumericDate = (date: string | number, format?: string): Date | undefined => {
  if (!format) {
    if (!dayjs(date).isValid()) return;
    return dayjs(date).toDate();
  }
  // if a format is passed assume that strict mode should be used
  if (!dayjs(date, format, true).isValid()) {
    return;
  }
  return dayjs(date, format).toDate();
};

/**
 *
 * @param date a valid string or number date
 * @param format a valid string date format
 * @returns String date formated or undefined if invalid date
 */
export const toDateStringFromFormattedString = (date: string | number, format: string): string | undefined => {
  if (!dayjs(date).isValid()) {
    return;
  }
  return dayjs(date).format(format);
};

/**
 *
 * @param date dateType
 * @param start dateType
 * @param end dateType
 * @returns isBetween boolean
 */
export const isDateBetween = (date: dateType, start: dateType, end: dateType): boolean => {
  return dayjs(date).isBetween(start, end, "day", "[]");
};

/**
 *
 * @param value dateType
 * @returns isNumericTimestamp boolean
 */
export const isNumericTimestamp = (value: string): boolean => {
  if (numRegex.test(value)) {
    return dayjs(+value).isValid();
  }
  return false;
};

/**
 *
 * @param date dateType
 * @param dayCount number
 * @returns Date object dayCount days ago
 */
export const subtractDaysToDate = (date: dateType, dayCount: number): Date => {
  if (dayCount < 0 || !Number.isInteger(dayCount)) throw "dayCount must be a positive integer";
  return dayjs(date).subtract(dayCount, "day").toDate();
};

/**
 *
 * @param from dateType
 * @param to dateType
 * @returns days between the two dates
 */
export const daysBetween = (from: dateType, to: dateType): number => {
  return Math.ceil(dayjs(to).diff(from, "day", true));
};

/**
 *
 * @param date dateType
 * @returns Date object at the start of date's day
 */
export const startOfDay = (date: dateType): Date => {
  return dayjs(date).startOf("day").toDate();
};

/**
 * @param dateStr
 * @param format
 * @returns Date object if exists or undefined
 */
export const parseToDateFromCustomString = (dateStr: string, format: string, allowAutoFormat: boolean) => {
  let result;
  // We try to parse the date using the format the user selected
  result = toDateObjFromFormattedStringOrNumericDate(dateStr, format);
  // If that does not work we try to auto parse
  if (!result && allowAutoFormat) {
    result = toDateObjOrUndefined(dateStr);
  }
  return result;
};
