import { EChartsReactProps } from "echarts-for-react";
import { MutableRefObject } from "react";

import { EventOccurrenceRate, EventType, FaultTimelineSeries } from "@/api/customerApi";
import { chartLibraryEventFaultTooltipFormatter } from "@/features/chartElementLibrary/ChartLibraryBuildUtilsTooltips";
import { formatNumber } from "@/utils/numberUtils";

export type PlottableEvent = {
  startDate: number;
  endDate: number;
  label: string;
  rate: number;
  vehicles: number;
  events: number;
  eventType: EventType;
};

/**
 * [timestamp, event label, PlottableEvent]
 * ["1685145600000", "PCEB0DA", PlottableEvent]
 */
export type EventSummarySeriesElement = [string, string, PlottableEvent];

const kmNumberFormat = new Intl.NumberFormat(undefined, { maximumFractionDigits: 0 });
const rateAxisFormat = new Intl.NumberFormat(undefined, { style: "percent", minimumSignificantDigits: 2 });
const rateNumberFormat = new Intl.NumberFormat(undefined, { style: "percent", minimumFractionDigits: 2 });

// TODO proper size and colors for rates
export const buildEventsSummaryChartConfig = (
  windowSize: number,
  data?: Pick<EventOccurrenceRate, "end" | "rate">[]
): EChartsReactProps => {
  return {
    // TODO get colors from theme provider
    option: {
      backgroundColor: "#f9fafe",
      textStyle: {
        color: "#333",
        fontFamily: "Inter",
      },
      grid: {
        left: "75",
        right: "75",
      },
      tooltip: {
        trigger: "axis",
        formatter: (params: any) => {
          return `<div class="tooltip">
            <div class="interval">${kmNumberFormat.format(params[0].axisValue - windowSize)} -
            ${kmNumberFormat.format(params[0].axisValue)} km</div>
            <hr>
            Rate: ${rateNumberFormat.format(params[0].data[1])}</div>`;
        },
      },
      xAxis: {
        type: "value",
        min: windowSize,
        splitNumber: 10,
        name: "km",
        nameLocation: "center",
        nameTextStyle: {
          fontWeight: "bold",
        },
        nameGap: 30,
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: (value: number) => rateAxisFormat.format(value),
        },
        name: "Rate",
        nameTextStyle: {
          fontWeight: "bold",
        },
      },
      series: [
        {
          type: "line",
          symbol: "none",
          data: data?.map((d) => [d.end, d.rate]),
        },
      ],
    },
  };
};

export type FaultTimeLineChartConfigProps = {
  yAxis:
    | {
        label: string;
        count: number;
      }[]
    | undefined;
  xAxis: number[] | undefined;
  rangeUnits: "kms" | "days";
  seriesData:
    | {
        xAxis: number;
        faultCount: number;
        pvinCount: number;
        label: string;
      }[]
    | undefined;
  axisLabelRef: MutableRefObject<string | undefined> | undefined;
};

export const buildFaultTimeLineChartConfig = ({
  seriesData,
  yAxis,
  xAxis,
  rangeUnits,
  axisLabelRef,
}: FaultTimeLineChartConfigProps): EChartsReactProps => {
  const normalize = createValueNormalizer(seriesData ? seriesData.map((data) => data.faultCount) : []);
  return {
    option: {
      grid: {
        left: 10,
        bottom: 10,
        right: 10,
        containLabel: true,
      },
      tooltip: {
        trigger: "item",
        formatter: (params: { data: { data: FaultTimelineSeries } }) => {
          if (axisLabelRef?.current) return axisLabelRef.current;
          return chartLibraryEventFaultTooltipFormatter(params, rangeUnits);
        },
      },
      axisPointer: {
        show: true,
        triggerTooltip: true,
        label: {
          formatter: (e: any) => {
            if (e.axisDimension === "y") {
              return e.value;
            }
          },
        },
      },
      xAxis: {
        type: "value",
        data: xAxis,
        boundaryGap: false,
        name: rangeUnits,
        nameGap: 20,
        nameLocation: "center",
        axisPointer: {
          label: {
            show: false,
          },
        },
        splitLine: {
          show: true,
        },
        axisLine: {
          show: true,
        },
        axisLabel: {
          formatter: (value: number) => {
            return rangeUnits === "kms" ? formatNumber(Math.floor(value / 100) * 100) : value;
          },
        },
      },
      yAxis: {
        type: "category",
        data: yAxis?.map((y) => {
          return y.label;
        }),
        triggerEvent: true,
        name: yAxis?.length ? "Fault Code + ECU + Trouble Code" : "",
        show: true,
        nameLocation: "end",
        nameTextStyle: {
          fontWeight: "bold",
          align: "right",
        },
        axisLine: {
          onZero: false,
        },
      },
      series: [
        {
          type: "scatter",
          symbol: "roundRect",
          data:
            seriesData?.map((d) => ({
              value: [d.xAxis, d.label],
              symbolSize: [18, 22],
              data: d,
            })) ?? [],
          itemStyle: {
            // Vary color over a linear range between rgba(0,31,255,0.2) (cold) and rgb(192,31,63,1.0) (hot) based on faultCount
            color: ({
              data: {
                data: { faultCount },
              },
            }: {
              data: { data: { faultCount: number } };
            }) =>
              `rgba(${normalize(faultCount) * 192}, 31, ${(1 - normalize(faultCount)) * 192 + 63}, ${
                normalize(faultCount) * 0.8 + 0.2
              })`,
            opacity: 0.8,
          },
          markLine: {
            silent: true,
            data: [{ name: "line", xAxis: 0 }],
            symbol: ["none", "none"],
            lineStyle: {
              color: "black",
              type: "solid",
              width: 1,
            },
            label: {
              show: false,
            },
            emphasis: {
              disabled: true,
            },
          },
        },
      ],
    },
  };
};

export interface SimpleDataChartProps {
  categoryLabel: string;
  valueLabel: string;
  bucketLabels: string[];
  data: number[];
  isLoading?: boolean;
}

const getSimpleChartTitle = (isLoading: boolean | undefined, chartHasData: boolean) => {
  if (!isLoading && !chartHasData) {
    return {
      title: {
        text: "No Data Available .",
        left: "center",
        top: "center",
        show: true,
      },
    };
  }
  return {};
};

export const buildBarChartConfig = ({
  categoryLabel,
  valueLabel,
  bucketLabels,
  data,
  isLoading,
}: SimpleDataChartProps): EChartsReactProps => {
  const chartHasData = !!bucketLabels.length || !!data.length;
  return {
    // TODO get colors from theme provider
    option: {
      backgroundColor: "#f9fafe",
      textStyle: {
        color: "#333",
        fontFamily: "Inter",
      },
      ...getSimpleChartTitle(isLoading, chartHasData),
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        show: false,
      },
      xAxis: {
        type: "category",
        name: categoryLabel,
        nameTextStyle: {
          color: "#000",
          fontSize: 14,
          verticalAlign: "top",
        },
        axisLine: {
          show: !!chartHasData,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#ddd",
          },
        },
        data: bucketLabels,
      },
      yAxis: {
        type: "value",
        splitLine: {
          lineStyle: {
            color: "#ddd",
          },
        },
        name: valueLabel,
        nameGap: 15,
      },
      series: [
        {
          type: "bar",
          barWidth: 35,
          itemStyle: {
            borderRadius: 4,
            color: "#3A404A",
          },
          emphasis: {
            itemStyle: {
              color: "#BD9A64",
            },
          },
          data,
          label: {
            show: true,
          },
        },
      ],
    },
  };
};

export const buildUpdateReceivedChartConfig = ({
  categoryLabel,
  valueLabel,
  bucketLabels,
  data,
  isLoading,
}: SimpleDataChartProps): EChartsReactProps => {
  const chartHasData = !!bucketLabels.length || !!data.length;

  return {
    // TODO get colors from theme provider
    option: {
      backgroundColor: "transparent",
      textStyle: {
        color: "#333",
        fontFamily: "Inter",
      },
      grid: {
        top: 10,
        bottom: 50,
        left: 80,
        right: 50,
      },
      ...getSimpleChartTitle(isLoading, chartHasData),
      tooltip: {
        show: false,
      },
      xAxis: {
        type: "value",
        name: valueLabel,
        nameLocation: "center",
        nameGap: 30,
        nameTextStyle: {
          color: "#5F5F5F",
          fontSize: 12,
          fontWeight: 600,
          fontFamily: "Inter",
        },
        axisLine: {
          show: !!chartHasData,
        },
        axisLabel: {
          formatter: "{value}",
        },
      },
      yAxis: {
        type: "category",
        axisLine: {
          show: false,
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: "#ddd",
          },
        },
        name: categoryLabel,
        nameGap: 65,
        data: bucketLabels,
        nameRotate: 90,
        axisTick: {
          show: false,
        },
        nameTextStyle: {
          color: "#5F5F5F",
          fontSize: 12,
          fontWeight: 600,
          fontFamily: "Inter",
        },
        nameLocation: "center",
      },
      series: [
        {
          type: "bar",
          barWidth: 8,
          itemStyle: {
            borderRadius: [0, 16, 16, 0],
            color: "#5F5F5F",
          },
          emphasis: {
            itemStyle: {
              color: "#F6C62F",
            },
          },
          data,
        },
      ],
    },
  };
};

/**
 *
 * @param values the original scatter chart values
 * @returns normalizer fn that returns a value between 0 and 1
 */
export const createValueNormalizer = (values: number[]): ((value: number) => number) => {
  if (!values.length) {
    return (_value: number) => 1;
  }

  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);

  return (value: number) => {
    if (minValue === maxValue) return 1;
    return Math.min(Math.max((value - minValue) / (maxValue - minValue), 0), 1);
  };
};
