import { Divider } from "antd";
import styled from "styled-components";

export const ClusterViewWrapper = styled.div`
  .ant-card-head-wrapper {
    width: 100%;
  }
`;

export const ClusterViewVehicleTable = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const VehicleHelperButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
`;

export const ClusterViewFaultTableHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  .ant-select {
    width: 180px;
    height: 100%;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.darkGray};
    .ant-select-selector {
      svg {
        fill: white;
      }
      border-radius: 0.75rem;
      height: 100%;
      .ant-select-selection-item {
        height: 95%;
        background-color: ${({ theme }) => theme.colors.accentBlue};
        color: white;
        border-radius: 4px;
      }
    }
  }
`;

export const ClusterViewGraphHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .attribute-select {
    width: 150px;
  }
`;

export const ClusterViewHeaderWrapper = styled.div`
  &.cluster-header-loading {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const ClusterViewTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header-event-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin-bottom: 0.25rem;
  }
  .cluster-analysis-link {
    color: ${({ theme }) => theme.colors.accentBlue};
  }
  text-transform: none;
`;

export const ClusterViewHeaderRight = styled.div`
  display: flex;
`;
export const DividerStyled = styled(Divider)`
  margin: auto 20px;
  height: 50px;
  border-width: 1px;
`;

export const FieldDiv = styled.div`
  padding: 0 1rem;
  border-left: 1px solid #dedede;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.25rem;
`;

export const TableTitle = styled.div`
  display: flex;
  align-items: center;
  .TableTitle-Title,
  .TableTitle-Info {
    margin-right: 10px;
  }
`;
