import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from "@lib/src/table/defaults";
import { useNavigate } from "@tanstack/react-router";
import { TableProps } from "antd";
import { ColumnType } from "antd/es/table";

import { Filter, Pagination, SortFilter, SortOrder } from "@/api";

import { BasicTable, BasicTableProps } from "./BasicTable";
import { TableStateSearchKey } from "./StatefulTable.types";

const columnsConfigMapper = <DataType,>(
  columns?: ColumnType<DataType>[],
  sorting?: SortFilter
): ColumnType<DataType>[] => {
  return (
    columns?.map((c) => {
      if (c.key === sorting?.field) return { ...c, sortOrder: sorting?.order };
      return c;
    }) ?? []
  );
};

export type StatefulTableProps<T> = BasicTableProps<T> & {
  searchKey: TableStateSearchKey;
  pagination?: Pagination;
  sorting?: SortFilter;
  filters?: Filter[];
};

const StatefulTable = <T extends object>(props: StatefulTableProps<T>) => {
  const searchNavigate = useNavigate();

  const currentPagination: Pagination = {
    currentPage: props?.pagination?.currentPage || DEFAULT_CURRENT_PAGE,
    pageSize: props?.pagination?.pageSize || DEFAULT_PAGE_SIZE,
  };

  const currentSorting = props?.sorting;

  const handleTableChange: TableProps<T>["onChange"] = (pagination, _filters, sorter) => {
    if (Array.isArray(sorter)) {
      console.warn("Unsupported usage of multiple sorting, ignoring it.");
      return;
    }

    const newSorting = sorter.columnKey
      ? {
          field: (sorter.columnKey ?? "").toString(),
          order: sorter.order as SortOrder,
        }
      : currentSorting;

    const newPagination: Pagination = {
      currentPage: pagination.current ?? currentPagination.currentPage,
      pageSize: pagination.pageSize ?? currentPagination.pageSize,
    };

    if (!props.searchKey) return;
    const serchKey = props.searchKey;

    searchNavigate({
      search: (prev) => ({
        ...prev,
        [serchKey]: {
          pagination: newPagination,
          sorting: newSorting,
          filters: [],
        },
      }),
    });
  };

  return (
    <BasicTable
      dataSource={props.dataSource}
      columns={columnsConfigMapper(props.columns, props.sorting)}
      sortDirections={["ascend", "descend", "ascend"]}
      rowKey={props.rowKey}
      onChange={handleTableChange}
      pagination={
        props.pagination
          ? {
              showSizeChanger: true,
              pageSizeOptions: [5, 10, 25, 50],
              current: props.pagination.currentPage,
              ...props.pagination,
            }
          : undefined
      }
      loading={props.loading}
    />
  );
};

export default StatefulTable;
