import { PillStyled } from "./Pill.styled";

type PillProps = {
  onClick: (value: string) => void;
  value: string;
  label: string;
  selected: boolean;
};

export const Pill = ({ label, value, selected, onClick }: PillProps) => {
  const className = selected ? "pill-selected" : "";
  return (
    <PillStyled className={className} onClick={() => onClick(value)}>
      {label}
    </PillStyled>
  );
};
