import styled from "styled-components";

export const SignalSelectorContainer = styled.div.attrs({ className: "library-signal-selector" })`
  max-height: 900px;
  padding: 1.5rem;
  .search-signal-input {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
  }
  .signal-list-container {
    overflow: auto;
  }
  .collapse-groups {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem 0;
    .ant-collapse-header-text {
      font-size: inherit;
    }
  }
`;
