import styled from "styled-components";

export const PillStyled = styled.div`
  background: ${({ theme }) => theme.colors.lightGray};
  opacity: 0.7;
  border-radius: 10px;
  padding: 0.2rem 10px;
  &.pill-selected {
    background: ${({ theme }) => theme.colors.hoverBlue};
    opacity: 1;
  }
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.hoverBlue};
    opacity: 1;
  }
`;
