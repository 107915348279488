import { isNotUndefined } from "@lib/src/isNotUndefined";
import { useRef } from "react";
import { useDispatch } from "react-redux";

import { TimeSeriesFieldFamily } from "@/api";
import { AggregateType, SingleVehicleAnomalyDetector } from "@/api/customerApi";
import { ChartLibraryActions } from "@/contexts/ChartLibrarySlice";
import { useTimeSeriesFields } from "@/hooks/useTimeSeriesFields";

import { AnomalyDetectorSection } from "../anomaly/singleCar/list/AnomalyDetectorSection";
import { AnomalyDetectorSectionContainer } from "../anomaly/singleCar/list/AnomalyDetectorSectionContainer";

interface VehicleDetailsAnomalySectionProps {
  pvin: string;
  className: string;
}

const VehicleDetailsAnomalySection = ({ pvin, className }: VehicleDetailsAnomalySectionProps) => {
  const dispatch = useDispatch();
  const { byFieldId, byId } = useTimeSeriesFields();
  const plottingFromSameDetectorRef = useRef(false);

  const handleAnomalySelected = (anomaly: SingleVehicleAnomalyDetector) => {
    plottingFromSameDetectorRef.current = false;

    const anomalyField = byId(anomaly.detector.id); // availablePlottableFields?.find((a) => a.id === anomaly.detector.id);

    if (anomalyField) {
      const fieldId = anomalyField.id ?? anomaly.detector.id;
      dispatch(
        ChartLibraryActions.toggleSignal({
          chart: "",
          comparison: [],
          fieldId,
          selected: true,
          yAxisGroup: "",
          anomalyThreshold: {
            caution: anomaly.detector.cautionThreshold,
            warning: anomaly.detector.warningThreshold,
          },
          fieldInput: {
            id: fieldId,
            aggregateType: AggregateType.Last,
            family: TimeSeriesFieldFamily.AnomalyScore,
          },
        })
      );
    }
    anomaly.detector.inputIds
      .map(byId)
      .filter(isNotUndefined)
      .forEach((field) => {
        dispatch(
          ChartLibraryActions.toggleSignal({
            chart: "",
            comparison: [],
            fieldId: field.id,
            selected: true,
            yAxisGroup: "",
            fieldInput: {
              id: field.id,
              aggregateType: field.aggregationTypes?.find((a) => a) ?? AggregateType.Last,
              family: field.family,
            },
          })
        );
      });
  };

  const handleFieldSelected = (fieldId: string) => {
    const plottableFieldMatch = byFieldId(fieldId);
    if (plottableFieldMatch) {
      if (plottingFromSameDetectorRef.current === false) {
        dispatch(ChartLibraryActions.resetLibrary());
        plottingFromSameDetectorRef.current = true;
      }
      dispatch(
        ChartLibraryActions.toggleSignal({
          chart: "",
          comparison: [],
          fieldId: plottableFieldMatch.id,
          selected: true,
          yAxisGroup: "",
          fieldInput: {
            id: plottableFieldMatch.id,
            aggregateType: plottableFieldMatch.aggregationTypes?.find((a) => a) ?? AggregateType.Last,
            family: plottableFieldMatch.family,
          },
        })
      );
    }
  };

  return (
    <AnomalyDetectorSectionContainer className={className}>
      <AnomalyDetectorSection
        pvin={pvin}
        onAnomalySelected={handleAnomalySelected}
        onFieldSelected={handleFieldSelected}
      />
    </AnomalyDetectorSectionContainer>
  );
};

export default VehicleDetailsAnomalySection;
