import { findCircularDependence } from "@lib/src/expression/circularity/findCircularDependence";
import { isExpressionValid } from "@lib/src/expression/isExpressionValid";
import { Expression, TimeSeriesExpressionType } from "@lib/src/expression/types";
import { useContext, useEffect, useMemo } from "react";
import { PartialDeep } from "type-fest";

import {
  TimeSeriesIndependentVar,
  useGetFilteredExpressionHistogramQuery,
  useGetFilteredExpressionTimeSeriesQuery,
} from "@/api/customerApi";
import Loading from "@/components/loading/Loading";
import { QUERY_SETTINGS } from "@/constants";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { IncompletePatternWarning } from "@/features/anomaly/IncompletePatternWarning";
import { useTimeSeriesFields } from "@/hooks/useTimeSeriesFields";
import { formatExpressionForCharts } from "@/utils/patterns";

import { PatternHistogramPreview } from "./PatternHistogramPreview";
import { PatternPreviewContainer } from "./PatternPreview.styled";
import { PatternTimeSeriesPreview } from "./PatternTimeSeriesPreview";

type PatternPreviewProps = {
  patternId: string | undefined;
  currentInputIds: string[];
  patternDefinition: PartialDeep<Expression>;
  onPatternChange?: (value: boolean) => void;
  patternValid?: boolean;
};

const defaultPercentile = 5;
const bucketNumber = 25;

export const PatternPreview = ({
  patternId,
  currentInputIds,
  patternDefinition,
  onPatternChange,
}: PatternPreviewProps) => {
  const { globalFilter } = useContext(GlobalFilterContext);
  const { all: timeSeriesFields, isLoading: isTimeSeriesLoading } = useTimeSeriesFields();

  const extractIdForChartGeneration = formatExpressionForCharts(patternDefinition, timeSeriesFields);

  const isExpressionValueEmpty =
    (extractIdForChartGeneration.type == TimeSeriesExpressionType.Signal ||
      extractIdForChartGeneration.type == TimeSeriesExpressionType.Pattern ||
      extractIdForChartGeneration.type == TimeSeriesExpressionType.AnomalyScore) &&
    extractIdForChartGeneration.value == "";

  const isExpressionValidMemoized = useMemo(() => {
    return (
      !findCircularDependence(timeSeriesFields, currentInputIds, patternId) &&
      isExpressionValid(patternDefinition as Partial<Expression>) &&
      !isTimeSeriesLoading &&
      !isExpressionValueEmpty
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSeriesFields, patternDefinition, patternId]);

  const { data: histogramData, isLoading: isLoadingHistogram } = useGetFilteredExpressionHistogramQuery(
    {
      filter: globalFilter,
      buckets: bucketNumber,
      outlierPercentile: defaultPercentile / 100,
      expression: extractIdForChartGeneration,
    },
    { enabled: isExpressionValidMemoized, staleTime: QUERY_SETTINGS.LONG_STALE_TIME }
  );

  const { data: timeSeriesData, isLoading: isLoadingTimeSeries } = useGetFilteredExpressionTimeSeriesQuery(
    {
      filter: globalFilter,
      independentVar: TimeSeriesIndependentVar.Mileage,
      expression: extractIdForChartGeneration,
    },
    { enabled: isExpressionValidMemoized, staleTime: QUERY_SETTINGS.LONG_STALE_TIME }
  );

  useEffect(() => {
    if (onPatternChange) {
      onPatternChange(isExpressionValidMemoized);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpressionValidMemoized]);

  if (isTimeSeriesLoading) {
    return <Loading height={256} />;
  }

  return isExpressionValidMemoized ? (
    <PatternPreviewContainer>
      <PatternHistogramPreview
        loading={isLoadingHistogram}
        data={
          histogramData?.filteredQuery.filteredSavedExpressionsHistogram
            ? {
                min: histogramData.filteredQuery.filteredSavedExpressionsHistogram.min,
                max: histogramData.filteredQuery.filteredSavedExpressionsHistogram.max,
                pct: histogramData.filteredQuery.filteredSavedExpressionsHistogram.pct,
                outliers: histogramData.filteredQuery.filteredSavedExpressionsHistogram.outliers,
              }
            : undefined
        }
      />
      <PatternTimeSeriesPreview
        loading={isLoadingTimeSeries}
        data={
          timeSeriesData?.filteredQuery.filteredSavedExpressionsTimeSeries
            ? {
                x: timeSeriesData.filteredQuery.filteredSavedExpressionsTimeSeries.x,
                y: timeSeriesData.filteredQuery.filteredSavedExpressionsTimeSeries.y[0],
                min: timeSeriesData.filteredQuery.filteredSavedExpressionsTimeSeries.low[0],
                max: timeSeriesData.filteredQuery.filteredSavedExpressionsTimeSeries.high[0],
                vehicleCount: timeSeriesData.filteredQuery.filteredSavedExpressionsTimeSeries.vehicleCount[0],
              }
            : undefined
        }
      />
    </PatternPreviewContainer>
  ) : (
    <IncompletePatternWarning />
  );
};
