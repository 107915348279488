import { ClusterRedshiftCreateInput } from "@/api";
import { parseToDateFromCustomString, toDateObjOrUndefined } from "@/utils/dateUtils";

const CLUSTER_SEPARATOR = /[ , \t ]+/;
export const inputToPvinsAndDateRanges = (
  text: string,
  vinLabel: string,
  format?: string
): { input: ClusterRedshiftCreateInput[]; errors: string[] } => {
  const rows = text.trim().split("\n");
  const errors: string[] = [];

  const parsedRows: (ClusterRedshiftCreateInput | undefined)[] = rows.map((row, index) => {
    const line = index + 1;
    const parsedRow = row.split(CLUSTER_SEPARATOR).filter(Boolean);
    if (parsedRow.length < 1) {
      errors.push(`${vinLabel} and Date Range required on line ${line}`);
      return undefined;
    }
    const [pvin, startDateString, endDateString] = parsedRow;
    const startDate = startDateString ? formatDateField(startDateString, format) : null;
    const endDate = endDateString ? formatDateField(endDateString, format) : null;

    return {
      pvin,
      ...(startDate && { startDate: startDate.toISOString() }),
      ...(endDate && { endDate: endDate.toISOString() }),
    };
  });
  return {
    input: parsedRows.filter<ClusterRedshiftCreateInput>(isValidClusterInput),
    errors,
  };
};

export const formatDateField = (dateString: string, format?: string): Date | undefined => {
  if (format) {
    return parseToDateFromCustomString(dateString, format, false);
  }
  return toDateObjOrUndefined(dateString);
};

function isValidClusterInput(v: ClusterRedshiftCreateInput | undefined): v is ClusterRedshiftCreateInput {
  return v !== undefined;
}
