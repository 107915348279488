// Load stylesheets
import "unfonts.css";
import "./styles/App.less";

import {
  createRouter,
  FullSearchSchema,
  ParseRoute,
  parseSearchWith,
  RouterProvider,
  stringifySearchWith,
} from "@tanstack/react-router";
import { parse, stringify } from "jsurl2";

import { ApiClientProvider } from "./contexts/ApiClientProvider";
import { AuthenticationProvider } from "./contexts/AuthenticationProvider";
// Import the generated route tree
import { routeTree } from "./routeTree.gen";

export type ValidAppRoutePath = ParseRoute<typeof routeTree>["fullPath"];
export type ValidAppSearch = FullSearchSchema<typeof routeTree>;
export type ValidAppSearchKey = keyof FullSearchSchema<typeof routeTree>;

// Create a new router instance
const router = createRouter({
  routeTree,
  parseSearch: parseSearchWith(parse),
  stringifySearch: stringifySearchWith(stringify),
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

function App() {
  return (
    <AuthenticationProvider>
      <ApiClientProvider>
        <RouterProvider router={router} />
      </ApiClientProvider>
    </AuthenticationProvider>
  );
}

export default App;
