import { AutoComplete, Button, Col, Row } from "antd";
import { useState } from "react";
import styled from "styled-components";

import Loading from "@/components/loading";

type TableFilterProps<T> = {
  label: string;
  isLoading: boolean;
  fieldName: T;
  options: { value: string | undefined; label: string }[];
  onApplyInclusions: (fieldName: T, values: (string | undefined)[]) => void;
  onSearchChange: (value: string) => void;
};

export const TableFilterInclusions = <T,>({
  label,
  isLoading,
  fieldName,
  options,
  onApplyInclusions,
  onSearchChange,
}: TableFilterProps<T>) => {
  const [localSelections, setLocalSelections] = useState<{ label: string; value: string | undefined }[]>([]);
  const [search, setSearch] = useState("");
  const [selectOpen, setSelectOpen] = useState(false);

  const handleSearchChange = (value: string) => {
    setSearch(value);
    if (value.trim()) {
      onSearchChange(value);
    }
    setSelectOpen(true);
  };

  const handleSelect = (selection: { value: string | undefined; label: string }) => {
    const newSelections = [...localSelections, selection];
    setLocalSelections(newSelections);
    setSearch("");
    setSelectOpen(false);
    onSearchChange("");
  };

  const handleClearAllBtnClick = () => {
    setLocalSelections([]);
  };

  const handleApplyBtnClick = () => {
    if (!localSelections.length) {
      onApplyInclusions(fieldName, []);
      return;
    }
    const selectedValues = localSelections.map((selection) => selection.value);
    onApplyInclusions(fieldName, selectedValues);
  };

  const getOptions = () => {
    const filteredOptions = search
      ? options
          .filter(
            (option) =>
              option.label.toLowerCase().includes(search.toLowerCase()) &&
              !localSelections.map((ls) => ls.value).includes(option.value)
          )
          .slice(0, 100)
      : [];
    return filteredOptions;
  };

  return (
    <VisualWrapper>
      <AutoComplete
        value={search}
        open={selectOpen}
        onFocus={() => setSelectOpen(true)}
        onBlur={() => setSelectOpen(false)}
        placeholder={`Type to Select ${label}s`}
        options={getOptions()}
        onChange={(value) => handleSearchChange(value)}
        onSelect={(_, selection) => handleSelect(selection)}
        notFoundContent={
          search === "" ? null : isLoading ? <Loading size="small" label="Searching..." /> : <div>No results found</div>
        }
      />
      <h3>Current Selections:</h3>
      <Row>
        {!localSelections.length && <span>All Values Selected</span>}
        {!!localSelections.length &&
          localSelections.map((selection) => (
            <Col
              key={selection.value}
              onClick={() => setLocalSelections([...localSelections.filter((s) => s.value !== selection.value)])}
            >
              {selection.label ?? "-"}
            </Col>
          ))}
      </Row>
      <ActionsButtonsWrapper>
        <ActionButton onClick={handleClearAllBtnClick} disabled={!localSelections.length}>
          Clear Selections
        </ActionButton>
        <ActionButton type="primary" onClick={handleApplyBtnClick}>
          Apply
        </ActionButton>
      </ActionsButtonsWrapper>
    </VisualWrapper>
  );
};

const VisualWrapper = styled.div`
  width: 400px;
  padding: 24px;
  .ant-select-single {
    width: 100%;
  }
  h3 {
    margin: 10px 0px;
  }
  .ant-row {
    max-width: 400px;
    max-height: 500px;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  .ant-col {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    padding: 5px;
    border-radius: 5px;
  }
  .ant-col:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.hoverRow};
  }
`;

const ActionsButtonsWrapper = styled.div`
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
`;

const ActionButton = styled(Button)``;
