import { TableProps } from "antd";
import { NotificationInstance } from "antd/es/notification/interface";
import { ColumnsType } from "antd/es/table";
import { Dispatch, SetStateAction } from "react";

import i18next from "@/i18n";

export const SELECTED_CELL_NAME = "drag-selected-cell";

export type InternalFunctions = {
  setSelection: Dispatch<
    SetStateAction<{
      columns: SelectionGrid;
      rows: SelectionGrid;
    }>
  >;
  selection: {
    columns: SelectionGrid;
    rows: SelectionGrid;
  };
};

export type SelectionGrid = {
  start: number | undefined;
  end: number | undefined;
};
/**
 * Get a users table selection will return the min and max value for both row and column.
 * If any of selection is undefined it will return -1 for each value.
 */

export const getSelectionMinAndMax = (selection: { columns: SelectionGrid; rows: SelectionGrid }) => {
  if (
    selection.rows.start === undefined ||
    selection.rows.end === undefined ||
    selection.columns.start === undefined ||
    selection.columns.end === undefined
  )
    return { minRow: -1, maxRow: -1, minCol: -1, maxCol: -1 };
  const minRow = Math.min(selection.rows.start, selection.rows.end);
  const maxRow = Math.max(selection.rows.start, selection.rows.end);
  const minCol = Math.min(selection.columns.start, selection.columns.end);
  const maxCol = Math.max(selection.columns.start, selection.columns.end);
  return { minRow, maxRow, minCol, maxCol };
};

/**
 * Adds logic to all the cells to support users dragging over the table to sellect the content in the cells.
 * Preserves any custom logic that would be added to the cells.
 */

export const addCellEventListener = <T extends object>(props: TableProps<T>, InternalFunctions: InternalFunctions) => {
  const { setSelection, selection } = InternalFunctions;
  const isCellInSelection = (cellRow: number, cellCol: number) => {
    const { minRow, maxRow, minCol, maxCol } = getSelectionMinAndMax(selection);
    return minRow <= cellRow && maxRow >= cellRow && minCol <= cellCol && maxCol >= cellCol;
  };
  return {
    ...props,
    columns: props.columns?.map((c, i) => ({
      ...c,
      onCell: (row: any, rowIndex: number | undefined) => {
        const propsOnCell = c.onCell?.(row, rowIndex);
        const className = isCellInSelection(rowIndex ?? 0, i) ? "drag-selected-cell" : "";
        return {
          ...propsOnCell,
          className: `${propsOnCell?.className ?? ""} ${className}`,
          onMouseDown: (e: any) => {
            //@ts-ignore
            propsOnCell?.onMouseDown?.(e);
            setSelection({ columns: { start: i, end: undefined }, rows: { start: rowIndex, end: undefined } });
          },
          onMouseMove: (e: any) => {
            //@ts-ignore
            propsOnCell?.onMouseMove?.(e);
            if (
              (selection.columns.end === undefined && selection.columns.start !== undefined) ||
              (selection.rows.end === undefined && selection.rows.start !== undefined)
            ) {
              setSelection((prev) => {
                return { columns: { ...prev.columns, end: i }, rows: { ...prev.rows, end: rowIndex } };
              });
            }
          },
          onMouseEnter: (e: any) => {
            e.preventDefault();
            //@ts-ignore
            propsOnCell?.onMouseEnter?.(e);
            setSelection((prev) => {
              if (prev.columns.start === undefined) return prev;
              return { columns: { ...prev.columns, end: i }, rows: { ...prev.rows, end: rowIndex } };
            });
          },
        };
      },
    })),
  };
};

export const getSelectedData = async <T extends object>(
  selection: { columns: SelectionGrid; rows: SelectionGrid },
  setSelection: Dispatch<
    SetStateAction<{
      columns: SelectionGrid;
      rows: SelectionGrid;
    }>
  >,
  columns: ColumnsType<T> | undefined,
  api: NotificationInstance
) => {
  if (selection.columns.start === undefined || selection.columns.end === undefined) return;
  const { minCol, maxCol } = getSelectionMinAndMax(selection);
  const selectedCells = document.getElementsByClassName(SELECTED_CELL_NAME);
  const cellData = [...selectedCells].map((c) => c.textContent);
  const columnsData = columns?.slice(minCol, maxCol + 1).map((c) => c.title) ?? [];
  const allData = [...columnsData, ...cellData];
  const groupedData = [];
  const groupSize = maxCol - minCol + 1;
  for (let i = 0; i < allData.length; i += groupSize) {
    groupedData.push(allData.slice(i, i + groupSize).join("\t"));
  }
  navigator.clipboard
    .writeText([...groupedData].join("\r"))
    .then(() => {
      api.success({ message: i18next.t("tableSelection.SUCCESSFUL_COPY") });
    })
    .catch(() => {
      api.error({ message: i18next.t("tableSelection.COPY_ERROR") });
    })
    .finally(() => {
      setSelection({ columns: { start: undefined, end: undefined }, rows: { start: undefined, end: undefined } });
    });
};
