import { UseQueryOptions } from "@tanstack/react-query";

import { FeatureId } from "@/api";
import {
  useVehicleChargingSessionDurationStatsQuery,
  VehicleChargingSessionDurationStatsQuery,
  VehicleChargingSessionDurationStatsQueryVariables,
} from "@/api/customerApi";
import { useFeatureFlags } from "@/utils/features";

export const useTelematicsProtectedVehicleChargingSessionDurationStatsQuery = (
  variables: VehicleChargingSessionDurationStatsQueryVariables,
  options?: UseQueryOptions<VehicleChargingSessionDurationStatsQuery>
) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const results = useVehicleChargingSessionDurationStatsQuery(variables, {
    ...options,
    enabled: isFeatureEnabled(FeatureId.Telemetry) ? options?.enabled : false,
  });

  if (isFeatureEnabled(FeatureId.Telemetry)) {
    return results;
  }

  return {
    data: fakeTempStats,
    isLoading: false,
    isError: false,
    error: undefined,
    isSuccess: true,
    telematicsless: true,
  };
};

const fakeTempStats: VehicleChargingSessionDurationStatsQuery = {
  filteredQuery: {
    vehicleChargingSessionDurationStats: {
      vehicle: {
        p5: 91.5,
        p25: 400,
        median: 640,
        p75: 857.5,
        p95: 1094,
      },
      fleet: {
        p5: 5,
        p25: 10,
        median: 35,
        p75: 360,
        p95: 922.749999999999,
      },
    },
  },
};
