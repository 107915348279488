import { Expression, LiteralNumericExpression } from "@lib/src/expression/types";
import { isEqual } from "lodash";
import { KeyboardEvent, useContext, useEffect, useRef } from "react";
import { PartialDeep } from "type-fest";

import { PatternFocusContext } from "@/contexts/PatternFocusContext";

import { StyledBorderlessInput } from "../TypeaheadInput.styled";
import { floatRegex } from "../TypeaheadUtils";
import { PatternFocusedNode } from "./PatternFocusedNode";
import { PatternNodeContainer } from "./PatternNode.styled";

type PatternLiteralNodeProps = {
  node: LiteralNumericExpression;
  path: string[];
  onChange: (newValue: PartialDeep<Expression>, path: string[]) => void;
  disabled: boolean;
  autofocus?: boolean;
};

const PatternLiteralNode = ({ node, path, onChange, disabled }: PatternLiteralNodeProps) => {
  const inputRef = useRef<any>();
  const nodeClassname = `pattern-node ${node.type?.toLowerCase()}`;

  const { setMostRecentPath, mostRecentPath } = useContext(PatternFocusContext);

  useEffect(() => {
    return () => {
      setMostRecentPath([...path]);
    };
  }, []);

  useEffect(() => {
    if (isEqual(mostRecentPath, [...path, "value"])) {
      inputRef.current.focus();
    }
  }, [mostRecentPath]);

  const handleChange = (value: string | number) => {
    if (typeof value === "string" && floatRegex.test(`${value}`)) {
      onChange({ ...node, value: Number(value) }, path);
    }
  };

  const handleDelete = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.code === "Backspace" && (e?.target as HTMLInputElement).value === "0") {
      onChange({}, path);
    }
  };

  return (
    <PatternFocusedNode node={node} path={path} onChange={onChange} disabled={disabled}>
      <PatternNodeContainer className={nodeClassname}>
        <StyledBorderlessInput
          className="no-min-width"
          ref={inputRef}
          placeholder="Enter a Constant Number"
          value={node.value}
          onChange={(e) => handleChange(e.target.value)}
          onKeyDown={handleDelete}
          disabled={disabled}
          autoFocus={isEqual(mostRecentPath, [...path, "value"])}
          onFocus={(e) => {
            setMostRecentPath([...path, "value"]);
            e.stopPropagation();
          }}
        />
      </PatternNodeContainer>
    </PatternFocusedNode>
  );
};

export default PatternLiteralNode;
