import { formatDateField } from "@/features/cluster/ClusterHelpers";

export const isValidClusterEntry = (value: string, dateFormat: string, vinLabel: string) => {
  if (!value) return Promise.reject(new Error("A Cluster needs at least one entry"));
  const rows = value.split(/[\n]+/);
  const rowParts = rows.map((row) => {
    const parts = row.split(/[\s,]+/); // Split by spaces, commas, or tabs
    return [...parts];
  });

  for (let i = 0; i < rowParts.length; i++) {
    if (rowParts[i].length < 2 || rowParts[i]?.[1].trim() === "") {
      return Promise.reject("Each row must include a start date.");
    }
    if (rowParts[i].length > 3) {
      return Promise.reject(`Each row must include only a ${vinLabel}, start date, and end date`);
    }
    if (!formatDateField(rowParts[i][1], dateFormat)) {
      return Promise.reject(`Invalid date on row ${i + 1}`);
    }
  }

  return Promise.resolve();
};
