import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";

import { TimeSeriesIndependentVar } from "@/api";
import { Fault, RepairEventHistory, useVehicleHistoricalDataAnalysisQuery } from "@/api/customerApi";
import { BaseEChart } from "@/components/ui/BaseEChart";
import { QUERY_SETTINGS } from "@/constants";
import { FaultEventFilter, LibraryChart, selectFaultFilters, selectLibrarySpan } from "@/contexts/ChartLibraryStore";
import SCVContext from "@/contexts/SCVContext";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";

import { getAllFaultElementsInRange, getFaultElementsWithName, getUniqueFaultCodes } from "./ChartFaultUtils";
import { buildLibraryFaultOnlyChartConfig, getFaultYCategory } from "./ChartLibraryBuildUtils";

interface ILibraryFaultOnlyChartPreviewItem {
  chart: LibraryChart;
  faultsFiltered: FaultEventFilter[];
}

export const LibraryFaultOnlyChartPreviewItem = ({ chart, faultsFiltered }: ILibraryFaultOnlyChartPreviewItem) => {
  const { pvin } = useContext(SCVContext);
  const { customerIdentifier } = useCustomerIdentifier();
  const librarySpan = useSelector(selectLibrarySpan);
  const { faultFilters: selectedFaultFilters } = useSelector(selectFaultFilters);
  const selectedFaultsWithYAxisValue = selectedFaultFilters.map((f) => ({
    ...f,
    value: getFaultYCategory(f),
  }));

  const independentVar = chart.intervalInput?.timeUnit
    ? TimeSeriesIndependentVar.Time
    : TimeSeriesIndependentVar.Mileage;

  const chartRangeInput = librarySpan.rangeInput ?? chart.rangeInput;

  const { data: historicalEventsData, isLoading } = useVehicleHistoricalDataAnalysisQuery(
    {
      vehicleId: pvin,
      independentVarInterval: librarySpan.intervalInput ?? chart.intervalInput,
      independentVar: independentVar,
      independentVarRange: chartRangeInput,
      withXAxis: true,
    },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME }
  );
  const allFaultEvents = (historicalEventsData?.vehicleFaultHistory?.data ?? []) satisfies Fault[] as Fault[];
  const allRepairEvents = (historicalEventsData?.vehicleRepairEventHistory ||
    []) satisfies RepairEventHistory[] as RepairEventHistory[];

  const faultElements = getAllFaultElementsInRange(
    allFaultEvents,
    faultsFiltered,
    independentVar,
    librarySpan.rangeInput
  );

  const faultElementsWithName = getFaultElementsWithName(faultElements, customerIdentifier);

  const uniqueFaultCodes = useMemo(() => getUniqueFaultCodes(faultElementsWithName), [faultElementsWithName]);

  return (
    <BaseEChart
      showLoading={isLoading}
      style={{ height: Math.max(uniqueFaultCodes * 30 + 60, 300) }}
      {...buildLibraryFaultOnlyChartConfig(
        faultElementsWithName,
        selectedFaultsWithYAxisValue,
        independentVar,
        historicalEventsData?.vehicleTimeSeriesXAxis?.x ?? [],
        allRepairEvents
      )}
      showEmptyChart
    />
  );
};
