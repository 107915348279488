import { useMemo } from "react";

import { FeatureId } from "@/api";
import { VehicleDetailsQuery } from "@/api/customerApi";
import BlockingLoading from "@/components/ui/BlockLoading";
import FriendlyCountryLabel from "@/components/ui/FriendlyCountryLabel";
import FromNowFormatter from "@/components/ui/FromNowFormatter";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { parseDateToMdStr } from "@/utils/dateUtils";
import { useFeatureFlags } from "@/utils/features";
import { formatNumber } from "@/utils/numberUtils";
import { toModelDescription } from "@/utils/vehicleModel";

import { CategoricalDataBodyContainer, CategoricalDataHeaderContainer } from "./VehicleCategoricalData.styled";

interface CategoricalDataProps {
  vehicleDetails: VehicleDetailsQuery["vehicleDetails"] | undefined;
  isLoading: boolean;
}

const VehicleCategoricalData = ({ vehicleDetails, isLoading }: CategoricalDataProps) => {
  const { customerIdentifier } = useCustomerIdentifier();
  const features = useFeatureFlags();

  //TODO handle error state properly

  const categoricalData = vehicleDetails?.[0];

  // TODO: Once we get the exact ways to get car data we can isole this method
  //       inside useCustomerIdenfierHook as a getVehicleURL function
  const imageSrc = useMemo(() => {
    const vehicleImages = customerIdentifier.vehicleImages;
    const carModel = categoricalData?.model ?? "";
    if (vehicleImages?.model?.[carModel]) {
      return `/${vehicleImages.model[carModel]}`;
    }
    if (vehicleImages?.["default"]) {
      return `/${vehicleImages?.["default"]}`;
    }
    return "unknown"; // TODO: Handle a not found image.
  }, [customerIdentifier, categoricalData?.model]);

  const modelDescription = useMemo(
    () => toModelDescription(categoricalData, customerIdentifier.models),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerIdentifier, categoricalData?.model, categoricalData?.modelStyle]
  );

  const widgetHeader = (
    <CategoricalDataHeaderContainer>
      {isLoading ? (
        <BlockingLoading className="inline" />
      ) : features.isFeatureEnabled(FeatureId.Vins) ? (
        <h2 className="heading-normal pvin-label">
          <span>VIN</span>
          <span>{categoricalData?.vin}</span>
        </h2>
      ) : (
        <h2 className="heading-normal pvin-label">
          <span>PVIN</span>
          <span>{categoricalData?.id}</span>
        </h2>
      )}
      <div className="categorical-data-section">
        <span className="body-medium">Latest vehicle reading</span>
        {isLoading ? (
          <BlockingLoading className="inline" />
        ) : (
          <span className="body-medium dark">
            {categoricalData?.lastReadingDate ? (
              <FromNowFormatter value={new Date(Date.parse(categoricalData.lastReadingDate))} />
            ) : (
              "Unknown"
            )}
          </span>
        )}
      </div>
    </CategoricalDataHeaderContainer>
  );

  return (
    <BasicWidget title={widgetHeader} noHeaderStyling className="rendering-order-first">
      <CategoricalDataBodyContainer>
        <div className="categorical-data-section-container">
          <div className="categorical-data-section">
            <p className="body-small">Model</p>
            {isLoading ? <BlockingLoading className="line" /> : <p className="body-medium dark">{modelDescription}</p>}
          </div>
          <div className="categorical-data-section">
            <p className="body-small">Model Year</p>
            {isLoading ? (
              <BlockingLoading className="line" />
            ) : (
              <p className="body-medium dark">{categoricalData?.year}</p>
            )}
          </div>
          <div className="categorical-data-section">
            <p className="body-small">Production Date</p>
            {isLoading ? (
              <BlockingLoading className="line" />
            ) : (
              <p className="body-medium dark">
                {categoricalData?.productionDate ? parseDateToMdStr(new Date(categoricalData.productionDate)) : "-"}
              </p>
            )}
          </div>
          <div className="categorical-data-section">
            <p className="body-small">Delivery Date</p>
            {isLoading ? (
              <BlockingLoading className="line" />
            ) : (
              <p className="body-medium dark">
                {categoricalData?.deliveryDate ? parseDateToMdStr(new Date(categoricalData.deliveryDate)) : "-"}
              </p>
            )}
          </div>
          <div className="categorical-data-section">
            <p className="body-small">Country</p>
            {isLoading ? (
              <BlockingLoading className="line" />
            ) : (
              <p className="body-medium dark">
                {categoricalData?.country ? <FriendlyCountryLabel countryCode={categoricalData.country} /> : "-"}
              </p>
            )}
          </div>
          <div className="categorical-data-section">
            <p className="body-small">Odometer</p>
            {isLoading ? (
              <BlockingLoading className="line" />
            ) : (
              <p className="body-medium dark">
                {categoricalData?.odometer && `${formatNumber(categoricalData.odometer)} km`}
              </p>
            )}
          </div>
          <div className="categorical-data-section">
            <p className="body-small">Last Diagnostic Check</p>
            {isLoading ? (
              <BlockingLoading className="line" />
            ) : (
              <p className="body-medium dark">
                {categoricalData?.lastDiagnosticCheckDate
                  ? parseDateToMdStr(new Date(categoricalData.lastDiagnosticCheckDate))
                  : "-"}
              </p>
            )}
          </div>
        </div>
        {/* TODO move to a thumbnail component */}
        <div className="categorical-data-thumbnail">
          {isLoading ? <BlockingLoading className="lean" /> : <img src={imageSrc} alt="car-thumbnail" />}
        </div>
      </CategoricalDataBodyContainer>
    </BasicWidget>
  );
};

export default VehicleCategoricalData;
