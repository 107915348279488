import { Dispatch, SetStateAction, useState } from "react";

import { Cluster } from "@/api";
import { AffectedVehicleAttributes, TopFaultParamsInput } from "@/api/customerApi";
import { FaultEventsTable } from "@/components/tables/Cluster/FaultEventsTable";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { PillWidgetHeader } from "@/components/widget/PillHeader";
import { toTitleCase } from "@/utils/toTitleCase";

import { DefinedView } from "../clusterModal/ClusterModal";

export interface FaultEventsWidgetProps {
  setAffectedPvins: Dispatch<SetStateAction<AffectedVehicleAttributes[] | undefined>>;
  cluster: Cluster;
  setModalView: Dispatch<SetStateAction<DefinedView | undefined>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export function FaultEventsWidget(props: FaultEventsWidgetProps) {
  const [topFaultParams, setTopFaultParams] = useState<TopFaultParamsInput>({ includeTroubleCode: true });
  const options = [
    "trouble code",
    "software version",
    "hardware version",
    "software part number",
    "hardware part number",
  ].map((option) => ({
    value: option,
    label: toTitleCase(option),
  }));

  const handleSelectionChange = (options: string[]) => {
    setTopFaultParams({
      includeTroubleCode: options.some((o) => o === "trouble code"),
      includeSoftwareVersion: options.some((o) => o === "software version"),
      includeHardwareVersion: options.some((o) => o === "hardware version"),
      includeSoftwarePartNumber: options.some((o) => o === "software part number"),
      includeHardwarePartNumber: options.some((o) => o === "hardware part number"),
    });
  };

  return (
    <BasicWidget
      title={
        <PillWidgetHeader
          title="Fault Events"
          options={options}
          onOptionChange={handleSelectionChange}
          initialValue={["trouble code"]}
          pillsDescriptor="Column Controls"
        />
      }
      noHeaderStyling
    >
      <FaultEventsTable
        topFaultParams={topFaultParams}
        cluster={props.cluster}
        setAffectedPvins={props.setAffectedPvins}
        setModalView={props.setModalView}
        setLoading={props.setLoading}
      />
    </BasicWidget>
  );
}
