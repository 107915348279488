import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { Link } from "@tanstack/react-router";
import React, { Dispatch } from "react";

import { FeatureId } from "@/api";
import { AffectedVehicleAttributes } from "@/api/customerApi";
import { BasicTable } from "@/components/tables/BasicTable";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { useFeatureFlags } from "@/utils/features";
import { toModelDescription } from "@/utils/vehicleModel";

import { ClusterModalView } from "./ClusterModal";

type AffectedVehiclesProps = {
  pvins: AffectedVehicleAttributes[];
  setView: Dispatch<React.SetStateAction<ClusterModalView>>;
};

const renderLink = (_: any, row: { pvin: string; vin: string }) => {
  return (
    <Link to="/vehicle/$pvin" params={{ pvin: row.pvin }}>
      {row.vin}
    </Link>
  );
};

export const AffectedVehicles = ({ pvins }: AffectedVehiclesProps) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const { customerIdentifier } = useCustomerIdentifier();
  return (
    <>
      <BasicTable
        columns={[
          ...(isFeatureEnabled(FeatureId.Vins)
            ? [
                {
                  key: "vin",
                  title: "VIN",
                  dataIndex: "vin",
                  render: renderLink,
                },
              ]
            : [
                {
                  key: "pvin",
                  title: "PVIN",
                  dataIndex: "pvin",
                  render: renderLink,
                },
              ]),
          {
            key: "model",
            title: "Model",
            render(_v, record) {
              const model = toModelDescription(
                { model: record.model, modelStyle: record.modelStyle },
                customerIdentifier.models
              );
              return model || "-";
            },
          },
          { key: "modelYear", title: "Model Year", dataIndex: "modelYear" },
        ]}
        rowKey={"pvin"}
        dataSource={
          pvins.map((v) => ({ ...v, vin: isFeatureEnabled(FeatureId.Vins) ? v.vin! : v.id, pvin: v.id })) ?? []
        }
        pagination={
          (pvins.length ?? 0) > DEFAULT_PAGE_SIZE
            ? { pageSize: DEFAULT_PAGE_SIZE, style: { padding: "5px" }, showSizeChanger: false }
            : false
        }
      />
    </>
  );
};
