import { DefaultTheme } from "styled-components";

// TODO centralize colors
export const defaultTheme: DefaultTheme = {
  colors: {
    background: "#141919",
    backgroundHover: "#5F5F5F",
    backgroundActive: "#323232",
    secondary: "#F4FC7B",
    success: "#2AC18B",
    warning: "#FFAC40",
    error: "#DF0000",
    defaultWhite: "#FFFFFF",
    defaultBlack: "#000000",
    darkGray: "#222222",
    darkOpacity: "#00000040",
    defaultGray: "#CCCCCC",
    lightGray: "#DEDEDE",
    simpleGray: "#D2D2D2",
    smoothGray: "#F9FAFE",
    blueGray: "#98A2B3",
    oddRow: "#FAFBFE",
    hoverRow: "#E5F4FF",
    accentBlue: "#0094FF",
    hoverBlue: "#AACCFF",
    mediumTeal: "#459b99",
    antdHover: "#29adff",
  },
  breakpoints: {
    sm: "768px",
    md: "1366px",
    lg: "1920px",
  },
  sizes: {
    sidebar: "70px",
    header: "5rem",
  },
  paddings: {
    mainContent: "40px",
  },
};
