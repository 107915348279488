import { useAuth0, User } from "@auth0/auth0-react";
import Intercom from "@intercom/messenger-js-sdk";
import { CustomTokenClaims } from "@lib/src/auth/tokenClaims";
import { useLocation } from "@tanstack/react-router";
import { useEffect } from "react";

import { getCurrentCustomerId } from "@/utils/customers";

/**
 * Renders a support chat widget control in the lower right corner of the app, which hovers in the foreground
 * in front of all other content.
 */
export default function SupportChatWidget() {
  // TODO: For internal environments, this should show our own bug submission form rather than intercom
  const { user, isAuthenticated, getIdTokenClaims } = useAuth0();
  const pathName = useLocation({
    select: (location) => location.pathname,
  });

  const currentUrl = `${window.origin}${pathName}`;

  useEffect(() => {
    async function bootIntercom(user: User) {
      if (CustomTokenClaims.INTERCOM_HASH in user) {
        const idTokenClaims = await getIdTokenClaims();
        Intercom({
          app_id: import.meta.env.VITE_INTERCOM_APP_ID,
          name: user.name,
          email: user.email,
          user_hash: user?.[CustomTokenClaims.INTERCOM_HASH],
          sid: idTokenClaims?.sid,
          current_customer_id: getCurrentCustomerId(),
          app_location: currentUrl,
        });
      }
    }

    if (isAuthenticated && user) {
      bootIntercom(user);
    }
  }, [user, isAuthenticated, getIdTokenClaims, currentUrl]);

  return <></>;
}
