import { useEffect, useState } from "react";

import { Pill } from "../pill/Pill";
import { PillHeaderStyled, PillsContainer } from "./PillHeader.styled";

type PillWidgetHeaderProps = {
  title: string;
  options: { label: string; value: string }[];
  onOptionChange: (selectedOptions: string[]) => any;
  initialValue?: string[];
  pillsDescriptor?: string;
};

export const PillWidgetHeader = ({
  title,
  options,
  onOptionChange,
  initialValue,
  pillsDescriptor,
}: PillWidgetHeaderProps) => {
  const [selectedOptions, setSelectedOptions] = useState<Set<string>>(new Set(initialValue ?? []));

  useEffect(() => {
    onOptionChange(Array.from(selectedOptions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  const handleClick = (value: string) => {
    const newSet = new Set(selectedOptions);
    if (selectedOptions.has(value)) {
      newSet.delete(value);
      setSelectedOptions(newSet);
    } else {
      newSet.add(value);
      setSelectedOptions(newSet);
    }
  };

  return (
    <PillHeaderStyled>
      <h3 className="frame-input-label heading-x-small">{title}</h3>
      <div>
        <PillsContainer>
          <div>{pillsDescriptor}</div>
          {options.map((option) => {
            return (
              <Pill
                key={option.value}
                label={option.label}
                value={option.value}
                selected={selectedOptions.has(option.value)}
                onClick={() => handleClick(option.value)}
              />
            );
          })}
        </PillsContainer>
      </div>
    </PillHeaderStyled>
  );
};
