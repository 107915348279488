import { DEFAULT_PAGINATION } from "@lib/src/table/defaults";
import { createFileRoute } from "@tanstack/react-router";

import { FeatureId, SavedExpressionsSortField, SortOrder } from "@/api";
import { ProtectedRoute } from "@/components/protectedRoute/ProtectedRoute";
import { TableState } from "@/components/tables/StatefulTable.types";
import { PatternsHome } from "@/features/pattern/PatternsHome";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

const defaultTableState: TableState = {
  pagination: DEFAULT_PAGINATION,
  sorting: { field: SavedExpressionsSortField.Created, order: SortOrder.Descend },
};

export type PatternsHomeSearch = {
  patternsTable?: TableState;
};

export const Route = createFileRoute("/pattern/")({
  component: () => (
    <ProtectedRoute featureId={FeatureId.Patterns}>
      <AppMainLayout>
        <PatternsHome />
      </AppMainLayout>
    </ProtectedRoute>
  ),
  beforeLoad: () => setTitleBeforeLoad("Patterns"),
  validateSearch: (search: Record<string, unknown>): PatternsHomeSearch => {
    return {
      patternsTable: (search.patternsTable as TableState) || defaultTableState,
    };
  },
});
