import { Button, Flex, Form, Input } from "antd";
import { NotificationInstance } from "antd/es/notification/interface";
import { Dispatch, SetStateAction, useState } from "react";

import { ClusterRedshift, useUpdateClusterMutation } from "@/api";
import DateFormatDropdownPicker from "@/components/event/DateFormatDropdownPicker";
import { useVinLabel } from "@/hooks/useVinLabel";
import { getCurrentCustomerId } from "@/utils/customers";
import { isValidClusterEntry } from "@/utils/isValidClusterEntry";
import { toPercent } from "@/utils/toPercent";

import {
  addVehiclesToClusterInBatch,
  BatchAddVehiclesToClusterState,
  makeInitialBatchAddVehiclesToClusterState,
} from "../addVehiclesToClusterInBatch";
import { inputToPvinsAndDateRanges } from "../ClusterHelpers";
import { ClusterModalView } from "./ClusterModal";

export type NewClusterProps = {
  api: NotificationInstance;
  onSuccess: () => void;
  onCancel: () => void;
  clusterId: string;
  clusterVersion: number;
  setView: Dispatch<SetStateAction<ClusterModalView>>;
  setMissingPvins: Dispatch<SetStateAction<string[] | undefined>>;
  setRepeatedCombinations: Dispatch<SetStateAction<ClusterRedshift[]>>;
};

type FormField = {
  input: string;
};

export const newClusterFooter = undefined;
export const newClusterButtonStyle = {};

export const missingPvinsFooter = null;
export const missingPvinsButtonStyle = { display: "none" };

export const AddVehicleToCluster = ({
  api,
  onSuccess,
  onCancel,
  setView,
  clusterId,
  clusterVersion: version,
  setMissingPvins,
  setRepeatedCombinations,
}: NewClusterProps) => {
  const [dateFormat, setDateFormat] = useState<string>("");
  const [form] = Form.useForm();

  const vinLabel = useVinLabel();

  const [batchUpdateState, setBatchUpdateState] = useState<BatchAddVehiclesToClusterState>(
    makeInitialBatchAddVehiclesToClusterState()
  );

  const updateCluster = useUpdateClusterMutation();

  const handleClose = () => {
    form.resetFields();
    onCancel();
  };

  const handleConfirm = (args: FormField) => {
    const { input } = args;

    const { input: inputFormatted, errors } = inputToPvinsAndDateRanges(input, vinLabel, dateFormat);

    if (errors.length) {
      api.error({ message: errors.join(", ") });
      return;
    }

    addVehiclesToClusterInBatch({
      customerId: getCurrentCustomerId() ?? "",
      clusterId,
      clusterVersion: version,
      updateCluster,
      input: inputFormatted,
      onStateChange: setBatchUpdateState,
      onResult: (result) => {
        if (result.isAllSuccessfullyDone) {
          api.success({ message: "All cluster updates went successfully" });
        } else {
          api.error({ message: "One or more failures on updating cluster" });
        }

        if (result.accumulatedMissingPvins.length || result.accumulatedRepeatedCombinations.length) {
          setView("missingPvins");
          setMissingPvins(result.accumulatedMissingPvins);
          setRepeatedCombinations(result.accumulatedRepeatedCombinations);
        } else {
          handleClose();
        }

        onSuccess();
      },
    });
  };

  const getSubmitButtonLabel = (): string => {
    if (!batchUpdateState.isUpdating) {
      return "Update Cluster";
    }

    if (batchUpdateState.totalBatchesQtt === 1) {
      return "Updating...";
    }

    return `Updating (${toPercent(batchUpdateState.currentBatchIndex / batchUpdateState.totalBatchesQtt, 0)})...`;
  };

  const inputPlaceholder = `Enter the ${vinLabel}s and date ranges in the cluster seperated by commas or spaces. Each line is a new ${vinLabel} + date range value`;

  return (
    <Form
      form={form}
      layout="vertical"
      name="basic"
      onFinish={(v: FormField) => {
        handleConfirm(v);
      }}
      autoComplete="off"
    >
      <h4 className="heading-x-small">Add {vinLabel}s + Date Ranges to Cluster</h4>
      <Form.Item<FormField>
        label={`${vinLabel}s + Date Ranges`}
        name="input"
        rules={[
          { required: true, message: "Add at least one combination" },
          {
            validator: (_, value) => isValidClusterEntry(value, dateFormat, vinLabel),
          },
        ]}
        initialValue={""}
      >
        <Input.TextArea placeholder={inputPlaceholder} />
      </Form.Item>
      <Flex justify="end">
        <DateFormatDropdownPicker
          onSelected={(value) => {
            setDateFormat(value);
            form.validateFields();
          }}
        />
      </Flex>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={batchUpdateState.isUpdating}>
          {getSubmitButtonLabel()}
        </Button>
      </Form.Item>
    </Form>
  );
};
