import { EllipsisOutlined } from "@ant-design/icons";
import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { Link, useNavigate, useSearch } from "@tanstack/react-router";
import { Button, Dropdown, TableColumnType } from "antd/lib";
import { FilterValue, SorterResult, SortOrder, TablePaginationConfig } from "antd/lib/table/interface";
import camelCase from "lodash/camelCase";
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";

import { Cluster, Pagination } from "@/api";
import {
  AffectedVehicleAttributes,
  FaultHistoryColumn,
  FaultsByPvinsAffectedPvinsArgs,
  FaultsByPvinsAffectedPvinsSortField,
  FaultsByPvinsAffectedPvinsSortInput,
  FaultStat,
  TopFaultExclusionsInput,
  TopFaultParamsInput,
  useFaultHistoryColumnByUserInputQuery,
  useFaultsByPvinsAffectedPvinsQuery,
  useFaultsByPvinsQuery,
} from "@/api/customerApi";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { DefinedView } from "@/features/cluster/clusterModal/ClusterModal";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { FaultEventAnalysisSearch } from "@/hooks/useEventAnalysisSearch";
import { getFaultDescription } from "@/utils/fault/getFaultDescription";

import { BasicTable } from "../BasicTable";
import { TableFilterExclusions } from "../filter/TableFilterExclusions";
import { compareText } from "../sorting";
import { useRemoteFiltering } from "../useRemoteFiltering";
import { useRemoteSorting } from "../useRemoteSorting";

type FaultEventsTableProps = {
  topFaultParams: TopFaultParamsInput;
  setAffectedPvins: Dispatch<SetStateAction<AffectedVehicleAttributes[] | undefined>>;
  cluster: Cluster;
  setModalView: Dispatch<SetStateAction<DefinedView | undefined>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

export const FaultEventsTable = ({
  topFaultParams,
  setAffectedPvins,
  cluster,
  setModalView,
  setLoading,
}: FaultEventsTableProps) => {
  const { customerIdentifier } = useCustomerIdentifier();
  const { globalFilter } = useContext(GlobalFilterContext);

  const currentPage = useSearch({ strict: false, select: (search) => search.faultEventsPage });
  const navigate = useNavigate();

  const pagination: Pagination = {
    currentPage: currentPage || 1,
    pageSize: DEFAULT_PAGE_SIZE,
  };

  const handlePaginationChange = (page: number) => {
    navigate({ search: (prev) => ({ ...prev, faultEventsPage: page }) });
  };

  const fields = Object.values(FaultHistoryColumn);

  const {
    currentlySearching,
    setSearchedFilteringOptions,
    filteringOptions,
    handleFilterInputChangeUndebounced,
    filteringSelecteds,
  } = useRemoteFiltering({ fields });

  const [exclusions, setExclusions] = useState<TopFaultExclusionsInput>({
    ...(selectedColumnToFilteringInput(filteringSelecteds) as TopFaultExclusionsInput),
    hardwarePartNumber: [],
    hardwareVersion: [],
  });

  const { data: currentFilteringOptionsData, isFetching: isFetchingFilteringOptions } =
    useFaultHistoryColumnByUserInputQuery(
      {
        input: currentlySearching?.content ?? "",
        column: currentlySearching?.field as FaultHistoryColumn,
        exclusions,
      },
      { enabled: !!currentlySearching?.field }
    );

  useEffect(() => {
    if (!currentlySearching) {
      return;
    }

    const newOptions = (currentFilteringOptionsData?.faultHistoryColumnByUserInput ?? []).map((it) => ({
      value: it,
      label: it,
    }));
    setSearchedFilteringOptions({ field: currentlySearching.field, options: newOptions });
  }, [setSearchedFilteringOptions, currentlySearching, currentFilteringOptionsData]);

  const { sorting, toMaybeColumnWithInitialSorting, handleTableChangeForSorting } = useRemoteSorting({
    fieldsEnumOptions: Object.values(FaultsByPvinsAffectedPvinsSortField),
    initialValue: { field: FaultsByPvinsAffectedPvinsSortField.FaultCodeAppearances, order: "descend" as SortOrder },
  });
  const [selectedFault, setSelectedFault] = useState<FaultsByPvinsAffectedPvinsArgs>();
  const {
    data: faultsData,
    isLoading: isFaultsLoading,
    isFetching,
  } = useFaultsByPvinsQuery(
    {
      clusterId: cluster?.id,
      filter: globalFilter,
      params: topFaultParams,
      exclusions: exclusions,
      pagination,
      sorting: sorting as FaultsByPvinsAffectedPvinsSortInput,
    },
    { enabled: !!cluster }
  );

  const { data: pvins } = useFaultsByPvinsAffectedPvinsQuery(
    {
      clusterId: cluster?.id,
      filter: globalFilter,
      params: selectedFault ?? { faultCode: "", ecu: "" },
    },
    { enabled: !!cluster && !!selectedFault }
  );

  useEffect(() => {
    if (pvins) {
      setLoading(false);
      setSelectedFault(undefined);
      setAffectedPvins(pvins.filteredQuery.FaultsByPvinsAffectedPvins);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pvins]);

  const handleRowClick = (row: FaultStat) => {
    setLoading(true);
    setModalView("vehiclesAffected");
    setSelectedFault({
      faultCode: row.faultCode,
      ecu: row.ecu,
      troubleCode: row.troubleCode,
      softwarePartNumber: row.softwarePartNumber,
      softwareVersion: row.softwareVersion,
      hardwarePartNumber: row.hardwarePartNumber,
      hardwareVersion: row.hardwareVersion,
    });
  };

  const buildFaultAnalysisSearch = (row: FaultStat): FaultEventAnalysisSearch => {
    return {
      ecu: row.ecu,
      softwareVersion: topFaultParams.includeSoftwareVersion ? row.softwareVersion?.toString() : undefined,
      hardwareVersion: topFaultParams.includeHardwareVersion ? row.hardwareVersion?.toString() : undefined,
      softwarePartNumber: topFaultParams.includeSoftwarePartNumber ? row.softwarePartNumber?.toString() : undefined,
      hardwarePartNumber: topFaultParams.includeHardwarePartNumber ? row.hardwarePartNumber?.toString() : undefined,
      troubleCode: topFaultParams.includeTroubleCode ? row.troubleCode?.toString() : undefined,
      clusterId: cluster?.id,
    };
  };

  const getFilteringOptions = (fieldName: FaultHistoryColumn) => {
    const availableOptions = filteringOptions[fieldName] as { value: string; label: string }[];
    const previouslyUnselectedValues = exclusions[camelCase(fieldName) as keyof TopFaultExclusionsInput] ?? [];
    const previouslyUnselectedOptions = previouslyUnselectedValues.map((value) => ({
      value: value || "",
      label: value || "",
    }));
    const options = [...previouslyUnselectedOptions, ...availableOptions].sort((a, b) => compareText(a.label, b.label));
    const uniqueOptions = uniqBy(options, "value");
    return uniqueOptions;
  };

  const handleFilterDropdownOpenChangeFn = (fieldName: FaultHistoryColumn) => (open: boolean) => {
    if (open) {
      handleFilterInputChangeUndebounced(fieldName, "");
    }
  };

  const handleExclusionsApplied = (fieldName: FaultHistoryColumn, values: (string | undefined)[]) => {
    setExclusions({ ...exclusions, [camelCase(fieldName)]: values });
  };

  const getColumns = (): TableColumnType<FaultStat>[] => {
    return [
      {
        key: "ecu",
        dataIndex: "ecu",
        title: columnFieldToLabel(FaultHistoryColumn.Ecu),
        sorter: true,
        onFilterDropdownOpenChange: handleFilterDropdownOpenChangeFn(FaultHistoryColumn.Ecu),
        filterDropdown() {
          return (
            <TableFilterExclusions
              label={columnFieldToLabel(FaultHistoryColumn.Ecu)}
              isLoading={isFetchingFilteringOptions}
              fieldName={FaultHistoryColumn.Ecu}
              options={getFilteringOptions(FaultHistoryColumn.Ecu)}
              onApplyExclusions={(fieldName, values) => handleExclusionsApplied(fieldName, values)}
            />
          );
        },
      },
      topFaultParams?.includeSoftwareVersion
        ? {
            key: "softwareVersion",
            dataIndex: "softwareVersion",
            title: columnFieldToLabel(FaultHistoryColumn.SoftwareVersion),
            sorter: true,
            onFilterDropdownOpenChange: handleFilterDropdownOpenChangeFn(FaultHistoryColumn.SoftwareVersion),
            filterDropdown() {
              return (
                <TableFilterExclusions
                  label={columnFieldToLabel(FaultHistoryColumn.SoftwareVersion)}
                  isLoading={isFetchingFilteringOptions}
                  fieldName={FaultHistoryColumn.SoftwareVersion}
                  options={getFilteringOptions(FaultHistoryColumn.SoftwareVersion)}
                  onApplyExclusions={handleExclusionsApplied}
                />
              );
            },
          }
        : {},
      topFaultParams?.includeHardwareVersion
        ? {
            key: "hardwareVersion",
            dataIndex: "hardwareVersion",
            title: columnFieldToLabel(FaultHistoryColumn.HardwareVersion),
            sorter: true,
            onFilterDropdownOpenChange: handleFilterDropdownOpenChangeFn(FaultHistoryColumn.HardwareVersion),
            filterDropdown() {
              return (
                <TableFilterExclusions
                  label={columnFieldToLabel(FaultHistoryColumn.HardwareVersion)}
                  isLoading={isFetchingFilteringOptions}
                  fieldName={FaultHistoryColumn.HardwareVersion}
                  options={getFilteringOptions(FaultHistoryColumn.HardwareVersion)}
                  onApplyExclusions={handleExclusionsApplied}
                />
              );
            },
          }
        : {},
      topFaultParams?.includeSoftwarePartNumber
        ? {
            key: "softwarePartNumber",
            dataIndex: "softwarePartNumber",
            title: columnFieldToLabel(FaultHistoryColumn.SoftwarePartNumber),
            sorter: true,
            onFilterDropdownOpenChange: handleFilterDropdownOpenChangeFn(FaultHistoryColumn.SoftwarePartNumber),
            filterDropdown() {
              return (
                <TableFilterExclusions
                  label={columnFieldToLabel(FaultHistoryColumn.SoftwarePartNumber)}
                  isLoading={isFetchingFilteringOptions}
                  fieldName={FaultHistoryColumn.SoftwarePartNumber}
                  options={getFilteringOptions(FaultHistoryColumn.SoftwarePartNumber)}
                  onApplyExclusions={handleExclusionsApplied}
                />
              );
            },
          }
        : {},
      topFaultParams?.includeHardwarePartNumber
        ? {
            key: "hardwarePartNumber",
            dataIndex: "hardwarePartNumber",
            title: columnFieldToLabel(FaultHistoryColumn.HardwarePartNumber),
            sorter: true,
            onFilterDropdownOpenChange: handleFilterDropdownOpenChangeFn(FaultHistoryColumn.HardwarePartNumber),
            filterDropdown() {
              return (
                <TableFilterExclusions
                  label={columnFieldToLabel(FaultHistoryColumn.HardwarePartNumber)}
                  isLoading={isFetchingFilteringOptions}
                  fieldName={FaultHistoryColumn.HardwarePartNumber}
                  options={getFilteringOptions(FaultHistoryColumn.HardwarePartNumber)}
                  onApplyExclusions={handleExclusionsApplied}
                />
              );
            },
          }
        : {},
      {
        key: "faultCode",
        dataIndex: "faultCode",
        title: columnFieldToLabel(FaultHistoryColumn.FaultCode),
        sorter: true,
        onFilterDropdownOpenChange: handleFilterDropdownOpenChangeFn(FaultHistoryColumn.FaultCode),
        filterDropdown() {
          return (
            <TableFilterExclusions
              label={columnFieldToLabel(FaultHistoryColumn.FaultCode)}
              isLoading={isFetchingFilteringOptions}
              fieldName={FaultHistoryColumn.FaultCode}
              options={getFilteringOptions(FaultHistoryColumn.FaultCode)}
              onApplyExclusions={handleExclusionsApplied}
            />
          );
        },
      },
      topFaultParams?.includeTroubleCode
        ? {
            key: "troubleCode",
            dataIndex: "troubleCode",
            title: columnFieldToLabel(FaultHistoryColumn.TroubleCode),
            sorter: true,
            onFilterDropdownOpenChange: handleFilterDropdownOpenChangeFn(FaultHistoryColumn.TroubleCode),
            tooltipMessage: (_: string, row: FaultStat) =>
              getFaultDescription(customerIdentifier, row.faultCode, row.ecu, row.troubleCode ?? "")?.name ?? undefined,
            filterDropdown() {
              return (
                <TableFilterExclusions
                  label={columnFieldToLabel(FaultHistoryColumn.TroubleCode)}
                  isLoading={isFetchingFilteringOptions}
                  fieldName={FaultHistoryColumn.TroubleCode}
                  options={getFilteringOptions(FaultHistoryColumn.TroubleCode)}
                  onApplyExclusions={handleExclusionsApplied}
                />
              );
            },
          }
        : {},
      { key: "faultCodeAppearances", title: "Occurrences", dataIndex: "faultCodeAppearances", sorter: true },
      {
        key: "vehiclesAffected",
        title: "Vehicles Affected",
        dataIndex: "vehiclesAffected",
        sorter: true,
      },
      {
        key: "actions",
        title: "",
        dataIndex: "",
        render: (_: any, row: FaultStat) => {
          return (
            <Dropdown
              menu={{
                items: [
                  {
                    key: "viewInEventAnalysis",
                    label: (
                      <Link
                        to="/fault/$faultCodeId"
                        params={{ faultCodeId: row.faultCode }}
                        search={() => ({ ...buildFaultAnalysisSearch(row) })}
                        onClick={(e) => e.stopPropagation()}
                      >
                        View in event analysis
                      </Link>
                    ),
                  },
                  {
                    key: "viewAffectedVehicles",
                    label: "View affected vehicles",
                    onClick: (e) => {
                      e.domEvent.stopPropagation();
                      handleRowClick(row);
                    },
                  },
                ],
              }}
              placement="bottomRight"
            >
              <Button
                size="small"
                type="text"
                className="action-button"
                onClick={(e) => e.stopPropagation()}
                icon={<EllipsisOutlined style={{ transform: "rotate(90deg)", fontSize: "16px" }} />}
              />
            </Dropdown>
          );
        },
        width: 75,
      },
    ]
      .filter((c) => !isEmpty(c))
      .map(toMaybeColumnWithInitialSorting);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorterResult: SorterResult<any> | SorterResult<any>[]
  ) => {
    handleTableChangeForSorting(pagination, filters, sorterResult as SorterResult<unknown>);
  };

  return (
    <BasicTable
      style={{ minHeight: 600 }}
      columns={getColumns()}
      dataSource={faultsData?.filteredQuery.faultsByPvins.result ?? []}
      pagination={{
        pageSize: DEFAULT_PAGE_SIZE,
        total: faultsData?.filteredQuery.faultsByPvins.totalCount,
        current: currentPage,
        onChange: handlePaginationChange,
        showSizeChanger: false,
      }}
      onChange={handleTableChange}
      loading={isFaultsLoading || isFetching}
      rowKey={(record) => JSON.stringify(record)}
      onRow={(record) => ({
        onClick: () =>
          navigate({
            to: "/fault/$faultCodeId",
            params: { faultCodeId: record.faultCode },
            search: buildFaultAnalysisSearch(record),
          }),
        style: { cursor: "pointer" },
      })}
    />
  );
};

function selectedColumnToFilteringInput(
  selection: Record<string, (string | number | undefined)[]>
): TopFaultParamsInput {
  return Object.entries(selection).reduce((acc, [field, selecteds]) => ({ ...acc, [camelCase(field)]: selecteds }), {});
}

function columnFieldToLabel(column: FaultHistoryColumn): string {
  switch (column) {
    case FaultHistoryColumn.Ecu:
      return "ECU";
    case FaultHistoryColumn.FaultCode:
      return "Fault Code";
    case FaultHistoryColumn.TroubleCode:
      return "Trouble Code";
    case FaultHistoryColumn.SoftwareVersion:
      return "Software Version";
    case FaultHistoryColumn.SoftwarePartNumber:
      return "Software Part Number";
    case FaultHistoryColumn.HardwareVersion:
      return "Hardware Version";
    case FaultHistoryColumn.HardwarePartNumber:
      return "Hardware Part Number";
    default:
      return `"${column}"`;
  }
}
