import { EChartsType } from "echarts";
import EChartsReact from "echarts-for-react";
import { DebouncedFunc } from "lodash";
import { RefObject } from "react";

import { TimeSeriesIndependentVar } from "@/api";
import { Fault, RepairEventHistory } from "@/api/customerApi";
import { BaseEChart } from "@/components/ui/BaseEChart";
import { EchartsTooltipFormatterParams } from "@/utils/types/EChartsDefinitions";

import { buildLibraryFaultOnlyChartConfig } from "./ChartLibraryBuildUtils";

export type FaultIndetinfier = {
  value: string;
  code: string;
  troubleCode: string;
  source: string;
};

type FaultsOnlyChartProps = {
  independentVar: TimeSeriesIndependentVar;
  faultElementsWithName: (Fault & { value: string })[];
  faultsLoading: boolean;
  faultsChartRef: RefObject<EChartsReact | undefined>;
  handleSeriesClick: (e: EchartsTooltipFormatterParams) => void;
  zoomEventHandler: DebouncedFunc<(chart?: EChartsReact) => void>;
  restoreZoomHandler: () => void;
  initChartZoomMode: (chart?: EChartsType) => void;
  xAxis: number[];
  uniqueFaultCodes: number;
  allRepairEvents: RepairEventHistory[];
  selectedFaultsWithYAxisValue: FaultIndetinfier[];
};

export const FaultsOnlyChart = ({
  independentVar,
  faultElementsWithName,
  faultsLoading,
  faultsChartRef,
  handleSeriesClick,
  zoomEventHandler,
  restoreZoomHandler,
  initChartZoomMode,
  xAxis,
  uniqueFaultCodes,
  allRepairEvents,
  selectedFaultsWithYAxisValue,
}: FaultsOnlyChartProps) => {
  return (
    <BaseEChart
      style={{ height: Math.max(uniqueFaultCodes * 30 + 90, 300) }}
      className="library-fault-chart"
      {...buildLibraryFaultOnlyChartConfig(
        faultElementsWithName,
        selectedFaultsWithYAxisValue,
        independentVar,
        xAxis,
        allRepairEvents ?? []
      )}
      showLoading={faultsLoading}
      ref={faultsChartRef as RefObject<EChartsReact>}
      onChartReady={(chart) => initChartZoomMode(faultsChartRef.current?.getEchartsInstance() || chart)}
      onEvents={{
        click: (e: EchartsTooltipFormatterParams) => {
          handleSeriesClick(e);
        },
        datazoom: () => {
          zoomEventHandler(faultsChartRef?.current ?? undefined);
        },
        restore: () => {
          zoomEventHandler(faultsChartRef.current ?? undefined);
          initChartZoomMode(faultsChartRef.current?.getEchartsInstance());
          restoreZoomHandler();
        },
      }}
      notMerge={true}
      showEmptyChart
    />
  );
};
