import { Flex, List, Typography } from "antd";
import { HookAPI } from "antd/es/modal/useModal";

import { Dependency } from "@/api";
import { buildDependencyLink, getDependencyTypeName } from "@/utils/patterns";

import { DependencyLink, DependencyList } from "./DependencyModal.styled";

const buildDependencyItem = (item: Dependency) => (
  <List.Item>
    <DependencyLink to={buildDependencyLink(item.type, item.id)} target="_blank">
      <Flex justify="space-between" gap={24}>
        <div className="dependency-name">{item.name}</div>
        <div className="dependency-type">{getDependencyTypeName(item.type)}</div>
      </Flex>
    </DependencyLink>
  </List.Item>
);

export const showModalDeleteDependencies = (modal: HookAPI, data: Dependency[], title: string, message: string) =>
  modal.warning({
    width: 600,
    title: (
      <Flex vertical gap={2}>
        <Typography.Title level={5} type="warning">
          {title}
        </Typography.Title>
        <Typography.Text>{message}</Typography.Text>
      </Flex>
    ),
    content: (
      <DependencyList>
        <List
          size="small"
          header={<Typography.Text>Dependencies</Typography.Text>}
          bordered
          dataSource={data}
          renderItem={buildDependencyItem}
        />
      </DependencyList>
    ),
    maskClosable: true,
    okText: "Ok",
    styles: {
      body: {
        width: "100%",
        padding: "1.5rem 1rem 1rem 1rem",
      },
    },
  });
