import { Link } from "@tanstack/react-router";
import { Button } from "antd";

import { PatternList } from "@/features/pattern/PatternList";

import { TopHeadingWithButtons } from "../../components/pageContent/TopHeadingWithButtons";

export const PatternsHome = () => {
  return (
    <>
      <TopHeadingWithButtons
        title="Patterns"
        description="Enhance your analytical capabilities across Workbench by creating and editing your own, custom data patterns that can leverage telematics and fault data."
        buttons={
          <Link to="/pattern/new">
            <Button type="primary">Create New Pattern</Button>
          </Link>
        }
      />
      <PatternList />
    </>
  );
};
