import { QuestionCircleOutlined } from "@ant-design/icons";
import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { useQueryClient } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";
import { Button, Col, notification, Popconfirm, Popover, Row } from "antd";
import { Key, useContext, useState } from "react";

import {
  ClusterUpdateAction,
  FeatureId,
  Pagination,
  useClusterDetailsQuery,
  useClusterNonVehicleDetailsQuery,
  useListClustersQuery,
  useUpdateClusterMutation,
} from "@/api";
import {
  AffectedVehicleAttributes,
  AttributeTypeEnum,
  ClusterMetadata,
  ClusterRedshiftCreateInput,
  useAttributeCountQuery,
  useDistanceVersusFailureCountQuery,
  useFaultsByPvinsQuery,
  useVehicleMetadataQuery,
} from "@/api/customerApi";
import { ClusterAttributeCount } from "@/components/charts/cluster/ClusterAttributeCount";
import { ClusterDistanceVsFailureCount } from "@/components/charts/cluster/ClusterDistanceVsFailureCount";
import Loading from "@/components/loading";
import { BasicTable } from "@/components/tables/BasicTable";
import { BasicWidget } from "@/components/widget/BasicWidget";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { useVinLabel } from "@/hooks/useVinLabel";
import i18next from "@/i18n";
import Page404 from "@/pages/404";
import { Route } from "@/routes/cluster.$clusterId";
import { getCurrentCustomerId } from "@/utils/customers";
import { parseDateToStr } from "@/utils/dateUtils";
import { useFeatureFlags } from "@/utils/features";
import { toModelDescription } from "@/utils/vehicleModel";

import { ClusterModal, DefinedView } from "../clusterModal/ClusterModal";
import { ClusterViewVehicleTable, ClusterViewWrapper, TableTitle, VehicleHelperButtons } from "./ClusterView.styled";
import { ClusterViewHeader } from "./ClusterviewHeader";
import { FaultEventsWidget } from "./FaultEventsWidget";

export type ClusterViewParams = {
  id: string;
};

export const ClusterView = () => {
  const { clusterId } = Route.useParams();
  const { customerIdentifier } = useCustomerIdentifier();
  const { isFeatureEnabled } = useFeatureFlags();
  const { globalFilter } = useContext(GlobalFilterContext);
  const [isDeletingPvins, setIsDeletingPvins] = useState(false);
  const [modalView, setModalView] = useState<DefinedView>();
  const queryClient = useQueryClient();
  const updateCluster = useUpdateClusterMutation();
  const vinLabel = useVinLabel();
  const [api, context] = notification.useNotification();
  const [affectedPvins, setAffectedPvins] = useState<AffectedVehicleAttributes[]>();
  const [selectedPvins, setSelectedPvins] = useState<ClusterMetadata[]>([]);
  const [pvinsLoading, setPvinsLoading] = useState(false);

  const currentPage = Route.useSearch({ select: (search) => search.diagnosticChecksPage });
  const diagnosticCheckNavigate = Route.useNavigate();
  const areVinsEnabled = isFeatureEnabled(FeatureId.Vins);

  const pagination: Pagination = {
    currentPage: currentPage || 1,
    pageSize: DEFAULT_PAGE_SIZE,
  };

  const handlePaginationChange = (page: number) => {
    diagnosticCheckNavigate({ search: (prev) => ({ ...prev, diagnosticChecksPage: page }) });
  };

  const clusterQueryParams = {
    customerId: getCurrentCustomerId() ?? "",
    id: clusterId,
    filter: globalFilter,
  };

  const { data: nonVehicleData, isFetching: isFetchingClusterNonVehicleData } = useClusterNonVehicleDetailsQuery({
    id: clusterId,
    customerId: clusterQueryParams.customerId,
  });
  const clusterNonVehicleData = nonVehicleData?.clusters?.clusterNonVehicleDetails;

  const { data: clusterData, isLoading, isFetching } = useClusterDetailsQuery(clusterQueryParams);
  const cluster = clusterData?.clusters?.clusters?.data[0];

  const { isFetching: isFetchingVehiclesData, data } = useVehicleMetadataQuery({
    filter: globalFilter,
    clusterId: clusterId,
    pagination,
  });

  const maintenanceHistoryIdsPresent = data?.filteredQuery?.clusterMetadata?.data.some(
    (metadata) => metadata.maintenanceHistoryId
  );

  const invalidateQueriesAndResetPagination = async () => {
    await diagnosticCheckNavigate({
      search: (prev) => ({ ...prev, diagnosticChecksPage: 1, faultEventsPage: 1 }),
    });
    queryClient.invalidateQueries({
      queryKey: useClusterNonVehicleDetailsQuery.getKey({
        id: clusterId,
        customerId: getCurrentCustomerId() ?? "",
      }),
    });
    queryClient.invalidateQueries({
      queryKey: useClusterDetailsQuery.getKey(clusterQueryParams),
    });
    queryClient.invalidateQueries({
      queryKey: useAttributeCountQuery.getKey({
        clusterId: cluster?.id ?? "",
        filter: globalFilter,
        attribute: AttributeTypeEnum.ModelType,
      }),
    });
    queryClient.invalidateQueries({
      queryKey: useDistanceVersusFailureCountQuery.getKey({ clusterId: cluster?.id ?? "", filter: globalFilter }),
    });
    queryClient.invalidateQueries({
      queryKey: useFaultsByPvinsQuery.getKey({
        clusterId: cluster?.id ?? "",
        filter: globalFilter,
        pagination,
      }),
    });
    queryClient.invalidateQueries({
      queryKey: useVehicleMetadataQuery.getKey({
        filter: globalFilter,
        clusterId: cluster?.id || "",
        pagination,
      }),
    });
    queryClient.invalidateQueries({
      queryKey: useListClustersQuery.getKey({
        customerId: getCurrentCustomerId() ?? "",
        filter: globalFilter,
      }),
    });
  };

  const handleDeleteActivePvins = () => {
    if (selectedPvins.length === cluster?.combinationCount) {
      api.warning({
        message: `If you want to delete all the ${vinLabel}s, delete the cluster`,
      });
      return;
    }

    const pvinsToDelete: ClusterRedshiftCreateInput[] = selectedPvins.map(({ pvin, startDate, endDate, vin }) => {
      return {
        pvin: isFeatureEnabled(FeatureId.Vins) ? vin! : pvin,
        startDate,
        endDate,
      };
    });

    handleDeletePvins(pvinsToDelete, false);
  };

  const handleDeleteMissingPvins = (pvinsToDelete: ClusterRedshiftCreateInput[]) => {
    handleDeletePvins(pvinsToDelete, true);
  };

  const handleDeletePvins = (pvinsToDelete: ClusterRedshiftCreateInput[], ignoreDateRanges: boolean) => {
    setIsDeletingPvins(true);
    updateCluster.mutate(
      {
        customerId: getCurrentCustomerId() ?? "",
        input: pvinsToDelete,
        clusterId,
        version: cluster?.version || 0,
        action: ClusterUpdateAction.RemovePvins,
        ignoreDateRanges,
      },
      {
        onSuccess: (data) => {
          const cluster = data.clusters?.updateClusterPvins;
          invalidateQueriesAndResetPagination();
          if (cluster) {
            api.success({ message: "Cluster updated successfully" });
          }
          setSelectedPvins([]);
          setModalView(undefined);
        },
        onSettled: () => {
          setIsDeletingPvins(false);
        },
      }
    );
  };

  const handleAddPvins = () => setModalView("addVehiclesToCluster");

  const handleCopyCluster = () => {
    setModalView("copyCluster");
  };

  const handleEditCluster = () => {
    setModalView("editCluster");
  };

  const rowSelection = {
    onChange: (_newRowKeys: Key[], newSelectedPvins: ClusterMetadata[]) => {
      setSelectedPvins(newSelectedPvins);
    },
  };

  if (isLoading) {
    return <Loading />;
  }

  const isReadOnly = !!(cluster && !cluster.creator);

  return (
    <>
      {context}
      {!isLoading && !cluster && <Page404 message="Cluster not found" />}
      {!isLoading && cluster && clusterNonVehicleData && (
        <ClusterViewWrapper>
          <ClusterViewHeader
            clusterNonVehicleData={clusterNonVehicleData}
            handleCopyCluster={handleCopyCluster}
            handleEditCluster={handleEditCluster}
            isLoading={isFetchingClusterNonVehicleData}
          />
          <Row gutter={[24, 24]} style={{ marginTop: "24px", marginBottom: "24px" }}>
            <Col span={12}>
              <ClusterDistanceVsFailureCount
                setAffectedPvins={setAffectedPvins}
                setModalView={setModalView}
                setLoading={setPvinsLoading}
              />
            </Col>
            <Col span={12}>
              <ClusterAttributeCount
                setAffectedPvins={setAffectedPvins}
                setModalView={setModalView}
                setLoading={setPvinsLoading}
              />
            </Col>
          </Row>
          <Row gutter={[24, 24]} style={{ marginTop: "24px", marginBottom: "24px" }}>
            <Col span={24}>
              <FaultEventsWidget
                cluster={cluster}
                setAffectedPvins={setAffectedPvins}
                setModalView={setModalView}
                setLoading={setPvinsLoading}
              />
            </Col>
            <Col span={24}>
              <BasicWidget
                title={
                  <ClusterViewVehicleTable>
                    <TableTitle>
                      <div className="TableTitle-Title">{i18next.t("tableTitle.DIAGNOSTIC_CHECKS")}</div>
                      <Popover
                        className="TableTitle-Info"
                        title={<h3>How {i18next.t("tableTitle.DIAGNOSTIC_CHECKS")} impact Cluster View</h3>}
                        content={
                          <div style={{ width: 300 }}>
                            <p>
                              {i18next.t("tableTitle.DIAGNOSTIC_CHECKS")} are used to filter and group fault codes to
                              display relevant data in the charts and tables on this page. Based on how the{" "}
                              {i18next.t("tableTitle.DIAGNOSTIC_CHECKS")} are grouped, they might not align with the
                              workshop start and end dates.
                            </p>
                            <p>
                              If we cannot find the correct {i18next.t("tableTitle.DIAGNOSTIC_CHECKS")} for a vehicle,
                              it will be displayed as &quot;-&quot; and the data will be omitted from the charts and
                              tables on this page.
                            </p>
                          </div>
                        }
                      >
                        <QuestionCircleOutlined />
                      </Popover>
                    </TableTitle>
                    <VehicleHelperButtons>
                      {cluster.missingPvins.length > 0 && (
                        <Button
                          type={"text"}
                          onClick={() => {
                            setModalView("viewMissingPvins");
                            setAffectedPvins(
                              cluster.missingPvins.map((pvin) => ({
                                id: pvin,
                                vin: pvin,
                                model: "",
                                modelStyle: "",
                                modelYear: 0,
                              }))
                            );
                          }}
                        >
                          {cluster.missingPvins.length} Missing {vinLabel}(s)
                        </Button>
                      )}
                      <Button loading={isDeletingPvins} onClick={handleAddPvins} disabled={isFetching || isReadOnly}>
                        Add {vinLabel}s
                      </Button>
                      {!!selectedPvins.length && (
                        <Popconfirm
                          title={
                            selectedPvins.length > 1
                              ? `Are you sure you want to delete these ${vinLabel}s from the cluster?`
                              : `Are you sure you want to delete this ${vinLabel} from the cluster?`
                          }
                          okText="Yes"
                          cancelText="No"
                          onConfirm={handleDeleteActivePvins}
                          disabled={isDeletingPvins}
                        >
                          <Button loading={isDeletingPvins} disabled={isReadOnly}>
                            Remove {vinLabel}s ({selectedPvins.length})
                          </Button>
                        </Popconfirm>
                      )}
                    </VehicleHelperButtons>
                  </ClusterViewVehicleTable>
                }
              >
                <BasicTable<ClusterMetadata>
                  rowSelection={
                    !isReadOnly
                      ? {
                          type: "checkbox",
                          columnWidth: 48,
                          ...rowSelection,
                        }
                      : undefined
                  }
                  pagination={{
                    defaultPageSize: DEFAULT_PAGE_SIZE,
                    total: data?.filteredQuery?.clusterMetadata?.pagination.totalCount,
                    current: pagination.currentPage,
                    onChange: handlePaginationChange,
                    showSizeChanger: false,
                  }}
                  rowKey={(v) => `${v.pvin}-${v.startDate}-${v.endDate}`}
                  loading={isFetchingVehiclesData}
                  columns={[
                    {
                      key: "maintenanceHistoryId",
                      title: i18next.t("columnTitle.REPAIR_EVENT_ID"),
                      dataIndex: "maintenanceHistoryId",
                      hidden: !maintenanceHistoryIdsPresent,
                      render: (_, metadata) => metadata.maintenanceHistoryId || "-",
                    },
                    areVinsEnabled
                      ? {
                          key: "vin",
                          title: "VIN",
                          dataIndex: "vin",
                          render: (_: any, row: ClusterMetadata) => {
                            return (
                              <Link to="/vehicle/$pvin" params={{ pvin: row.pvin }}>
                                {row.vin}
                              </Link>
                            );
                          },
                        }
                      : {
                          key: "pvin",
                          title: "PVIN",
                          dataIndex: "pvin",
                          render: (_: any, row: ClusterMetadata) => {
                            return (
                              <Link to="/vehicle/$pvin" params={{ pvin: row.pvin }}>
                                {row.pvin}
                              </Link>
                            );
                          },
                        },
                    {
                      key: "modelYear",
                      title: "Model Year",
                      dataIndex: "modelYear",
                    },
                    {
                      key: "modelType",
                      title: "Model Style",
                      dataIndex: "modelType",
                      render: (_, metadata) => {
                        if (!metadata.modelType) return "-";
                        return (
                          toModelDescription(
                            {
                              model: metadata.model ?? "",
                              modelStyle: metadata.modelStyle ?? "",
                            },
                            customerIdentifier.models
                          ) ?? "-"
                        );
                      },
                    },
                    {
                      key: "startDate",
                      title: "Workshop Start Date",
                      dataIndex: "startDate",
                      render: (_: any, row: ClusterMetadata) => {
                        return row.startDate ? parseDateToStr(new Date(row.startDate), false, true) : "N/A";
                      },
                    },
                    {
                      key: "endDate",
                      title: "Workshop End Date",
                      dataIndex: "endDate",
                      render: (_: any, row: ClusterMetadata) => {
                        return row.endDate ? parseDateToStr(new Date(row.endDate), false, true) : "N/A";
                      },
                    },
                    {
                      key: "componentLocationId",
                      title: `${i18next.t("columnTitle.COMPONENT_LOCATION_ID")}`,
                      dataIndex: "componentLocationId",
                      hidden: !maintenanceHistoryIdsPresent,
                      render: (_, metadata) => metadata.componentLocationId || "-",
                    },
                    {
                      key: "diagnosticCheckId",
                      title: i18next.t("columnTitle.DIAGNOSTIC_CHECK_ID"),
                      dataIndex: "diagnosticCheckId",
                      render: (_, metadata) => metadata.diagnosticCheckId || "-",
                    },
                  ]}
                  dataSource={data?.filteredQuery?.clusterMetadata.data ?? []}
                />
              </BasicWidget>
            </Col>
          </Row>

          <ClusterModal
            isOpen={
              (!!affectedPvins && modalView === "vehiclesAffected") ||
              modalView === "addVehiclesToCluster" ||
              modalView === "viewMissingPvins" ||
              (pvinsLoading && modalView === "vehiclesAffected") ||
              modalView === "copyCluster" ||
              modalView === "editCluster"
            }
            onCancel={() => {
              setAffectedPvins(undefined);
              setModalView("vehiclesAffected");
              setPvinsLoading(false);
            }}
            api={api}
            initialView={modalView}
            pvins={affectedPvins}
            clusterId={clusterNonVehicleData.id}
            clusterVersion={clusterNonVehicleData.version}
            clusterName={clusterNonVehicleData.name}
            clusterDescription={clusterNonVehicleData.description}
            handleSuccess={invalidateQueriesAndResetPagination}
            handleDelete={handleDeleteMissingPvins}
            isLoading={pvinsLoading}
          />
        </ClusterViewWrapper>
      )}
    </>
  );
};
