import { Card } from "antd";
import { useMemo } from "react";
import { Provider as EventAnalysisChartProvider } from "react-redux";

import { FeatureId, TimeSeriesField, TimeSeriesFieldsQuery, useTimeSeriesFieldsQuery } from "@/api";
import Loading from "@/components/loading";
import { EVENT_ANALYSIS_CHART, QUERY_SETTINGS } from "@/constants";
import { getDefaultEventAnalysisChartState } from "@/contexts/EventAnalysisChartSlice";
import configureEventAnalysisChartStore, { EventAnalysisState } from "@/contexts/EventAnalysisChartStore";
import { useEventAnalysisParams } from "@/hooks/useEventAnalysisParams";
import { stateParser } from "@/hooks/useStateToExternalSource";
import { getCurrentCustomerId } from "@/utils/customers";
import { useFeatureFlags } from "@/utils/features";
import { getFromLocalStorage, removeFromLocalStorage } from "@/utils/localStorageUtils";
import { getQueryParam } from "@/utils/urlUtils";

import { externalLibraryStateShapeIsValid } from "../vehicleDetails/ChartLibraryWrapper";
import { EventAnalyzerProps } from "./EventAnalyzer";
import EventAnalyzerContent from "./EventAnalyzerContent";

const validateState = (state: EventAnalysisState | undefined, data: TimeSeriesFieldsQuery | undefined) => {
  if (!data) return;
  let validated = true;
  if (state) {
    const availableTimeSeriesFields: Record<string, TimeSeriesField> = {};
    data.timeSeriesFields.forEach((timeSeries) => (availableTimeSeriesFields[timeSeries.id] = timeSeries));
    for (const signal of state.signals) {
      if (!validated) return;
      if (!availableTimeSeriesFields[signal.id]) {
        removeFromLocalStorage(EVENT_ANALYSIS_CHART);
        validated = false;
        return;
      }
    }
    return state;
  }
  return undefined;
};

const EventAnalysisLoading = () => (
  <Card className="card-pullsystems-shadow">
    <Loading height={256} />
  </Card>
);

const EventAnalyzerStateWrapper = (props: EventAnalyzerProps) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const eventId = useEventAnalysisParams();
  const { data, isLoading } = useTimeSeriesFieldsQuery(
    { customerId: getCurrentCustomerId() ?? "" },
    { staleTime: QUERY_SETTINGS.LONG_STALE_TIME, enabled: isFeatureEnabled(FeatureId.Telemetry) }
  );

  const eventAnalysisChartStore = useMemo(() => {
    if (isLoading && isFeatureEnabled(FeatureId.Telemetry)) return;
    // Get state from URL first, then localstorage
    const serializedState = getQueryParam(EVENT_ANALYSIS_CHART) || getFromLocalStorage(EVENT_ANALYSIS_CHART);
    // Pass state to ChartLibraryStore, if state ends up being invalid then the store will use a hardcoded default
    const parsedState = serializedState ? stateParser(serializedState) : undefined;
    const initialState = externalLibraryStateShapeIsValid(parsedState, getDefaultEventAnalysisChartState())
      ? (parsedState as EventAnalysisState)
      : undefined;
    const maybeValidatedState = validateState(initialState, data);
    // Pass state to eventAnalysisChartStore, if state ends up being undefined then the store will use a hardcoded default
    const validatedState = maybeValidatedState ? maybeValidatedState : getDefaultEventAnalysisChartState();
    return configureEventAnalysisChartStore(validatedState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, eventId]);

  if ((isLoading && isFeatureEnabled(FeatureId.Telemetry)) || !eventAnalysisChartStore) {
    return <EventAnalysisLoading />;
  }

  return (
    <EventAnalysisChartProvider store={eventAnalysisChartStore}>
      <EventAnalyzerContent {...props} />
    </EventAnalysisChartProvider>
  );
};

export default EventAnalyzerStateWrapper;
