import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { Button, Form, Input } from "antd";
import { NotificationInstance } from "antd/es/notification/interface";
import { useState } from "react";

import { ErrorKey, useClusterNonVehicleDetailsQuery, useEditClusterMutation } from "@/api";
import { GraphQLErrorException } from "@/hooks/useApiFetcher";
import { getCurrentCustomerId } from "@/utils/customers";
import ApiError from "@/utils/errors/ApiError";

type EditClusterProps = {
  api: NotificationInstance;
  clusterId: string;
  clusterName: string | undefined;
  clusterDescription: string | undefined | null;
  version: number;
  onSuccess: () => void;
  onCancel: () => void;
};

type FormField = {
  name: string;
  description: string;
};

export const EditCluster = ({
  api,
  clusterId,
  clusterName,
  clusterDescription,
  version,
  onCancel,
}: EditClusterProps) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [isEditingCluster, setIsEditingCluster] = useState(false);

  const copyCluster = useEditClusterMutation({
    retry: (failureCount, error: ApiError) => {
      const exception = error.cause?.extensions?.exception as GraphQLErrorException | undefined;
      const errorCode: string = exception?.errorCode ?? ErrorKey.GenericError;
      if (errorCode === ErrorKey.UniquenessConstraint) {
        return false;
      }
      return failureCount < 3; // Retry up to 3 times for other errors
    },
  });
  const navigate = useNavigate();

  const handleClose = () => {
    form.resetFields();
    onCancel();
  };

  const handleCopy = (args: FormField) => {
    setIsEditingCluster(true);
    copyCluster.mutate(
      {
        clusterId,
        customerId: getCurrentCustomerId() ?? "",
        name: args.name,
        description: args.description,
        version,
      },
      {
        onSuccess: (data) => {
          const clusterId = data.clusters?.editCluster?.id;
          if (clusterId) {
            queryClient.invalidateQueries({
              queryKey: useClusterNonVehicleDetailsQuery.getKey({
                id: clusterId,
                customerId: getCurrentCustomerId() ?? "",
              }),
            });
            api.success({ message: "Cluster editied succesfully" });
            handleClose();
            navigate({ to: "/cluster/$clusterId", params: { clusterId: clusterId } });
          }
        },
        onSettled: () => {
          setIsEditingCluster(false);
        },
      }
    );
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="basic"
      onFinish={(v: FormField) => {
        handleCopy(v);
      }}
      autoComplete="off"
      initialValues={{
        name: clusterName,
        description: clusterDescription,
      }}
    >
      <Form.Item<FormField>
        label={"Name"}
        name={"name"}
        rules={[{ required: true, message: "Please input a new name for the cluster!" }]}
      >
        <Input placeholder="Enter a new name for the new cluster" />
      </Form.Item>
      <Form.Item<FormField> label={"Description"} name={"description"}>
        <Input placeholder="Enter a new description for the new cluster" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isEditingCluster}>
          Edit Cluster
        </Button>
      </Form.Item>
    </Form>
  );
};
