import styled from "styled-components";

export const LibraryActiveChartContainer = styled.div`
  width: 100%;
  .library-active-chart {
    width: calc(100% - 2rem);
  }
`;

export const LibraryActiveChartOverlay = styled.div`
  height: 100%;
  background-color: #dedede99;
  display: flex;
  padding: 2rem;
  justify-content: center;
  height: max-content;
  &:hover {
    cursor: pointer;
  }
`;
