import { BinaryExpression, Expression } from "@lib/src/expression/types";
import { isEmpty, isEqual } from "lodash";
import { KeyboardEvent, useContext } from "react";
import { PartialDeep } from "type-fest";

import { PatternFocusContext } from "@/contexts/PatternFocusContext";

import { getBinrayOperatorSymbol } from "../TypeaheadUtils";
import NodeWrapper from "./NodeWrapper";
import { PatternFocusedNode } from "./PatternFocusedNode";
import PatternNode from "./PatternNode";
import { PatternNodeContainer } from "./PatternNode.styled";

type PatternBinaryNodeProps = {
  node: BinaryExpression;
  path: string[];
  onChange: (newValue: PartialDeep<Expression>, path: string[]) => void;
  disabled: boolean;
};

const PatternBinaryNode = ({ node, path, onChange, disabled }: PatternBinaryNodeProps) => {
  const { mostRecentPath } = useContext(PatternFocusContext);

  const nodeClassname = `pattern-node${node.type ? ` ${node.type.toLowerCase()}` : ""}`;
  const operationSymbol = getBinrayOperatorSymbol(node.type);

  const handleDelete = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.code === "Backspace" && (e?.target as HTMLInputElement).value === "") {
      const remainingValues = node.values.filter((v) => Object.keys(v).length !== 0);
      if (remainingValues.length === 0) {
        onChange({}, path);
      } else if (remainingValues.length === 1) {
        onChange(remainingValues[0], path);
      }
    }
  };

  const areBinaryValuesInvalid = (valueIndex: number) => {
    const isMostRecentPathInFamily =
      isEqual(mostRecentPath, [...path, "values", "0"]) ||
      isEqual(mostRecentPath, [...path, "values", "1"]) ||
      isEqual(mostRecentPath, path);
    if (isMostRecentPathInFamily) {
      return;
    } else {
      return isEmpty(node.values[valueIndex]) ? "error" : undefined;
    }
  };

  return (
    <PatternFocusedNode node={node} path={path} onChange={onChange} disabled={disabled}>
      <NodeWrapper>
        <PatternNodeContainer className={nodeClassname}>
          <PatternNode
            onNodeChange={onChange}
            path={[...path, "values", "0"]}
            node={node.values[0]}
            deleteFunction={handleDelete}
            disabled={disabled}
            status={areBinaryValuesInvalid(0)}
          />
          <span className="operator-symbol">{operationSymbol}</span>
          <PatternNode
            onNodeChange={onChange}
            path={[...path, "values", "1"]}
            node={node.values[1]}
            deleteFunction={handleDelete}
            disabled={disabled}
            status={areBinaryValuesInvalid(1)}
          />
        </PatternNodeContainer>
      </NodeWrapper>
    </PatternFocusedNode>
  );
};

export default PatternBinaryNode;
