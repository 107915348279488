import { QuestionCircleOutlined } from "@ant-design/icons";
import { validateExpression } from "@lib/src/expression/expressionValidator";
import { expressionReferencedTimeSeriesFields } from "@lib/src/expression/referencedTimeSeriesFields";
import { Expression } from "@lib/src/expression/types";
import { isNotUndefined } from "@lib/src/isNotUndefined";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { Card, Flex, Form, Input, Modal, notification, Row, Spin, Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTheme } from "styled-components";

import {
  Dependency,
  useCreateSavedExpressionMutation,
  useDeleteSavedExpressionMutation,
  useGetSavedExpressionByIdQuery,
  useGetSavedExpressionsQuery,
  useSavedExpressionsQuery,
  useTimeSeriesFieldsQuery,
  useUpdateSavedExpressionMutation,
} from "@/api";
import { AfterCreate } from "@/features/anomaly/editor/AfterCreate";
import PatternEditorBoard from "@/features/pattern/PatternEditorBoard";
import { requireCurrentCustomerId } from "@/utils/customers";
import { humanFriendlyStatuses, isPatternCalculating } from "@/utils/patterns";

import { showModalDeleteDependencies } from "../../components/dependency/DependencyModal";
import Loading from "../../components/loading";
import { TopHeadingWithButtonsForAnomalyAndPattern } from "../../components/pageContent/TopHeadingWithButtonsForAnomalyAndPattern";
import { createPattern } from "./createPattern";
import { useFindUnavailableDefinitions } from "./hooks/useFindUnavailableDefinitions";
import { usePatternTreeBuilder } from "./hooks/usePatternTreeBuilder";
import { DefinitionHeaderContainer } from "./PatternEditor.styled";
import { PatternPreview } from "./PatternPreview/PatternPreview";
import { SaveAsModal } from "./SaveAsModal";

export type PatternEditorProps = {
  patternId?: string;
  afterCreate?: AfterCreate;
};

export type FormValues = {
  name: string;
  description: string | null | undefined;
};

export const PatternEditor = (props: PatternEditorProps) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [modal, contextHolder] = Modal.useModal();

  const [form] = Form.useForm<FormValues>();
  const { patternId, afterCreate } = props;
  const { rootExpression, editExpression } = usePatternTreeBuilder();

  const [submittable, setSubmittable] = useState(false);
  const [formulaValid, setFormulaValid] = useState(false);
  const [isSaveAsModalVisible, setIsSaveAsModalVisible] = useState(false);

  const {
    data,
    refetch,
    isLoading: isLoadingGetExpression,
  } = useGetSavedExpressionByIdQuery(
    {
      customerId: requireCurrentCustomerId(),
      expressionId: patternId!,
    },
    { enabled: !!patternId }
  );

  const expression = data?.savedExpressions?.savedExpression;

  const values = Form.useWatch([], form);

  useEffect(() => {
    editExpression({}, []);
  }, []);

  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(() => setSubmittable(formulaValid))
      .catch(() => setSubmittable(false));
  }, [form, values, formulaValid]);

  useEffect(() => {
    if (expression) {
      resetToBlankOrLastSaved();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expression]);

  const [notificationApi, notificationContextHolder] = notification.useNotification();

  const createSavedExpressionMutation = useCreateSavedExpressionMutation();
  const updateSavedExpressionMutation = useUpdateSavedExpressionMutation();
  const deleteSavedExpressionMutation = useDeleteSavedExpressionMutation();

  const isLoading =
    (!!patternId && isLoadingGetExpression) ||
    createSavedExpressionMutation.isLoading ||
    updateSavedExpressionMutation.isLoading;

  const isDeleting = deleteSavedExpressionMutation.isLoading;

  const navigate = useNavigate();

  const invalidateQueries = () => {
    invalidateSavedExpressionQuery();
    invalidateTimeSeriesFieldsQuery();
    invalidateSavedExpressionOptions();
    invalidateExpressionById();
  };

  const invalidateSavedExpressionOptions = () => {
    queryClient.invalidateQueries({
      queryKey: useSavedExpressionsQuery.getKey({
        customerId: requireCurrentCustomerId(),
      }),
    });
  };

  const invalidateSavedExpressionQuery = () => {
    queryClient.invalidateQueries({
      queryKey: useGetSavedExpressionsQuery.getKey({
        customerId: requireCurrentCustomerId(),
      }),
    });
  };

  const invalidateTimeSeriesFieldsQuery = () => {
    queryClient.invalidateQueries({
      queryKey: useTimeSeriesFieldsQuery.getKey({
        customerId: requireCurrentCustomerId(),
      }),
    });
  };

  const invalidateExpressionById = () => {
    queryClient.invalidateQueries({
      queryKey: useGetSavedExpressionByIdQuery.getKey({
        customerId: requireCurrentCustomerId(),
        expressionId: patternId!,
      }),
    });
  };

  const { patternsAreNotAvailable } = useFindUnavailableDefinitions({ rootExpression });

  const onCreateExpression = async () => {
    const formValues = form.getFieldsValue();

    return createPattern(
      formValues.name,
      formValues.description,
      rootExpression as Expression,
      createSavedExpressionMutation,
      notificationApi,
      setIsSaveAsModalVisible,
      afterCreate
    )
      .then(invalidateQueries)
      .then(() => navigate({ to: "/pattern" }));
  };

  const onCancelOperation = () => {
    invalidateQueries();
    navigate({ to: "/pattern" });
  };

  const onUpdateExpression = async () => {
    const formValues = form.getFieldsValue();
    try {
      validateExpression(rootExpression as Expression);
      updateSavedExpressionMutation.mutate(
        {
          customerId: requireCurrentCustomerId(),
          expression: {
            name: formValues.name ?? "",
            description: formValues.description ?? "",
            definition: rootExpression as Expression,
          },
          id: expression!.id,
          version: expression!.version,
        },
        {
          onSuccess(data) {
            notificationApi.success({
              message: `Pattern ${data.savedExpressions!.updateSavedExpression.name} updated.`,
            });
            invalidateQueries();
            refetch();
          },
        }
      );
    } catch (e) {
      notificationApi.error({ message: "Invalid pattern." });
    }
  };

  const onDeleteExpression = async () => {
    try {
      if (!expression?.creator?.isMe) return;
      deleteSavedExpressionMutation.mutate(
        {
          customerId: requireCurrentCustomerId(),
          id: patternId!,
          version: data!.savedExpressions!.savedExpression!.version,
        },
        {
          onSuccess({ savedExpressions }) {
            const dependencies = savedExpressions?.deleteSavedExpression.dependencies;
            if (dependencies?.length) {
              showModalDeleteDependencies(
                modal,
                dependencies as Dependency[],
                "It can't be deleted",
                "Sorry, this pattern has dependencies."
              );
            } else {
              notificationApi.success({ message: `Pattern ${expression!.name} deleted.` });
              invalidateQueries(); // Invalidate all queries
              navigate({ to: "/pattern" });
            }
          },
        }
      );
    } catch (e) {
      notificationApi.error({ message: "Invalid pattern" });
    }
  };

  const isReadOnly = isLoading || (expression && (expression.readOnly || !expression.creator?.isMe)) || false;

  const handleExpressionChange = (valid: boolean) => setFormulaValid(valid);

  const resetToBlankOrLastSaved = () => {
    editExpression(expression?.definition ?? {}, []);
    form.setFieldValue("formula", expression?.definition ?? "");
    form.setFieldValue("name", expression?.name ?? "");
    form.setFieldValue("description", expression?.description ?? "");
  };

  const currentF = rootExpression ? expressionReferencedTimeSeriesFields(rootExpression as Expression) : [];

  //TODO: currentInputIds in this case excludes signals, because i cant convert them to fully qualified names in frontend; but since they cannot be circular, i dont need them here
  const currentInputIds = currentF.map((f) => (f.type != "Signal" ? f.value : undefined)).filter(isNotUndefined);

  const isSubmitButtonDisable = isDeleting || isLoading || !submittable;

  const isPatternLoading = () => {
    if (!patternId) return false;
    return isLoadingGetExpression;
  };

  return (
    <Flex vertical gap={"1rem"}>
      {contextHolder}
      {isDeleting && <Spin fullscreen />}
      <TopHeadingWithButtonsForAnomalyAndPattern
        isNew={!patternId}
        isOwnedByMe={expression?.creator?.isMe || false}
        isCalculating={isPatternCalculating(expression?.status!)}
        itemType="Pattern"
        hideButtons={isLoadingGetExpression && !!patternId}
        onSaveAs={() => setIsSaveAsModalVisible(true)}
        onCreate={onCreateExpression}
        onDelete={onDeleteExpression}
        onUpdate={onUpdateExpression}
        onCancel={onCancelOperation}
        {...{ isLoading, isReadOnly, isDisabled: isSubmitButtonDisable }}
      />
      <Row>
        <Card
          title={
            <Flex vertical={false} justify="space-between" align="center">
              <span>Pattern Definition</span>
              {expression && (
                <Typography.Text type="secondary">Status: {humanFriendlyStatuses(expression?.status)}</Typography.Text>
              )}
            </Flex>
          }
          bordered={false}
          style={{ width: "100%" }}
        >
          <Form layout="vertical" form={form}>
            <Flex gap={8} vertical>
              <DefinitionHeaderContainer>
                <Form.Item
                  required
                  className="form-name-input"
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the pattern name",
                    },
                  ]}
                >
                  <Input placeholder="name" disabled={isReadOnly ?? false} name="name" />
                </Form.Item>
                <Form.Item className="form-description-input" label="Description" name="description">
                  <Input placeholder="description" disabled={isReadOnly ?? false} name="description" />
                </Form.Item>
              </DefinitionHeaderContainer>
              <div>{isPatternLoading() ? <Loading /> : <PatternEditorBoard disabled={isReadOnly} />}</div>
            </Flex>
          </Form>
          {expression && (
            <SaveAsModal
              title="Save Pattern As..."
              originalData={{
                name: expression.name,
                description: expression.description ?? "",
              }}
              isVisible={isSaveAsModalVisible}
              onOk={(expression) =>
                createPattern(
                  expression.name,
                  expression.description,
                  rootExpression as Expression,
                  createSavedExpressionMutation,
                  notificationApi,
                  setIsSaveAsModalVisible,
                  afterCreate
                ).then(invalidateQueries)
              }
              onCancel={() => setIsSaveAsModalVisible(false)}
              isLoading={createSavedExpressionMutation.isLoading}
            />
          )}
          {notificationContextHolder}
        </Card>
      </Row>
      <Row>
        <Card
          title={
            <div>
              Preview{" "}
              <Tooltip
                title="The preview charts are generated using a subset of vehicle data to help you refine your pattern"
                placement="topLeft"
              >
                <QuestionCircleOutlined style={{ color: theme.colors.blueGray, fontSize: 16 }} />
              </Tooltip>
            </div>
          }
          bordered={false}
          style={{ height: "100%", width: "100%" }}
        >
          <PatternPreview
            patternId={patternId}
            currentInputIds={currentInputIds}
            patternDefinition={patternsAreNotAvailable ? {} : rootExpression}
            onPatternChange={handleExpressionChange}
          />
        </Card>
      </Row>
    </Flex>
  );
};
