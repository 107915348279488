import { createFileRoute } from "@tanstack/react-router";

import { RepairEvents } from "@/features/repairEvents/RepairEvents";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

export type RepairEventsSearch = {
  repairEventsPage?: number;
};
export const Route = createFileRoute("/repair-events/")({
  component: () => (
    <AppMainLayout>
      <RepairEvents />
    </AppMainLayout>
  ),
  beforeLoad: () => setTitleBeforeLoad("Repair Events"),
  validateSearch: (search: Record<string, unknown>): RepairEventsSearch => ({
    repairEventsPage: (search.repairEventsPage as number) || undefined,
  }),
});
