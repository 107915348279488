import { EChartsReactProps } from "echarts-for-react";

import { BaseEChart } from "@/components/ui/BaseEChart";
import { buildTimeSeriesChartConfig } from "@/features/chartElementLibrary/ChartLibraryBuildUtils";

import { DescriptionContainer } from "./PatternPreview.styled";

type PatternTimeSeriesPreviewPreviewProps = {
  loading: boolean;
  data?: {
    x: number[];
    y: (number | null)[];
    min: number[];
    max: number[];
    vehicleCount: number[];
  };
};

export const PatternTimeSeriesPreview = (props: PatternTimeSeriesPreviewPreviewProps) => {
  const { loading, data: { x = [], y = [], min = [], max = [], vehicleCount = [] } = {} } = props;
  const chartConfig: EChartsReactProps = buildTimeSeriesChartConfig(x, y, min, max, vehicleCount);
  return (
    <div className="preview-section">
      <h3>
        <b>Time Series</b>
        <DescriptionContainer>
          The average value and standard deviation of vehicles within each kilometer range.
        </DescriptionContainer>
      </h3>
      <BaseEChart {...chartConfig} style={{ height: 500 }} showLoading={loading} notMerge />
    </div>
  );
};
