import styled from "styled-components";

export const ChartToolboxContainer = styled.div`
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .plotted-elements-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    .signal-element {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.3rem;
    }
  }
  .chart-tools-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    svg {
      width: 1rem;
    }
    button {
      font-size: 10px;
    }
  }
`;

export const ChartSerieslegendContainer = styled.div.attrs({ className: "series-legend-container" })`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  .signal-element {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.3rem;
  }
`;

export const PlottedSignalIconContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.1rem;
  .signal-icon {
    width: 1rem;
    height: 0.25rem;
    border-radius: 0.25rem;
  }
`;

export const PlottedElementIcon = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  &.fault-icon {
    background-color: ${({ theme }) => theme.colors.accentBlue};
  }
  &.repair-icon {
    width: 0;
    height: 0;
    border-radius: unset;
    border-left: 0.35rem solid transparent;
    border-right: 0.35rem solid transparent;
    border-top: 0.6rem solid ${({ theme }) => theme.colors.backgroundHover};
  }
`;
