import { Expression } from "@lib/src/expression/types";
import { isEqual } from "lodash";
import {
  KeyboardEvent as ReactKeyboardEvent,
  MouseEvent as ReactMouseEvent,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { PartialDeep } from "type-fest";

import { PatternFocusContext } from "@/contexts/PatternFocusContext";

import { setFocusedNode } from "../setFocusedNode";
import TypeaheadInput from "../TypeaheadInput";
import { getNodeByOperationSymbol } from "../TypeaheadUtils";
import { PatternFocusedNodeContainer } from "./PatternFocusedNode.styled";

type PatternFocusedNodeProps = {
  node: PartialDeep<Expression>;
  path: string[];
  onChange: (newValue: PartialDeep<Expression>, path: string[]) => void;
  disabled: boolean;
};

const varCharRegex = /^[a-zA-Z0-9]+$/;
export const PatternFocusedNode = ({
  node,
  path,
  onChange,
  children,
  disabled,
}: PropsWithChildren<PatternFocusedNodeProps>) => {
  const { mostRecentPath, setMostRecentPath, setFocusRootNode, focusRootNode } = useContext(PatternFocusContext);
  const focusedRef = useRef<HTMLDivElement>(null);
  const nodeRef = useRef<any>(null);

  const [focused, setFocused] = useState(false);
  const [typeahead, setTypeahead] = useState(false);

  const className = `pattern-node${focused ? " node-is-focused" : ""}`;

  useEffect(() => {
    return () => {
      setMostRecentPath([...path]);
      removeWindowListeners();
    };
  }, []);

  useEffect(() => {
    nodeRef.current = node;
  }, [node]);

  useEffect(() => {
    if (focusRootNode) {
      setFocused(true);
      setFocusRootNode(false);
    }
  }, [focusRootNode]);

  useEffect(() => {
    if (!disabled && isEqual(mostRecentPath, path)) {
      if (!typeahead) {
        const didChangePath = setFocusedNode(
          node,
          focused,
          typeahead,
          path,
          mostRecentPath,
          setMostRecentPath,
          focusRootNode
        );
        if (!didChangePath) {
          focusedRef.current?.focus();
        }
      }
    } else {
      setFocused(false);
      setTypeahead(false);
      focusedRef.current?.blur();
    }
  }, [mostRecentPath, path, disabled]);

  const addClickEventListener = () => {
    window.removeEventListener("mousedown", handleClickOutside);
    window.addEventListener("mousedown", handleClickOutside);
  };

  const addKeyDownEventListener = () => {
    window.removeEventListener("keydown", handlePatternNodeContainerKeyDown);
    window.addEventListener("keydown", handlePatternNodeContainerKeyDown);
  };

  const removeWindowListeners = () => {
    window.removeEventListener("keydown", handlePatternNodeContainerKeyDown);
    window.removeEventListener("mousedown", handleClickOutside);
  };

  useEffect(() => {
    if (!disabled && focused && !typeahead) {
      addClickEventListener();
      addKeyDownEventListener();
    } else if (!disabled && !focused && typeahead) {
      window.removeEventListener("keydown", handlePatternNodeContainerKeyDown);
      focusedRef.current?.blur();
    } else if (disabled || (!typeahead && !focused && !isEqual(mostRecentPath, path))) {
      removeWindowListeners();
      focusedRef.current?.blur();
    }
  }, [focused, typeahead, disabled]);

  const handleEntityFirstTyping = (newValue: PartialDeep<Expression>, path: string[]) => {
    if ("values" in newValue && newValue.values) {
      const n = { ...newValue };
      n.values = [...newValue.values];
      n.values[0] = node;
      onChange(n, path);
      setTypeahead(false);
      setFocused(false);
      removeWindowListeners();
    }
  };

  const handleTypeaheadKeyDown = (e: ReactKeyboardEvent<HTMLDivElement>) => {
    setFocused(false);
    if (e.code === "Backspace" && (e?.target as HTMLInputElement).value === "") {
      e.stopPropagation();
      setTypeahead(false);
      setFocused(true);
    }
  };

  const handlePatternNodeContainerKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === "Backspace" && !(e.target as HTMLInputElement).value) {
      removeWindowListeners();
      onChange({}, path);
      setMostRecentPath(path);
    } else if (e.key === "Tab") {
      removeWindowListeners();
      setTypeahead(false);
      setFocused(false);
    } else if (e.key.length === 1 && varCharRegex.test(e.key)) {
      setTypeahead(true);
      setFocused(false);
    } else if (e.key.length === 1) {
      // Handle Operation intent
      const possibleOperationNode = getNodeByOperationSymbol(e.key, nodeRef.current);
      if (possibleOperationNode) {
        onChange(possibleOperationNode, path);
      }
    }
  }, []);

  const handleFocus = (e?: ReactMouseEvent<HTMLDivElement, MouseEvent> | any) => {
    if (disabled) return;
    if (!typeahead && !focused) {
      e?.stopPropagation();
      setFocused(true);
      setMostRecentPath(path);
    } else {
      setFocused(false);
    }
  };

  const handleClickOutside = useCallback((e: MouseEvent) => {
    if (focusedRef.current && !focusedRef.current.contains(e.target as Node)) {
      setFocused(false);
      removeWindowListeners();
    } else {
      focusedRef.current?.blur();
      setMostRecentPath(undefined);
    }
  }, []);

  const handleBlur = () => {
    if (isEqual(mostRecentPath, path)) {
      setMostRecentPath(undefined);
      removeWindowListeners();
    }
  };

  return (
    <PatternFocusedNodeContainer
      className={className}
      ref={focusedRef}
      tabIndex={0}
      onFocus={(e) => {
        e.preventDefault();
        handleFocus(e);
      }}
      onBlur={handleBlur}
    >
      {children}
      {typeahead && (
        <TypeaheadInput
          onChange={handleEntityFirstTyping}
          path={path}
          autoFocus={true}
          functionalOnly={true}
          onKeyDown={handleTypeaheadKeyDown}
        />
      )}
    </PatternFocusedNodeContainer>
  );
};
