import { createFileRoute } from "@tanstack/react-router";

import { FeatureId } from "@/api";
import { ProtectedRoute } from "@/components/protectedRoute/ProtectedRoute";
import { HistoryProvider } from "@/contexts/HistoryProvider";
import { PatternEditor } from "@/features/pattern/PatternEditor";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

const NewPatternComponent = () => {
  return (
    <ProtectedRoute featureId={FeatureId.Patterns}>
      <AppMainLayout>
        <HistoryProvider>
          <PatternEditor />
        </HistoryProvider>
      </AppMainLayout>
    </ProtectedRoute>
  );
};

export const Route = createFileRoute("/pattern/new")({
  component: () => <NewPatternComponent />,
  beforeLoad: () => setTitleBeforeLoad("New Pattern"),
});
