import { useContext } from "react";

import { FeatureId } from "@/api";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import SCVContext from "@/contexts/SCVContext";
import { useTelematicsProtectedVehicleChargingSessionDurationStatsQuery } from "@/hooks/telematicsProtectedHooks/useTelematicsProtectedVehicleChargingSessionDurationStatsQuery";
import { useFeatureFlags } from "@/utils/features";

import { BaseEChart } from "../ui/BaseEChart";
import { BasicWidget } from "../widget/BasicWidget";
import { createBoxPlotOptions } from "./BoxPlotHelpers";

export const VehicleChargingSessionDurationBoxPlot = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const telematicsDisabled = !isFeatureEnabled(FeatureId.Telemetry);

  const { globalFilter } = useContext(GlobalFilterContext);
  const { pvin } = useContext(SCVContext);
  const { data, isLoading } = useTelematicsProtectedVehicleChargingSessionDurationStatsQuery({
    vehicleId: pvin,
    filter: globalFilter,
  });

  return (
    <BasicWidget title="Vehicle Charging Duration vs. Fleet" muted={telematicsDisabled}>
      <BaseEChart
        showLoading={isLoading}
        option={createBoxPlotOptions(data?.filteredQuery.vehicleChargingSessionDurationStats, isLoading, "minutes")}
      />
    </BasicWidget>
  );
};
