import { useNavigate, useParams } from "@tanstack/react-router";

import { HistoryProvider } from "@/contexts/HistoryProvider";

import { PatternEditor } from "./PatternEditor";

const PatternEditorPage = () => {
  const { patternId } = useParams({ strict: false });

  const navigate = useNavigate();

  return (
    <HistoryProvider>
      <PatternEditor
        patternId={patternId}
        afterCreate={(pattern) => navigate({ to: "/pattern/$patternId", params: { patternId: pattern.id } })}
      />
    </HistoryProvider>
  );
};

export default PatternEditorPage;
