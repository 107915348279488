import AnomalyIcon from "@amayaIcons/Anomaly.svg";
import FunctionIcon from "@amayaIcons/Function.svg";
import AddIcon from "@amayaIcons/operators/Add.svg";
import EqIcon from "@amayaIcons/operators/Eq.svg";
import GtIcon from "@amayaIcons/operators/Gt.svg";
import GteIcon from "@amayaIcons/operators/Gte.svg";
import LtIcon from "@amayaIcons/operators/Lt.svg";
import LteIcon from "@amayaIcons/operators/Lte.svg";
import MultiplyIcon from "@amayaIcons/operators/Multiply.svg";
import NeqIcon from "@amayaIcons/operators/Neq.svg";
import SubtractIcon from "@amayaIcons/operators/Subtract.svg";
import SignalIcon from "@amayaIcons/Signal.svg";
import {
  CalculatorOutlined,
  FieldBinaryOutlined,
  ForkOutlined,
  MergeCellsOutlined,
  MonitorOutlined,
  NumberOutlined,
} from "@ant-design/icons";
import {
  AggregationExpression,
  AggregationType,
  BooleanBinaryExpressionType,
  BooleanUnaryExpressionType,
  Expression,
  ExpressionType,
  ExpressionValueType,
  NumericBinaryExpressionType,
  NumericUnaryExpressionType,
  TimeSeriesExpressionType,
} from "@lib/src/expression/types";
import React from "react";
import { PartialDeep } from "type-fest";

import { ExpressionIntervalType, TimeSeriesField } from "@/api";
import { toNumberOrUndef } from "@/utils/numberUtils";

import { patternBuilderMap, PatternOperatorType } from "./patternBuilderMap";
import TypeaheadOption from "./TypeaheadOption";
import TypeaheadOptionGroupHeader from "./TypeaheadOptionGroupHeader";

export type PatternOption = {
  label: React.ReactNode;
  value: string;
  // attrs below are meant for custom UI/behavior
  searchLabel: string;
  isFunction?: boolean;
  isOperator?: boolean;
  entity?: TimeSeriesExpressionType;
  aggregation?: AggregationType;
};

export type PatternOptionFilterBy = {
  [K in keyof PatternOption]: PatternOption[K] extends boolean | TimeSeriesExpressionType | undefined ? K : never;
}[keyof PatternOption];

export type GroupedPatternOption = {
  label: React.ReactNode;
  groupLabel: string;
  options: PatternOption[];
};

export const icNullMatcher = "null";
export const functionOpenSymbol = "(";
export const operatorIntentSymbols = ["+", "-", "*", "/"] as const;
export type OperatorIntentSymbol = (typeof operatorIntentSymbols)[number];

// TODO DRY the option functions
export const getFunctionOnlyOptions = () => {
  return [
    {
      label: <TypeaheadOptionGroupHeader label={ExpressionValueType.Comparison} icon={<FieldBinaryOutlined />} />,
      groupLabel: ExpressionValueType.Comparison,
      options: [
        { value: ExpressionType.Gt, label: "Greater than", searchLabel: "Greater than", isFunction: true },
        { value: ExpressionType.Gte, label: "Greater or equal", searchLabel: "Greater or equal", isFunction: true },
        { value: ExpressionType.Lt, label: "Less than", searchLabel: "Less than", isFunction: true },
        { value: ExpressionType.Lte, label: "Less or equal", searchLabel: "Less or equal", isFunction: true },
        { value: ExpressionType.Eq, label: "Equal", searchLabel: "Equal", isFunction: true },
        { value: ExpressionType.Neq, label: "Not equal", searchLabel: "Not equal", isFunction: true },
        { value: ExpressionType.And, label: "And", searchLabel: "And", isFunction: true },
        { value: ExpressionType.Or, label: "Or", searchLabel: "Or", isFunction: true },
        { value: ExpressionType.IsNull, label: "Is null", searchLabel: "Is null", isFunction: true },
        { value: ExpressionType.IsNotNull, label: "Is not null", searchLabel: "Is not null", isFunction: true },
        { value: ExpressionType.Not, label: "Not", searchLabel: "Not", isFunction: true },
      ],
    },
    {
      label: <TypeaheadOptionGroupHeader label={ExpressionValueType.Mathematical} icon={<CalculatorOutlined />} />,
      groupLabel: ExpressionValueType.Mathematical,
      options: [
        { value: ExpressionType.Add, label: "Add", searchLabel: "Add", isFunction: true },
        { value: ExpressionType.Subtract, label: "Subtract", searchLabel: "Subtract", isFunction: true },
        { value: ExpressionType.Multiply, label: "Multiply", searchLabel: "Multiply", isFunction: true },
        { value: ExpressionType.Divide, label: "Divide", searchLabel: "Divide", isFunction: true },
        { value: ExpressionType.Abs, label: "Absolute value", searchLabel: "Absolute value", isFunction: true },
      ],
    },
    {
      label: <TypeaheadOptionGroupHeader label={ExpressionValueType.Conditional} icon={<ForkOutlined />} />,
      groupLabel: ExpressionValueType.Conditional,
      options: [
        { value: ExpressionType.If, label: "If", searchLabel: "If", isFunction: true },
        { value: ExpressionType.Case, label: "Case", searchLabel: "Case", isFunction: true },
      ],
    },
    {
      label: <TypeaheadOptionGroupHeader label={ExpressionValueType.Aggregation} icon={<MergeCellsOutlined />} />,
      groupLabel: ExpressionValueType.Aggregation,
      options: [
        {
          value: AggregationType.Average,
          label: "Average",
          searchLabel: "Average",
          isFunction: true,
          aggregation: AggregationType.Average,
        },
        {
          value: AggregationType.Count,
          label: "Count",
          searchLabel: "Count",
          isFunction: true,
          aggregation: AggregationType.Count,
        },
        {
          value: AggregationType.Max,
          label: "Max",
          searchLabel: "Max",
          isFunction: true,
          aggregation: AggregationType.Max,
        },
        {
          value: AggregationType.Median,
          label: "Median",
          searchLabel: "Median",
          isFunction: true,
          aggregation: AggregationType.Median,
        },
        {
          value: AggregationType.Min,
          label: "Min",
          searchLabel: "Min",
          isFunction: true,
          aggregation: AggregationType.Min,
        },
        {
          value: AggregationType.Percentile,
          label: "Percentile",
          searchLabel: "Percentile",
          isFunction: true,
          aggregation: AggregationType.Percentile,
        },
        {
          value: AggregationType.Std,
          label: "Standard Deviation",
          searchLabel: "Standard Deviation",
          isFunction: true,
          aggregation: AggregationType.Std,
        },
      ],
    },
  ];
};

export const getTimeSeriesIconByType = (t: TimeSeriesExpressionType): React.JSX.Element => {
  switch (t) {
    case TimeSeriesExpressionType.Signal:
      return <SignalIcon />;
    case TimeSeriesExpressionType.Pattern:
      return <FunctionIcon />;
    case TimeSeriesExpressionType.AnomalyScore:
      return <AnomalyIcon />;
    default:
      return <></>;
  }
};

export const floatRegex = /^[0-9]*[.]?[0-9]*$/;
// TODO set key attr to array options below
export const getPatternInputOptions = (
  signals: TimeSeriesField[],
  patterns: TimeSeriesField[],
  anomalies: TimeSeriesField[]
): GroupedPatternOption[] => {
  return [
    {
      label: (
        <TypeaheadOptionGroupHeader
          label={TimeSeriesExpressionType.Signal}
          icon={getTimeSeriesIconByType(TimeSeriesExpressionType.Signal)}
          timeSeriesType={TimeSeriesExpressionType.Signal}
        />
      ),
      groupLabel: TimeSeriesExpressionType.Signal,
      options: signals.map((s) => ({
        label: <TypeaheadOption label={s.displayName ?? s.id} timeSeriesType={TimeSeriesExpressionType.Signal} />,
        searchLabel: s.displayName ?? s.id,
        value: s.id,
        entity: TimeSeriesExpressionType.Signal,
      })),
    },
    {
      label: (
        <TypeaheadOptionGroupHeader
          label={TimeSeriesExpressionType.Pattern}
          icon={getTimeSeriesIconByType(TimeSeriesExpressionType.Pattern)}
          timeSeriesType={TimeSeriesExpressionType.Pattern}
        />
      ),
      groupLabel: TimeSeriesExpressionType.Pattern,
      options: patterns
        .filter((p) => p.entityId)
        .map((p) => ({
          label: <TypeaheadOption label={p.displayName ?? p.id} timeSeriesType={TimeSeriesExpressionType.Pattern} />,
          searchLabel: p.displayName ?? p.id,
          value: p.entityId as string,
          entity: TimeSeriesExpressionType.Pattern,
        })),
    },
    {
      label: (
        <TypeaheadOptionGroupHeader
          label={TimeSeriesExpressionType.AnomalyScore}
          icon={getTimeSeriesIconByType(TimeSeriesExpressionType.AnomalyScore)}
          timeSeriesType={TimeSeriesExpressionType.AnomalyScore}
        />
      ),
      groupLabel: TimeSeriesExpressionType.AnomalyScore,
      options: anomalies
        .filter((a) => a.entityId)
        .map((a) => ({
          label: (
            <TypeaheadOption label={a.displayName ?? a.id} timeSeriesType={TimeSeriesExpressionType.AnomalyScore} />
          ),
          searchLabel: a.displayName ?? a.id,
          value: a.entityId as string,
          entity: TimeSeriesExpressionType.AnomalyScore,
        })),
    },
    {
      label: <TypeaheadOptionGroupHeader label={ExpressionValueType.Comparison} icon={<FieldBinaryOutlined />} />,
      groupLabel: ExpressionValueType.Comparison,
      options: [
        { value: ExpressionType.Gt, label: "Greater than", searchLabel: "Greater than", isFunction: true },
        { value: ExpressionType.Gte, label: "Greater or equal", searchLabel: "Greater or equal", isFunction: true },
        { value: ExpressionType.Lt, label: "Less than", searchLabel: "Less than", isFunction: true },
        { value: ExpressionType.Lte, label: "Less or equal", searchLabel: "Less or equal", isFunction: true },
        { value: ExpressionType.Eq, label: "Equal", searchLabel: "Equal", isFunction: true },
        { value: ExpressionType.Neq, label: "Not equal", searchLabel: "Not equal", isFunction: true },
        { value: ExpressionType.And, label: "And", searchLabel: "And", isFunction: true },
        { value: ExpressionType.Or, label: "Or", searchLabel: "Or", isFunction: true },
        { value: ExpressionType.IsNull, label: "Is null", searchLabel: "Is null", isFunction: true },
        { value: ExpressionType.IsNotNull, label: "Is not null", searchLabel: "Is not null", isFunction: true },
        { value: ExpressionType.Not, label: "Not", searchLabel: "Not", isFunction: true },
      ],
    },
    {
      label: <TypeaheadOptionGroupHeader label={ExpressionValueType.Mathematical} icon={<CalculatorOutlined />} />,
      groupLabel: ExpressionValueType.Mathematical,
      options: [
        { value: ExpressionType.Add, label: "Add", searchLabel: "Add", isFunction: true },
        { value: ExpressionType.Subtract, label: "Subtract", searchLabel: "Subtract", isFunction: true },
        { value: ExpressionType.Multiply, label: "Multiply", searchLabel: "Multiply", isFunction: true },
        { value: ExpressionType.Divide, label: "Divide", searchLabel: "Divide", isFunction: true },
        { value: ExpressionType.Abs, label: "Absolute value", searchLabel: "Absolute value", isFunction: true },
      ],
    },
    {
      label: <TypeaheadOptionGroupHeader label={ExpressionValueType.Conditional} icon={<ForkOutlined />} />,
      groupLabel: ExpressionValueType.Conditional,
      options: [
        { value: ExpressionType.If, label: "If", searchLabel: "If", isFunction: true },
        { value: ExpressionType.Case, label: "Case", searchLabel: "Case", isFunction: true },
      ],
    },
    {
      label: <TypeaheadOptionGroupHeader label={ExpressionValueType.Aggregation} icon={<MergeCellsOutlined />} />,
      groupLabel: ExpressionValueType.Aggregation,
      options: [
        {
          value: AggregationType.Average,
          label: "Average",
          searchLabel: "Average",
          isFunction: true,
          aggregation: AggregationType.Average,
        },
        {
          value: AggregationType.Count,
          label: "Count",
          searchLabel: "Count",
          isFunction: true,
          aggregation: AggregationType.Count,
        },
        {
          value: AggregationType.Max,
          label: "Max",
          searchLabel: "Max",
          isFunction: true,
          aggregation: AggregationType.Max,
        },
        {
          value: AggregationType.Median,
          label: "Median",
          searchLabel: "Median",
          isFunction: true,
          aggregation: AggregationType.Median,
        },
        {
          value: AggregationType.Min,
          label: "Min",
          searchLabel: "Min",
          isFunction: true,
          aggregation: AggregationType.Min,
        },
        {
          value: AggregationType.Percentile,
          label: "Percentile",
          searchLabel: "Percentile",
          isFunction: true,
          aggregation: AggregationType.Percentile,
        },
        {
          value: AggregationType.Std,
          label: "Standard Deviation",
          searchLabel: "Standard Deviation",
          isFunction: true,
          aggregation: AggregationType.Std,
        },
      ],
    },
    {
      label: <TypeaheadOptionGroupHeader label={ExpressionValueType.Literal} icon={<NumberOutlined />} />,
      groupLabel: ExpressionValueType.Literal,
      options: [
        {
          value: ExpressionType.LiteralNumeric,
          label: "Constant Number",
          searchLabel: "Constant Number",
        },
        { value: ExpressionType.Null, label: "Null", searchLabel: "Null" },
      ],
    },
  ];
};

export const filterOptions = (options: GroupedPatternOption[], searchTerm: string) => {
  const icSearchTerm = searchTerm.toLowerCase();
  return (
    options
      .map((group) => {
        const groupLabelMatch = group.groupLabel.toLowerCase().includes(icSearchTerm);

        // If the group label matches, return the group with all its options
        if (groupLabelMatch) {
          return group;
        }

        // Otherwise, filter the options within the group based on the search term
        const filteredItems = group.options.filter(({ searchLabel }) =>
          searchLabel.toLowerCase().includes(icSearchTerm)
        );

        // Return the group only if it has matching options
        if (filteredItems.length > 0) {
          return {
            ...group,
            options: filteredItems,
          };
        }
        return null;
      })
      // Filter out any groups that are null (no matches)
      .filter((group) => group !== null)
  );
};

export const findPatternOption = (
  options: GroupedPatternOption[],
  functionMatcher: string,
  filterBy: PatternOptionFilterBy
): PatternOption | undefined => {
  if (!filterBy) return;
  const icFunctionMatcher = functionMatcher.toLowerCase();
  for (const group of options) {
    // Filters options within the group's options where option[filterBy] is truthy and the label or value matches the functionMatcher
    const currentMatches = group.options.filter(
      (option) => !!option[filterBy] && option.searchLabel.toLowerCase().includes(icFunctionMatcher)
    );

    // If a matching option is found, return it
    if (currentMatches.length === 1) {
      return currentMatches[0];
    }
  }
  // If no matching option is found, return undefined
  return;
};

export const formatAggregationNode = (
  expression: PartialDeep<AggregationExpression>,
  aggregationType: AggregationType
) => {
  return { ...expression, aggregationType };
};

export const getNodeFromFunctionIntent = (
  options: GroupedPatternOption[],
  searchTerm: string
): PartialDeep<Expression> | undefined => {
  if (searchTerm.endsWith(functionOpenSymbol)) {
    const possibleFnMatcher = searchTerm.slice(0, -1);
    const possibleFn = findPatternOption(options, possibleFnMatcher, "isFunction");
    if (possibleFn && (possibleFn.value in ExpressionType || possibleFn.value in AggregationType)) {
      const newNodeType =
        possibleFn.value in AggregationType ? "Aggregation" : (possibleFn.value as PatternOperatorType);
      const rawExpression = patternBuilderMap[newNodeType];
      const expression =
        newNodeType === "Aggregation" && possibleFn.aggregation
          ? formatAggregationNode(rawExpression as PartialDeep<AggregationExpression>, possibleFn.aggregation)
          : rawExpression;
      return expression;
    }
  }
};

export const getNodeFromOperationIntent = (
  options: GroupedPatternOption[],
  searchTerm: string
): PartialDeep<Expression> | undefined => {
  if (operatorIntentSymbols.some((op) => searchTerm.endsWith(op))) {
    const operatorSymbol = searchTerm.charAt(searchTerm.length - 1) as OperatorIntentSymbol;
    const possibleEntityMatcher = searchTerm.slice(0, -1).trim();
    const possibleEntity = findPatternOption(options, possibleEntityMatcher, "entity");
    const possibleLiteralNumber = getNumberNodeFromSearch(possibleEntityMatcher);
    if (possibleEntity || possibleLiteralNumber) {
      const resultEntityNode = possibleEntity
        ? { type: ExpressionType.Signal, value: possibleEntity.value }
        : possibleLiteralNumber;
      return getNodeByOperationSymbol(operatorSymbol, resultEntityNode || {});
    }
  }
};

export const getNodeByOperationSymbol = (
  operatorSymbol: string,
  leftValue: PartialDeep<Expression>
): PartialDeep<Expression> | undefined => {
  if (operatorIntentSymbols.some((op) => operatorSymbol.endsWith(op))) {
    switch (operatorSymbol) {
      case "+":
        return {
          type: ExpressionType.Add,
          values: [leftValue, {}],
        };
      case "-":
        return {
          type: ExpressionType.Subtract,
          values: [leftValue, {}],
        };
      case "*":
        return {
          type: ExpressionType.Multiply,
          values: [leftValue, {}],
        };
      case "/":
        return {
          type: ExpressionType.Divide,
          values: [leftValue, {}],
        };
      default:
        return;
    }
  }
};

export const getNumberNodeFromSearch = (searchTerm: string): PartialDeep<Expression> | undefined => {
  const searchAsNumber = toNumberOrUndef(searchTerm);
  if (searchAsNumber) {
    return { type: ExpressionType.LiteralNumeric, value: searchAsNumber };
  }
};

export const getNullNodeFromSearch = (searchTerm: string): PartialDeep<Expression> | undefined => {
  const searchAsNull = searchTerm.toLowerCase() === icNullMatcher;
  if (searchAsNull) {
    return patternBuilderMap[ExpressionType.Null];
  }
};

export const getBinrayOperatorSymbol = (type?: string): React.ReactNode => {
  switch (type) {
    case ExpressionType.Add:
      return <AddIcon />;
    case ExpressionType.Subtract:
      return <SubtractIcon />;
    case ExpressionType.Multiply:
      return <MultiplyIcon />;
    // TODO check for a nicer way to paint a bar
    case ExpressionType.Divide:
      return <hr className="operator-symbol" />;
    case ExpressionType.And:
      return <span className="logical-operator-label">AND</span>;
    case ExpressionType.Or:
      return <span className="logical-operator-label">OR</span>;
    case ExpressionType.Lt:
      return <LtIcon />;
    case ExpressionType.Lte:
      return <LteIcon />;
    case ExpressionType.Gt:
      return <GtIcon />;
    case ExpressionType.Gte:
      return <GteIcon />;
    case ExpressionType.Eq:
      return <EqIcon />;
    case ExpressionType.Neq:
      return <NeqIcon />;
    default:
      return "";
  }
};

export const nodeTypeHasWrapping = (t: Expression["type"] | undefined): boolean | undefined => {
  if (
    t &&
    (t in BooleanUnaryExpressionType ||
      t in BooleanBinaryExpressionType ||
      t in NumericUnaryExpressionType ||
      t in NumericBinaryExpressionType)
  ) {
    return true;
  }
};

export const getAggregationIntervalTypeOptions = (): GroupedPatternOption[] => {
  return [
    {
      label: <TypeaheadOptionGroupHeader label={"Interval Type"} icon={<MonitorOutlined />} />,
      groupLabel: "",
      options: Object.entries(ExpressionIntervalType).map(([k, v]) => ({ label: k, value: v, searchLabel: k })),
    },
  ];
};
