import { DEFAULT_PAGINATION } from "@lib/src/table/defaults";
import { createFileRoute } from "@tanstack/react-router";

import { FeatureId, SortOrder } from "@/api";
import { AnomaliesWithVehicleCountSortField } from "@/api/customerApi";
import { ProtectedRoute } from "@/components/protectedRoute/ProtectedRoute";
import { TableState } from "@/components/tables/StatefulTable.types";
import AnomalyDetectorHome from "@/features/anomaly/list/AnomalyDetectorHome";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

const defaultTableState: TableState = {
  pagination: DEFAULT_PAGINATION,
  sorting: { field: AnomaliesWithVehicleCountSortField.AnomalyVehicleCount, order: SortOrder.Descend },
};

export type AnomalyHomeSearch = {
  anomaliesTable?: TableState;
};

export const Route = createFileRoute("/anomaly-detector/")({
  component: () => (
    <ProtectedRoute featureId={FeatureId.Anomalies}>
      <AppMainLayout>
        <AnomalyDetectorHome />
      </AppMainLayout>
    </ProtectedRoute>
  ),
  beforeLoad: () => setTitleBeforeLoad("Anomaly Detectors"),
  validateSearch: (search: Record<string, unknown>): AnomalyHomeSearch => {
    return {
      anomaliesTable: (search.anomaliesTable as TableState) || defaultTableState,
    };
  },
});
