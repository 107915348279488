import styled from "styled-components";

export const ChartLibraryContainer = styled.div``;

export const ChartLibraryGeneralHeaderContainer = styled.div`
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .container {
    .title {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
    }
  }
`;
