import { FeatureId } from "@/api";
import { VehicleAmbientTemperatureBoxPlot } from "@/components/charts/VehicleAmbientTemperatureBoxPlot";
import { VehicleChargingSessionDurationBoxPlot } from "@/components/charts/VehicleChargingDurationBoxPlot";
import { VehicleDrivingSpeedBoxPlot } from "@/components/charts/VehicleDrivingSpeedBoxPlot";
import { useFeatureFlags } from "@/utils/features";

export const VehicleChartsSection = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const isChargingEnabled = isFeatureEnabled(FeatureId.ChargingDuration);
  const telematicsless = !isFeatureEnabled(FeatureId.Telemetry);
  const className = `vehicle-charts-container rendering-order-${telematicsless ? "fith" : "second"}`;
  return (
    <div className={className}>
      <div className={isChargingEnabled ? "driving-charts-container" : "driving-charts-container-charging-disabled"}>
        <VehicleDrivingSpeedBoxPlot />
        <VehicleAmbientTemperatureBoxPlot />
        {isChargingEnabled && <VehicleChargingSessionDurationBoxPlot />}
      </div>
    </div>
  );
};
