import { createFileRoute } from "@tanstack/react-router";

import { FeatureId } from "@/api";
import { ProtectedRoute } from "@/components/protectedRoute/ProtectedRoute";
import { HistoryProvider } from "@/contexts/HistoryProvider";
import PatternEditorPage from "@/features/pattern/PatternEditorPage";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

export const Route = createFileRoute("/pattern/$patternId")({
  component: () => (
    <ProtectedRoute featureId={FeatureId.Patterns}>
      <AppMainLayout>
        <HistoryProvider>
          <PatternEditorPage />
        </HistoryProvider>
      </AppMainLayout>
    </ProtectedRoute>
  ),
  beforeLoad: () => setTitleBeforeLoad("Pattern"),
});
