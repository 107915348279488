import { LogoutOutlined } from "@ant-design/icons";
import { Link, useMatches } from "@tanstack/react-router";
import { message, Tooltip } from "antd";
import React, { useState } from "react";

import { FeatureId } from "@/api";
import { ValidAppRoutePath } from "@/App";
import { useFeatureFlags } from "@/utils/features";
import Logger from "@/utils/Logger";

import AppVersion from "../appVersion/AppVersion";
import CustomerIcon from "../customerIcon/CustomerIcon";
import CustomerPicker from "../customerPicker/CustomerPicker";
import AnomalyIcon from "../icons/Anomaly.svg";
import CarIcon from "../icons/Car.svg";
import ChartsIcon from "../icons/Charts.svg";
import ChevronLeft from "../icons/CloseLeft.svg";
import ChevronRight from "../icons/CloseRight.svg";
import ClustersIcon from "../icons/Clusters.svg";
import EventsIcon from "../icons/Events.svg";
import FunctionIcon from "../icons/Function.svg";
import HomeIcon from "../icons/Home.svg";
import ProfileIcon from "../icons/Profile.svg";
import RepairEventsIcon from "../icons/RepairEvents.svg";
import ToolsIcon from "../icons/Tools.svg";
import {
  ClientInfoContainer,
  Container,
  LogoutButton,
  NavSectionBottom,
  NavSectionHeader,
  NavSectionMenu,
  SideIcon,
  SideLinkTooltip,
} from "./SideNav.styled";

const logger = Logger.create(import.meta.url);

export type SideNavLinkWithIcon = {
  icon: React.JSX.Element;
  link: ValidAppRoutePath;
  name: string;
  featureId?: FeatureId;
};

const topIcons: SideNavLinkWithIcon[] = [
  {
    icon: <HomeIcon />,
    link: "/",
    name: "Home",
  },
  {
    icon: <CarIcon />,
    link: "/vehicle",
    name: "Vehicles",
  },
  {
    icon: <ClustersIcon />,
    link: "/cluster",
    name: "Clusters",
    featureId: FeatureId.Clusters,
  },
  {
    icon: <AnomalyIcon />,
    link: "/anomaly-detector",
    name: "Anomalies",
    featureId: FeatureId.Anomalies,
  },
  {
    icon: <EventsIcon />,
    link: "/event-analysis",
    name: "Event Analysis",
  },
  {
    icon: <FunctionIcon />,
    link: "/pattern",
    name: "Patterns",
    featureId: FeatureId.Patterns,
  },
  {
    icon: <RepairEventsIcon />,
    link: "/repair-events",
    name: "Repair Events",
    featureId: FeatureId.RepairEvents,
  },
  {
    icon: <ToolsIcon />,
    link: "/repair-concept",
    name: "Repair Concept",
    featureId: FeatureId.FinancialAnalysis,
  },
  {
    icon: <ChartsIcon />,
    link: "/forecast",
    name: "Fleet Forecast",
    featureId: FeatureId.FinancialAnalysis,
  },
];

// This is a small hack to match routes into event-analysis that are actually not children of '/event-analysis'
const eventAnalysisRelatedRoutePaths: ValidAppRoutePath[] = [
  "/fault/$faultCodeId",
  "/repair/full",
  "/repair/partial",
  "/pattern-event/$patternEventId",
];

const isEventAnalysisRelatedPath = (paths: ValidAppRoutePath[]): boolean => {
  return paths.some((path) => eventAnalysisRelatedRoutePaths.includes(path));
};

const AppVersionSection = () => {
  const handleVersionSelected = (version: string) => {
    navigator.clipboard
      .writeText(version)
      .then(() => {
        message.info(`${version} copied to clipboard`);
      })
      .catch((error) => {
        logger.error("Error while copying to clipboard:", error as Error);
      });
  };

  return (
    <Tooltip title="Click to Copy" arrow={false}>
      <div className="version-container">
        <AppVersion onSelected={handleVersionSelected} />
      </div>
    </Tooltip>
  );
};

type SideNavProps = {
  isAuthenticated: boolean;
  onLogout: () => void;
  isLoading: boolean;
  name: string | undefined;
};

function SideNav({ isAuthenticated, name = "", onLogout }: SideNavProps) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { isFeatureEnabled } = useFeatureFlags();

  const currentRouteMatches = useMatches();
  const activePathRoutes = currentRouteMatches.map((match) => match.fullPath);

  const isEventAnalysisRelatedRoute = isEventAnalysisRelatedPath(activePathRoutes);

  return (
    <Container collapsedWidth={72} width={244} collapsible collapsed={isCollapsed} trigger={null}>
      <NavSectionMenu className={isCollapsed ? "collapsed" : ""}>
        <NavSectionHeader>
          <ClientInfoContainer>
            <Link to="/">
              <CustomerIcon size={42} />
            </Link>
            {!isCollapsed && isAuthenticated && <CustomerPicker />}
          </ClientInfoContainer>
          {topIcons
            .filter((icon) => !icon.featureId || isFeatureEnabled(icon.featureId))
            .map((icon) => {
              const isEventAnalysisLik = icon.link === "/event-analysis";
              const linkClassname = `link${isEventAnalysisLik && isEventAnalysisRelatedRoute ? " active" : ""}`;
              return (
                <Link className={linkClassname} to={icon.link} key={icon.name} activeProps={{ className: "active" }}>
                  <SideIcon>
                    {icon.icon}
                    {isCollapsed && (
                      <SideLinkTooltip className="link-tooltip">
                        <span>{icon.name}</span>
                      </SideLinkTooltip>
                    )}
                  </SideIcon>
                  {!isCollapsed && <div className="nav-btn-label">{icon.name}</div>}
                </Link>
              );
            })}
        </NavSectionHeader>
        <NavSectionBottom>
          <div className="links">
            <div className="link profile">
              <SideIcon>
                <ProfileIcon />
              </SideIcon>
              {isCollapsed ? undefined : (
                <div className="nav-btn-label">
                  {name}
                  <LogoutButton onClick={onLogout}>
                    Logout
                    <LogoutOutlined />
                  </LogoutButton>
                </div>
              )}
            </div>
          </div>
          <div className="link nav-btn-close" onClick={() => setIsCollapsed((prev) => !prev)}>
            <SideIcon>{isCollapsed ? <ChevronRight /> : <ChevronLeft />}</SideIcon>
            {isCollapsed ? undefined : <div className="nav-btn-label">Close</div>}
          </div>
          {isCollapsed ? undefined : <AppVersionSection />}
        </NavSectionBottom>
      </NavSectionMenu>
    </Container>
  );
}

export default SideNav;
