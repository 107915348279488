import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { notification, TableProps, Tooltip } from "antd";
import { SortOrder } from "antd/es/table/interface";
import { ColumnType } from "antd/lib/table";
import { debounce, get, split } from "lodash";
import { useEffect, useRef, useState } from "react";

import { UI_SETTINGS } from "@/constants";
import i18next from "@/i18n";

import IconSortDown from "../icons/icon-sort-down.svg";
import IconSortUp from "../icons/icon-sort-up.svg";
import { EmptyComponent } from "../ui/EmptyComponent";
import { LoadingWrapper } from "../ui/LoadingWrapper";
import { StyledTable, TableCellWrapper, TableWrapper } from "./BasicTable.styled";
import { addCellEventListener, getSelectedData, InternalFunctions, SelectionGrid } from "./BasicTableUtils";
import { DEFAULT_SORT_OPTIONS } from "./sorting";
type TableSortIconProps = {
  sortOrder: SortOrder;
};

export const TableSortIcon = ({ sortOrder }: TableSortIconProps) => {
  if (!sortOrder) return <></>;
  return sortOrder === "ascend" ? <IconSortUp /> : <IconSortDown />;
};

function addSortIcon<T>(props: BasicTableProps<T>): BasicTableProps<T> {
  return {
    ...props,
    columns: props.columns?.map((c) => ({
      ...c,
      sortIcon: c.sortIcon || (c.sorter ? ({ sortOrder }) => <TableSortIcon sortOrder={sortOrder} /> : undefined),
    })),
  };
}

export type BasicTableProps<T> = TableProps<T> & {
  noDataText?: string;
};

/**
 * Adds tooltips to the columns of a table.
 */
function addTooltipToColumns<T>(props: TableProps<T>): TableProps<T> {
  return {
    ...props,
    columns: props.columns?.map((c: ColumnType<T>) => {
      const isStringTitle = typeof c.title == "string";
      return {
        ...c,
        ellipsis: { showTitle: false },
        render: (value: any, record: T) => {
          return <BasicTableCell column={c} record={record} value={value} />;
        },
        title: isStringTitle && c.title ? <BasicTableHeaderCell title={c.title.toString()} width={c.width} /> : c.title,
      };
    }),
  };
}

type CellRendererProps = {
  value: any;
  record: any;
  column: ColumnType<any>;
};

const BasicTableHeaderCell = (props: { title: string; width: string | number | undefined }) => {
  const { title, width } = props;
  const headerCellRef = useRef<HTMLTableCellElement>(null);
  const [headerEllipsis, setHeaderEllipsis] = useState(false);

  const isEllipsisActive = () => {
    if (headerCellRef.current) {
      return headerCellRef.current.offsetWidth < headerCellRef.current.scrollWidth;
    }
    return false;
  };

  useEffect(() => {
    setHeaderEllipsis(isEllipsisActive());
  }, [props]);

  return headerEllipsis ? (
    <Tooltip title={title}>
      <TableCellWrapper size={width} ref={headerCellRef}>
        {title}
      </TableCellWrapper>
    </Tooltip>
  ) : (
    <TableCellWrapper size={width} ref={headerCellRef}>
      {title}
    </TableCellWrapper>
  );
};

/**
 * Renders the content of a basic table cell with tooltip and ellipsis.
 */
const BasicTableCell = (props: CellRendererProps) => {
  const { column, record, value } = props;
  const cellRef = useRef<HTMLTableCellElement>(null);
  const [ellipsis, setEllipsis] = useState(true);

  const isEllipsisActive = () => {
    if (cellRef.current) {
      return cellRef.current.offsetWidth < cellRef.current.scrollWidth;
    }
    return false;
  };

  useEffect(() => {
    setEllipsis(isEllipsisActive());
  }, [props]);

  const columnDataIndex = (column.dataIndex || column.key) as string;
  const keys = split(columnDataIndex, ".") ?? [];

  const renderContent = column.render ? column.render(value, record, 0) : get(record, keys);
  if (column.tooltipMessage) {
    return (
      <Tooltip title={column.tooltipMessage(value, record)} placement="topLeft">
        <TableCellWrapper size={column.width}>{renderContent}</TableCellWrapper>
      </Tooltip>
    );
  }

  return ellipsis ? (
    <Tooltip title={renderContent} placement="topLeft">
      <TableCellWrapper size={column.width} ref={cellRef}>
        {renderContent}
      </TableCellWrapper>
    </Tooltip>
  ) : (
    <TableCellWrapper size={column.width} ref={cellRef}>
      {renderContent}
    </TableCellWrapper>
  );
};

const refineProps = <T extends object>(props: TableProps<T>, InternalFunctions: InternalFunctions) => {
  const columnsWithToolTip = addTooltipToColumns(props);
  const columnCellsWithEventListeners = addCellEventListener(columnsWithToolTip, InternalFunctions);
  return addSortIcon(columnCellsWithEventListeners);
};

export const BasicTable = <T extends object>(props: BasicTableProps<T>) => {
  // used to prevent a click event from happening if the user is copying from the table
  const selectionMadeRef = useRef<boolean>(false);
  const [api, context] = notification.useNotification();
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  const [selection, setSelection] = useState<{ columns: SelectionGrid; rows: SelectionGrid }>({
    columns: { start: undefined, end: undefined },
    rows: { start: undefined, end: undefined },
  });

  const isSelecting =
    selection.columns.start !== undefined &&
    selection.rows.start !== undefined &&
    selection.columns.end !== undefined &&
    selection.rows.end !== undefined;

  const { loading, ...args } = refineProps(props, { setSelection, selection });
  const resizeSetTimestamp = debounce(() => setTimestamp(new Date().getTime()), UI_SETTINGS.STANDARD_INPUT_DEBOUNCE);

  useEffect(() => {
    window.addEventListener("resize", resizeSetTimestamp);

    return () => {
      window.removeEventListener("resize", resizeSetTimestamp);
    };
  }, []);

  return (
    <>
      {context}
      <TableWrapper className={`basic-table-wrapper ${timestamp}`}>
        <LoadingWrapper isLoading={!!loading}>
          <StyledTable
            className={`${isSelecting ? "basic-table-selecting" : ""}`}
            id={props.id}
            locale={{ emptyText: <EmptyComponent isLoading={!!loading} description={props.noDataText} /> }}
            showSorterTooltip={false}
            sortDirections={DEFAULT_SORT_OPTIONS}
            {...args}
            onRow={(row, index) => {
              const propsOnRow = props.onRow?.(row, index);
              const clearSelection = () => {
                setSelection({
                  columns: { start: undefined, end: undefined },
                  rows: { start: undefined, end: undefined },
                });
                window.removeEventListener("mouseup", clearSelection);
              };
              return {
                ...propsOnRow,
                onMouseDown: (e) => {
                  window.addEventListener("mouseup", clearSelection);
                  //@ts-ignore
                  propsOnRow?.onMouseDown?.(e);
                },
                onMouseUp: (e) => {
                  selectionMadeRef.current = isSelecting;
                  getSelectedData(selection, setSelection, props.columns, api);
                  //@ts-ignore
                  propsOnRow?.onMouseUp?.(e);
                },
                onClick: (e) => {
                  if (!selectionMadeRef.current) {
                    //@ts-ignore
                    propsOnRow?.onClick(e);
                  }
                  selectionMadeRef.current = true;
                },
              };
            }}
            pagination={
              args.pagination
                ? {
                    showSizeChanger: false,
                    showQuickJumper: false,
                    locale: {
                      items_per_page: i18next.t("pagination.ITEMS_PER_PAGE"),
                      jump_to: "",
                      page: i18next.t("pagination.PAGE_JUMPER"),
                    },
                    itemRender(_page, type, element) {
                      if (type === "prev") return <ArrowLeftOutlined />;
                      if (type === "next") return <ArrowRightOutlined />;
                      return element;
                    },
                    ...args.pagination,
                  }
                : false
            }
          />
        </LoadingWrapper>
      </TableWrapper>
    </>
  );
};
